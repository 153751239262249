<template>
    <div class="tabs">
        <div v-for="tab in tabs"
             :key="'tab-'+tab.id"
             :class="'tab '+( tab.id === id ? 'active' : 'inactive' )"
             @click="$emit( 'clicked', tab.id )">
            {{ tab.label }}
        </div>
        <div class="clearfix"></div>
    </div>
</template>

<script>
export default {
    name : 'Tabs',
    props: [ 'tabs', 'id' ],
    emits: [ 'clicked' ]
}
</script>