import { createWebHashHistory, createRouter } from 'vue-router'

/*import Vue        from 'vue'
import Router     from 'vue-router'*/

import Login      from '@/components/pages/Login'
import Dashboard  from '@/components/pages/Dashboard'
import Users      from '@/components/pages/Users'
import Monitoring from '@/components/pages/Monitoring'
import Sesame     from '@/components/pages/Sesame'
import Licenses   from '@/components/pages/Licenses'
import Invoices   from '@/components/pages/Invoices'
import Helpdesk   from '@/components/pages/Helpdesk'
import Tools      from '@/components/pages/Tools'
import Admin      from '@/components/pages/Admin'

const routes =  [
    {
        path     : '/',
        name     : 'login',
        component: Login
    },
    {
        path     : '/dashboard',
        name     : 'dashboard',
        component: Dashboard
    },
    {
        path     : '/users',
        name     : 'users',
        component: Users
    },
    {
        path     : '/licenses',
        name     : 'licenses',
        component: Licenses
    },
    {
        path     : '/invoices',
        name     : 'invoices',
        component: Invoices
    },
    {
        path     : '/helpdesk',
        name     : 'helpdesk',
        component: Helpdesk
    },
    {
        path     : '/invoices/:filter',
        props    : true,
        name     : 'filteredInvoices',
        component: Invoices
    },
    {
        path     : '/monitoring',
        name     : 'monitoring',
        component: Monitoring
    },
    {
        path     : '/tools',
        name     : 'tools',
        component: Tools
    },
    {
        path     : '/admin',
        name     : 'admin',
        component: Admin
    },
    {
        path     : '/yotter-please-let-me-in',
        name     : 'sesame',
        component: Sesame
    }
]

const router = new createRouter( {
    routes,
    history: createWebHashHistory(),
    scrollBehavior()
    {
        window.detachFunctionBox = true
        window.scrollTo( {
            top     : 0,
            left    : 0,
            behavior: 'smooth'
        } )
    }
} )

router.beforeEach( ( to, from, next ) =>
{
    
    let routesAllowed = [
        'login',
        'sesame'
    ]
    
    if( -1 < routesAllowed.indexOf( to.name ) )
    {
        next()
    }
    else
    {
        if( undefined === window.authKey )
        {
            setTimeout( () =>
            {
                if( 'auth' === window.authKey )
                {
                    next()
                }
                else
                {
                    console.error( 'router.guard: user not authorized -> not taking route.', to.name )
                    next( {
                        name: 'login'
                    } )
                }
            }, 1000 )
        }
        else
        {
            if( 'auth' === window.authKey )
            {
                next()
            }
            else
            {
                console.error( 'router.guard: user not authorized -> not taking route.', to.name )
            }
        }
        
    }
    
} )

export default router