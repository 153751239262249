export default class Session
{

    constructor( core )
    {

        if( !Session.instance )
        {

            this.store = core.getStore()
            this.client = core.getClient()
            this.eventManager = core.getEventManager()

            this.sessionTimer = null
            this.initialized = false
            this.initializing = false

            this.eventManager.append( 'on-websocket-open', () =>
            {
                this.initialize()
            } )

            Session.instance = this

        }

        return Session.instance

    }

    initialize()
    {

        if( !this.initializing )
        {
            console.log( 'Session initializing...' )
            this.initializing = true
            let cookie = document.cookie
            let tryPing = false

            if( '' !== cookie.trim() )
            {
                let cookies = cookie.split( ';' )
                for( let c in cookies )
                {
                    let temp = cookies[ c ].split( '=' )
                    switch( temp[ 0 ].trim() )
                    {
                        case 'sessionId':
                            this.store.commit( 'setIdSession', temp[ 1 ].trim() )
                            tryPing = true
                            break
                    }
                }
            }

            if( tryPing )
            {
                this.ping()
                    .then( result =>
                    {

                        if( false !== result )
                        {

                            console.log( '+ Session initialized' )
                            this.store.commit( 'setAuthorized', result.result.id_user !== false ? 1 : 0 )
                            this.store.commit( 'setUsername', result.result.username )
                            this.store.commit( 'setEmail', result.result.email )
                            this.store.commit( 'setFirstname', result.result.first_name )
                            this.store.commit( 'setLastname', result.result.last_name )
                            this.store.commit( 'setIdRole', result.result.id_role )

                            this.initialized = true
                            this.initializing = false
                            this.createSessionTimer()

                        }
                        else
                        {
                            console.log( '- failed to initialize session (failstate 1) - retrying in a few seconds...' )
                            setTimeout( () =>
                            {
                                this.initializing = false
                                this.initialize()
                            }, 5000 )
                        }

                    } )
                    .catch( e =>
                    {
                        console.log( '<!> failed to initialize session:', e )
                        this.store.dispatch( 'destroySession' )
                            .then( () =>
                            {
                                this.dropSessionCookie()
                                this.initialized = true
                                this.initializing = false
                            } )
                    } )
            }
            else
            {
                this.initializing = false
            }

        }
        else
        {
            console.log( '<!> already awaiting initialized Session...' )
        }

    }

    destroy()
    {
        if( this.sessionTimer )
        {
            clearInterval( this.sessionTimer )
        }
        this.dropSessionCookie()
        this.store.dispatch( 'destroySession' )
            .then( () =>
            {
                console.log( 'Session::destroy : destroyed.' )
            } )
    }

    dropSessionCookie()
    {
        document.cookie = 'sessionId=; expires=Thu, 01 Jan 1970 00:00:00 UTC;'
    }

    createSessionTimer()
    {
        this.sessionTimer = setInterval( () =>
        {
            this.ping()
        }, 10000 )
    }

    register( sessionData )
    {

        return new Promise( ( resolve, reject ) =>
        {

            if( undefined !== sessionData.result
                && undefined !== sessionData.result.id
                && 0 < parseInt( sessionData.result.id ) )
            {

                this.store.commit( 'setIdSession', sessionData.sessionId )

                this.ping()
                    .then( () =>
                    {

                        let user = sessionData.result

                        this.store.commit( 'setUsername', user.username )
                        this.store.commit( 'setEmail', user.email )

                        document.cookie = 'sessionId=' + sessionData.sessionId
                        this.store.commit( 'setAuthorized', 1 )

                        this.eventManager.dispatch( 'on-session-registered' )
                        this.createSessionTimer()

                        return resolve()

                    } )

            }
            else
            {
                return reject()
            }

        } )

    }

    ping()
    {

        return new Promise( resolve =>
        {

            if( null !== this.store.getters.idSession )
            {

                let message = {
                    method    : 'admin.ping',
                    id_session: this.store.getters.idSession
                }

                this.eventManager.dispatch( 'ping' )

                this.client.request( message )
                    .then( result =>
                    {
                        this.eventManager.dispatch( 'clearPing' )
                        this.store.commit( 'setIdUser', result.result.id_user )
                        this.store.commit( 'setFirstname', result.result.first_name )
                        this.store.commit( 'setLastname', result.result.last_name )
                        this.store.commit( 'setIdRole', result.result.id_role )
                        return resolve( result )
                    } )
                    .catch( error =>
                    {
                        console.error( '<!>', error )
                        switch( error )
                        {
                            case 'ERR_NOT_CONNECTED':
                            case 'WSS_INVALID_RESPONSE':
                                console.log( '...ignore' )
                                break
                            default:
                                console.log( '...dispatch clear / idUser reset' )
                                this.eventManager.dispatch( 'clearPing' )
                                this.store.commit( 'setIdUser', false )
                                break
                        }
                        return resolve( false )
                    } )

            }

        } )

    }

}