import CacheParserUsers from "@/classes/helpers/cacheparsers/users";

export default class Cacher
{

    constructor( core )
    {

        if( !Cacher.instance )
        {

            this.store = core.getStore()
            this.client = core.getClient()
            this.eventManager = core.getEventManager()
            this.reformatter = core.getReformatter()
            this.f = core.f()
            this.badge = core.getBadge()

            this.fetching = false
            this.resetTimer = null
            this.licenseTimer = null

            this.eventManager.add( 'on-store-authstate-change', () =>
            {
                if( 1 === this.store.getters.authorized )
                {
                    this.fillAdminCache()
                    this.fillUsersCache()
                    this.fillLicensesCache()
                }
            } )

            this.eventManager.append( 'on-session-registered', () =>
            {
                this.fillAdminCache()
                this.fillUsersCache()
                this.fillLicensesCache()
            } )

            this.eventManager.add( 'on-userlist-visit', () =>
            {
                let list = this.store.getters.usersCache
                if( null !== list )
                {
                    this.userCount = list.length
                    this.diff = 0
                    this.badge.setCount( 'users', this.diff )
                }
            } )

            this.userCount = -1
            this.diff = -1

            this.parsers = {
                users: new CacheParserUsers( this )
            }

            this.timer = null

            Cacher.instance = this

        }

        return Cacher.instance

    }

    fetchCount()
    {
        let message = {
            method: 'admin.users_getCount'
        }

        if( 1 === parseInt( this.store.getters.authorized ) )
        {
            this.client
                .request( message )
                .then( result =>
                {
                    if( parseInt( result.result ) > this.userCount )
                    {

                        this.diff = parseInt( result.result ) - this.userCount
                        this.badge.setCount( 'users', this.diff )
                        this.fillUsersCache( true )
                    }
                } )
                .catch( e =>
                {
                    console.log( 'error fetching cache counts:', e )
                } )
        }
    }

    fillAdminCache()
    {

        let message = {
            method: 'admin.admin_listAll'
        }

        this.client
            .request( message )
            .then( response =>
            {

                let adminCache = {}
                for( let r in response.result.accounts )
                {
                    adminCache[ response.result.accounts[ r ].id ] = response.result.accounts[ r ]
                }

                this.store.commit( 'setAdminCache', adminCache )

            } )
            .catch( () =>
            {

                clearTimeout( this.resetTimer )
                this.resetTimer = null
                this.resetTimer = setTimeout( () =>
                {
                    this.fillAdminCache()
                }, 5000 )

            } )

    }

    fillLicensesCache()
    {

        return new Promise( resolve =>
        {

            let message = {
                method: 'admin.licenses_listAll'
            }

            this.client
                .request( message )
                .then( response =>
                {

                    let licensesCache      = {},
                        licensesPoolsCache = {}

                    for( let l in response.result )
                    {
                        licensesCache[ response.result[ l ].id ] = response.result[ l ]
                    }

                    this.client
                        .request( {
                            method: 'admin.licenses_listAllPools'
                        } )
                        .then( response =>
                        {

                            for( let l in response.result )
                            {
                                licensesPoolsCache[ response.result[ l ].id ] = response.result[ l ]
                            }

                            this.store.commit( 'setLicensesCache', licensesCache )
                            this.store.commit( 'setLicensePoolsCache', licensesPoolsCache )

                            return resolve( {
                                licenses     : licensesCache,
                                licensesPools: licensesPoolsCache
                            } )

                        } )

                } )
                .catch( () => {

                    clearTimeout( this.licenseTimer )
                    this.licenseTimer = null
                    this.licenseTimer = setTimeout( () =>
                    {
                        this.fillAdminCache()
                    }, 5000 )


                })

        } )

    }

    awaitAdminCache()
    {
        return new Promise( resolve =>
        {

            if( null === this.store.getters.adminCache )
            {
                setTimeout( () =>
                {
                    return resolve( this.awaitAdminCache() )
                }, 100 )
            }
            else
            {
                return resolve()
            }
        } )
    }

    fillUsersCache( force, retryCount )
    {

        return new Promise( resolve =>
        {

            if( ( this.store.getters.usersCache === null
                  || true === force )
                && !this.fetching )
            {

                retryCount = retryCount || 0
                this.fetching = true
                this.store.commit( 'setUsersCache', null )

                let message = {
                    method: 'admin.users_listAll'
                }

                this.client
                    .request( message )
                    .then( result =>
                    {

                        this.fetching = false
                        let userlist = this.parsers.users.parseServerList( result )
                        this.userCount = userlist.length
                        this.eventManager.dispatch( 'on-usercache-ready', userlist )

                        if( null === this.timer )
                        {
                            this.timer = setInterval( () =>
                            {
                                this.fetchCount()
                            }, 10000 )
                        }

                        return resolve( userlist )

                    } )
                    .catch( e =>
                    {

                        console.log( 'ERROR', e )
                        if( 5 > retryCount )
                        {
                            this.fetching = false
                            retryCount++
                            setTimeout( () =>
                            {
                                return resolve( this.fillUsersCache( force, retryCount ) )
                            }, 500 )
                        }
                        else
                        {
                            this.fetching = false
                            console.error( 'fetch userlist failed', 'giving up after 5 retries' )
                        }

                    } )
            }
            else
            {
                if( this.fetching )
                {
                    setTimeout( () =>
                    {
                        return resolve( this.fillUsersCache( force ) )
                    }, 300 )
                }
                else
                {
                    return resolve( this.store.getters.usersCache )
                }
            }

        } )

    }

}