<template>
    <div class="infobox" v-if="ready">
        <Opener id="sessions" :state="open" inline=true
                :caption="'Aktive Sessions'+( !open ? '<span style=\'font-weight:normal\'>: '+count+'</span>' : '' )"
                @clicked="open = !open"/>
        <template v-if="open">
            <table class="margin-top infotable" v-if="undefined !== sessions">
                <thead>
                <tr>
                    <th class="center strong">Session-ID</th>
                    <th class="center strong">IP-Adresse</th>
                    <th class="center strong">aktiv&nbsp;seit</th>
                    <th class="center strong">letztes&nbsp;Update</th>
                    <th class="center strong" style="width:50px;">Optionen</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="s in sessions" :key="'sess-'+s.id">
                    <td class="center strong">{{ shortId( s.id ) }}</td>
                    <td class="center">{{ s.ip_address }}</td>
                    <td class="center">{{ s.session_start }}</td>
                    <td class="center strong">{{ s.session_update }}</td>
                    <td class="center" style="width: 50px;">
                        <div class="button cancel" @click="dropSession( s.id )" title="Session beenden">X</div>
                    </td>
                </tr>
                </tbody>
            </table>
            <table class="margin-top infotable" v-else>
                <tbody>
                <tr>
                    <td class="center"><h3 class="error">keine Informationen verfügbar</h3></td>
                </tr>
                </tbody>
            </table>
        </template>
    </div>
</template>

<script>
import Opener from '@/components/elements/Opener'

export default {

    name      : 'UserSessions',
    components: { Opener },
    props     : [ 'element' ],

    data()
    {
        return {
            sessions: undefined,
            ready   : false,
            open    : false,
            count   : 0
        }
    },

    mounted()
    {

        this.getSessions()

    },

    methods: {

        shortId( id )
        {
            return id.substr( 0, 16 ) + '...'
        },

        getSessions()
        {
            let message = {
                method : 'admin.users_getSessions',
                id_user: this.$props.element.id
            }

            this.$core.getClient()
                .request( message )
                .then( result =>
                {
                    if( undefined !== result.result )
                    {
                        this.sessions = result.result
                        this.count = result.result.length
                    }
                    this.ready = true
                } )
                .catch( () =>
                {
                    this.ready = true
                } )
        },

        dropSession( id )
        {

            let message = {
                method : 'admin.users_dropSession',
                id_user: this.$props.element.id,
                id_drop: id
            }

            this.$core.getClient()
                .request( message )
                .then( () =>
                {
                    this.getSessions()
                } )
                .catch( () =>
                {
                    this.getSessions()
                } )

        }

    }

}
</script>

<style lang="css" scoped>

th, td {
    white-space: nowrap;
}

</style>