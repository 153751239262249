<template>
    <div>
        <div class="width-70">
            <select class="select-css inset" :id="id">
                <option value="">bitte wählen...</option>
                <optgroup label="alle">
                    <option value="all">alle Newsletter-Empfänger</option>
                </optgroup>
                <optgroup label="individuell">
                    <option v-for="item in copy" :key="'opt-'+item.raw.id_user" :value="item.raw.id_user">
                        {{ item.name }}
                    </option>
                </optgroup>
            </select>
        </div>
        <div class="width-30">
            <input type="text" :id="'filter-'+id" placeholder="Filter" @change="applyFilter"/>
        </div>
    </div>
</template>

<script>
export default {

    name : 'NewsletterRecipients',
    props: [ 'id' ],

    data()
    {
        return {
            ready: false,
            list : [],
            copy: []
        }
    },

    created()
    {
        this.$core.getClient()
            .request( {
                method: 'admin.tools_listNewslettersSubscribers'
            } )
            .then( response =>
            {
                let list = response.result
                for( let l in list )
                {

                    let item = {
                        raw : list[ l ],
                        name: list[ l ].lastname + ', ' + list[ l ].firstname + ' (' + list[ l ].username + ')'
                    }
                    this.list.push( item )

                }
                this.list = this.$core.getSorter().sortObjects( this.list, 'name', 'ascending' )
                this.copy = JSON.parse( JSON.stringify( this.list ) )
            } )
    },

    methods: {
        applyFilter()
        {

            let filterElm = document.querySelector( '#filter-'+this.$props.id )
            if( null !== filterElm )
            {
                let value = filterElm.value
                if( 3 <= value.length )
                {
                    let newCopy = []
                    for( let l in this.list )
                    {
                        if( -1 < this.list[l].name.toLowerCase().indexOf( value.toLowerCase() ) )
                        {
                            newCopy.push( this.list[l] )
                        }
                    }
                    this.copy = newCopy
                }
                else
                {
                    this.copy = JSON.parse( JSON.stringify( this.list ) )
                }
            }

        }
    }

}
</script>