<template>
    <div class="infobox" v-if="ready">
        <Opener id="fails" :state="open" inline=true
                :caption="'Fehlgeschlagene Logins & aktive Sperren'+( !open ? '<span style=\'font-weight:normal\'>: '+count+'</span>' : '' )"
                @clicked="open = !open"/>
        <template v-if="open">
            <table class="margin-top infotable" v-if="0 < count">
                <tr>
                    <td class="center strong">fehlgeschlagene Logins</td>
                    <td class="center"><strong>{{ count }}</strong></td>
                </tr>
                <tr>
                    <td class="center strong">erster Eintrag</td>
                    <td class="center">{{ stats.datetime_created_formatted }}</td>
                </tr>
                <tr>
                    <td class="center strong">letzter Versuch</td>
                    <td class="center">{{ stats.datetime_updated_formatted }}</td>
                </tr>
                <tr>
                    <td class="center strong">gesperrt?</td>
                    <td class="center" v-if="count < 3"><span class="good strong">nein</span></td>
                    <td class="center" v-else><span class="error strong">JA</span></td>
                </tr>
                <tr>
                    <td class="center strong">&nbsp;</td>
                    <td>
                        <div class="button nonfixed right delete" @click="removeFailures()">Zähler löschen</div>
                    </td>
                </tr>
            </table>
            <table class="margin-top infotable" v-else>
                <tr>
                    <td class="center strong">&nbsp;</td>
                    <td class="center"><h3 class="error">keine Informationen verfügbar</h3></td>
                </tr>
            </table>
        </template>
    </div>
</template>

<script>

import Opener from '@/components/elements/Opener'

export default {
    
    name      : 'UserFailCounters',
    components: { Opener },
    props     : [ 'element' ],
    
    data()
    {
        return {
            count: 0,
            stats: undefined,
            open : false,
            ready: false
        }
    },
    
    mounted()
    {
        
        this.getFails()
        
    },
    
    methods: {
        
        getFails()
        {
            let message = {
                method : 'admin.users_getFailedCounters',
                id_user: this.$props.element.id
            }
            
            this.$core.getClient()
                .request( message )
                .then( response =>
                {
                    
                    this.stats = response.result
                    if( undefined !== this.stats.failed_invites )
                    {
                        this.count = parseInt( this.stats.failed_invites ) + parseInt( this.stats.failed_logins )
                    }
                    this.ready = true
                    
                } )
                .catch( () =>
                {
                    this.ready = true
                } )
        },
        
        removeFailures()
        {
            let message = {
                method: 'admin.users_resetFailedCounters',
                id_ban: this.stats.id
            }
            
            this.$core.getClient()
                .request( message )
                .then( () =>
                {

                    this.count = 0
                    this.getFails()
                    this.ready = true
                    
                } )
                .catch( () =>
                {
                    this.ready = true
                } )
        }
        
    }
    
}
</script>