/**
 * Application Core Class
 * - replaces all deprecated plugins by a core component for a better state machine handling
 import MessagesPlugin          from '@/plugins/Messages'
 */

import EventManager    from '@/classes/core/EventManager'
import Config          from '@/classes/Config'
import Crypto          from '@/classes/core/Crypto'
import WebSocketClient from '@/classes/client/WebSocketClient'
import Session         from '@/classes/core/Session'
import Sorter          from '@/classes/core/Sorter'
import Reformatter     from '@/classes/helpers/Reformatter'
import Translation     from '@/classes/core/Translation'
import Cacher          from '../helpers/Cacher'
import UUID            from '@/classes/helpers/UUID'
import HelpdeskHelper  from '@/classes/helpers/Helpdesk'
import Functions       from '@/classes/core/Functions'
import Badge           from "@/classes/helpers/Badge";
import Rights          from "@/classes/core/Rights";

export default class Core
{

    constructor( store, router )
    {

        if( !Core.instance )
        {

            this.config = new Config()
            this.store = store
            this.router = router
            this.badge = new Badge()

            this.rights = new Rights( this )
            this.eventManager = new EventManager( this )
            this.store.commit( 'injectEventManager', this.eventManager )
            this.functions = new Functions( this )
            this.websocket = new WebSocketClient( this )
            this.session = new Session( this )
            this.sorter = new Sorter()
            this.reformatter = new Reformatter()
            this.translation = new Translation()

            this.crypto = new Crypto( this.config )
            this.uuidHelper = new UUID()
            this.cacher = new Cacher( this )
            this.states = {}

            this.helpdeskHelper = new HelpdeskHelper( this )

            Core.instance = this

        }

        return Core.instance

    }

    /**
     * getters
     */

    getColorSet( key )
    {

        let colorSets = {
            doughnut : [
                'lightgreen',
                'orange',
                'lightblue',
                'pink',
                'lightyellow',
                'cyan',
                'red',
                'lightgrey',
                'lightbrown',
                'greenyellow',
                'salmon',
                'lavender',
                'fuchsia',
                'springgreen'
            ],
            bars     : [
                '#aa0000',
                '#992200',
                '#773300',
                '#555500',
                '#337700',
                '#229900',
                '#11a000',
                '#00aa00'
            ],
            colorbars: [
                'springgreen',
                'fuchsia',
                'lavender',
                'salmon',
                'greenyellow',
                'lightbrown',
                'lightgrey',
                'red',
                'cyan',
                'pink',
                'lightblue',
                'orange',
                'lightgreen'
            ]
        }

        return colorSets[ key ]

    }

    setState( key, value )
    {
        this.states[ key ] = value
    }
    
    getState( key )
    {
        return this.states[ key ]
    }
    
    deleteState( key )
    {
        delete this.states[ key ]
    }

    getBadge()
    {
        return this.badge
    }

    getCrypto()
    {
        return this.crypto
    }
    
    getCacher()
    {
        return this.cacher
    }
    
    getClient()
    {
        return this.websocket
    }

    getEventManager()
    {
        return this.eventManager
    }

    getSession()
    {
        return this.session
    }

    getConfig()
    {
        return this.config
    }

    getStore()
    {
        return this.store
    }

    getSorter()
    {
        return this.sorter
    }

    getReformatter()
    {
        return this.reformatter
    }

    getRouter()
    {
        return this.router
    }

    getHelpdeskHelper()
    {
        return this.helpdeskHelper
    }

    t()
    {
        return this.translation
    }

    f()
    {
        return this.functions
    }

    r()
    {
        return this.rights
    }

    uuid()
    {
        return this.uuidHelper
    }
    
}