<template>
    <div class="flowroot">
        <input type="checkbox" id="lite" value="lite" :checked="-1 < this.selected.indexOf( 'lite' )" @input="handleChange"/><label class="version lite" for="lite">basis</label>
        <div class="clearfix"></div>
        <input type="checkbox" id="full" value="full" :checked="-1 < this.selected.indexOf( 'full' )" @input="handleChange"/><label class="version pro" for="pro">pro</label>
        <div class="clearfix"></div>
        <input type="checkbox" id="networked" value="networked" :checked="-1 < this.selected.indexOf( 'networked' )" @input="handleChange"/><label class="version school" for="networked">netzwerk</label>
        <div class="clearfix"></div>
        <input type="hidden" :value="selected.join(',')" :id="id" />
    </div>
</template>

<script>
export default {
    
    name : 'FeatureSetSelector',
    props: [ 'value', 'id' ],
    
    data()
    {
        return {
            ready   : true,
            selected: []
        }
    },
    
    created()
    {
        this.selected = this.$props.value ? this.$props.value.split( /,/ ) : []
    },

    beforeDestroy()
    {
        this.ready = false
    },

    methods: {
        handleChange( event )
        {

            let key = event.target.id,
                value = event.target.checked,
                isSelected = this.selected.indexOf( key )

            if( !value )
            {
                this.selected.splice( isSelected, 1 )
            }
            else
            {
                this.selected.push( key )
            }

        }
    }
}
</script>