<template>
    <div v-if="ready">
        <ElementFilter @refresh="handleRefresh()" id="newsletter" :showAdder="true"
                       :showHideFor="{ id: 'test', label: 'Testversand' }" @add="add()"/>
        <Form v-if="editor"
              :formValues="formValues"
              @close="close()"
              @submit="sendMail"/>
        <table>
            <thead>
            <tr>
                <Thd id="id" caption="ID" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortNewsletter"
                     @sort="handleSort"/>
                <Thd id="count_recipients" caption="Modus"/>
                <Thd id="count_recipients" caption="# Empfänger" :sortBy="sortBy" :sortDir="sortDir"
                     sortRule="sortNewsletter"
                     @sort="handleSort"/>
                <Thd id="title" caption="Betreff" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortNewsletter"
                     @sort="handleSort"/>
                <Thd id="datetime_sent" caption="Angelegt" :sortBy="sortBy" :sortDir="sortDir"
                     sortRule="sortNewsletter"
                     @sort="handleSort"/>
                <Thd id="datetime_sent" caption="Verarbeitung" :sortBy="sortBy" :sortDir="sortDir"
                     sortRule="sortNewsletter"
                     @sort="handleSort"/>
                <Thd id="datetime_sent" caption="Versand" :sortBy="sortBy" :sortDir="sortDir"
                     sortRule="sortNewsletter"
                     @sort="handleSort"/>
                <th>Aktionen</th>
            </tr>
            </thead>
            <tbody class="filterable">
            <tr v-for="item in newsletters"
                :key="'row_'+item.id"
                :class="'clickable hideable-'+( item.count_recipients > 1 ? 'live' : 'test' )"
                @click="open(item)">
                <td>{{ item.id }}</td>
                <td><strong>{{ item.count_recipients > 1 ? 'LIVE' : 'TEST' }}</strong></td>
                <td><strong>{{ item.count_recipients }}</strong></td>
                <td><strong>{{ item.title }}</strong></td>
                <td>{{ $core.getReformatter().formatTimestamp( item.datetime_created ) }}</td>
                <td>{{ $core.getReformatter().formatTimestamp( item.datetime_processing ) }}</td>
                <td>{{ $core.getReformatter().formatTimestamp( item.datetime_sent ) }}</td>
                <td>
                    <div class="button cancel" @click="handleDelete( item.id )">X</div>
                    <div class="button" @click="handleResend( item.id )">R</div>
                </td>
            </tr>
            </tbody>
        </table>
        <tfoot>
        <tr :id="viewId">
            <th colspan="99" style="text-align:right;" class="filtercount">...</th>
        </tr>
        </tfoot>
    </div>
    <Spinner v-else title="Gleich geht's weiter" message="Versendete Newsletter werden abgerufen..."/>
</template>

<script>
import Spinner        from '@/components/elements/Spinner'
import ElementFilter  from '@/components/elements/ElementFilter'
import Thd            from '@/components/elements/Thd'
import Form           from '@/components/elements/fullscreen/Form'
import MixinViewReady from '@/mixins/MixinViewReady'

export default {

    name      : 'ToolsMessages',
    components: { Form, Thd, ElementFilter, Spinner },
    mixins    : [ MixinViewReady ],
    data()
    {
        return {
            ready      : false,
            editor     : false,
            formValues : {},
            sortBy     : 'id',
            sortDir    : 'descending',
            newsletters: []
        }
    },

    created()
    {
        this.init()
    },

    methods: {

        init()
        {

            this.newsletters = []
            this.ready = false
            this.$core.getClient()
                .request( {
                    method: 'admin.tools_listNewsletters'
                } )
                .then( response =>
                {
                    let list = response.result
                    this.newsletters = list
                    this.sort()
                } )

        },

        handleResend( id )
        {
            this.$core.getClient()
                .request( {
                    method: 'admin.tools_resendNewsletter',
                    id    : id
                } )
                .then( () =>
                {
                    this.$core.f().uiMessage( 'success', 'Newsletter wird erneut versendet...' )
                    this.init()
                } )
                .catch( e =>
                {
                    this.$core.f().uiMessage( 'error', e )
                } )
        },

        performDelete( id )
        {
            this.$core.getClient()
                .request( {
                    method: 'admin.tools_deleteNewsletter',
                    id    : id
                } )
                .then( () =>
                {
                    this.$core.f().uiMessage( 'success', 'Newsletter gelöscht.' )
                    this.init()
                } )
                .catch( e =>
                {
                    this.$core.f().uiMessage( 'error', e )
                } )
        },

        add()
        {

            this.formValues = {
                title         : '<strong>Newsletter</strong> versenden',
                subjectCaption: 'Versand starten',
                fields        : [
                    {
                        caption: 'Empfänger',
                        type   : 'NewsletterRecipient',
                        id     : 'recipients',
                        value  : ''
                    },
                    {
                        caption: 'Template',
                        type   : 'NewsletterTemplate',
                        id     : 'template',
                        value  : ''
                    },
                    {
                        caption: 'Betreff',
                        type   : 'TextBox',
                        id     : 'subject',
                        value  : ''
                    },
                    {
                        caption: 'Textkörper',
                        type   : 'TextArea',
                        id     : 'text',
                        value  : ''
                    },
                    {
                        type : 'hidden',
                        id   : 'id_author',
                        value: this.$store.getters.idUser
                    },
                    {
                        type : 'hidden',
                        id   : 'mode',
                        value: 'add'
                    }
                ]
            }

            this.editor = true

        },

        sort()
        {
            this.newsletters = this.$core.getSorter().sortObjects( this.newsletters, this.sortBy, this.sortDir )
            this.ready = true
        },

        handleRefresh()
        {
            this.init()
        },

        handleSort( args )
        {

            let newDir = args.sortDir === 'ascending' ? 'descending' : 'ascending'
            this.$store.commit( 'setSortNewsletter', args.id )
            this.$store.commit( 'setSortNewsletterDir', newDir )
            this.sortBy = args.id
            this.sortDir = newDir
            this.sort()

        },

        sendMail( values )
        {

            this.$core.getEventManager().dispatch( 'showBlocker' )
            this.$core.getClient()
                .request( {
                    method: 'admin.tools_createNewsletter',
                    values: values
                }, 300000 )
                .then( result =>
                {

                    this.$core.f().uiMessage( 'success', 'Newsletter angelegt: Er wird in 30-45 Sekunden versendet...' )

                    this.close()
                    this.init()
                    this.$core.getEventManager().dispatch( 'hideBlocker' )
                } )

        },

        handleDelete( id )
        {

            let setup = {
                show   : true,
                caption: 'Newsletter löschen',
                buttons: {
                    'left' : {
                        class   : 'default',
                        caption : 'nein',
                        callback: () =>
                        {
                            console.log( 'delete aborted by user input.' )
                        }
                    },
                    'right': {
                        class   : 'delete',
                        caption : 'löschen',
                        callback: () =>
                        {
                            this.performDelete( id )
                        }
                    }
                },
                message: '<strong>Bist du sicher</strong>, dass du diesen Newsletter löschen möchtest?'
            }

            this.$core.getEventManager().dispatch( 'show-modal-dialog', setup )

        },

        close()
        {
            this.formValues = {}
            this.editor = false
        }

    }

}
</script>