<template>
    <transition appear name="slide-left">
        <div class="modal-wrapper" v-if="show">
            <div class="modal-dialog">
                <h2>{{ caption }}</h2>
                <p class="modal-message" v-html="message"></p>
                <div class="modal-buttons">
                    <div :class="'modal-button '+buttons.left.class+' left'" @click="handleClick( 'left' )">
                        {{  buttons.left.caption }}
                    </div>
                    <div :class="'modal-button '+buttons.right.class+' right'" @click="handleClick( 'right' )">
                        {{  buttons.right.caption }}
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {

    name: 'ModalDialog',

    data()
    {
        return {
            show     : false,
            caption  : 'Löschi löschi?',
            message  : 'Ficki!',
            buttons: {
                'left': {
                    class: 'default',
                    caption: 'lieber nicht',
                    callback: () =>
                    {
                        this.show = false
                    }
                },
                'right': {
                    class: 'delete',
                    caption: 'mach et',
                    callback: () =>
                    {
                        this.show = false
                    }
                }
            }
        }
    },

    created()
    {
        this.$core.getEventManager().add( 'show-modal-dialog', ( setup ) =>
        {
            this.$nextTick()
                .then( () =>
                {
                    this.showModalDialog( setup )
                } )
        } )
    },

    beforeDestroy()
    {
        this.$core.getEventManager().remove( 'show-modal-dialog' )
    },

    methods: {
        showModalDialog( setup )
        {
            this.show = true
            this.caption = setup.caption
            this.message = setup.message
            this.buttons = setup.buttons
        },
        handleClick( which )
        {
            this.show = false
            this.buttons[ which ].callback()
        }
    }
}
</script>