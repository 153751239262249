<template>
    <transition appear name="slide-left">
        <div class="fullscreen raised">
            <div class="content">
                <div class="title">
                    <div class="headline" v-html="'<strong>'+( filename || 'kein Name' )+'</strong>'"></div>
                    <div class="closer" @click="$emit( 'close' )">
                        X
                    </div>
                </div>
                <div class="info text-content" v-html="text">
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name : "TextZoom",
    props: {
        filename: { Type: String, required: true },
        dataUri : { Type: String, required: true }
    },

    emits: [ 'close' ],

    data()
    {
        return {
            text: ''
        }
    },

    created()
    {
        this.text = this.nl2br( atob( this.$props.dataUri.split( ',' )[1] ) )
    },

    methods: {

        nl2br( text )
        {
            text = text.replace( /\r\n/g, '<br/>', text )
            text = text.replace( /\n/g, '<br/>', text )
            return text
        }

    }

}
</script>