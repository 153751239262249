<template>
    <div>
        <select class="select-css inset" :id="id" @change="$core.getEventManager().dispatch( 'on-role-select' )">
            <option value="">bitte wählen...</option>
            <option v-for="item in listTypes" :key="'opt-'+item" :value="item" :selected="item === value">
                {{ translateListType( item ) }}
            </option>
        </select>
    </div>
</template>

<script>
export default {
    
    name : 'RoleSelector',
    props: [ 'value', 'id' ],
    emits: [ 'select' ],

    data()
    {
        return {
            ready   : true,
            listTypes : [
                1, 5, 10
            ]
        }
    },
    
    created()
    {
    },

    beforeDestroy()
    {
        this.ready = false
    },
    
    methods: {

        translateListType( type )
        {
            switch( type )
            {
                case 1:
                    return 'Helpdesk'
                case 5:
                    return 'Editor'
                case 10:
                    return 'Administrator'
            }
        }
        
    }
    
}
</script>