<template>
    <transition appear name="fade">
        <div class="spinner">
            <div class="spinnerlogo-boundaries">
                <div id="spinnerlogo"></div>
            </div>
            <div class="spinner-messagebox">
                <div id="spinner-title" v-html="title"></div>
                <div id="spinner-message" v-html="message"></div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {

    name: 'Spinner',
    props: [ 'title', 'message' ]

}
</script>