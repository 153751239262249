<template>
    <div class="infobox" v-if="ready">
        <h2>Meta-Informationen</h2>
        <table class="margin-top infotable" v-if="undefined !== meta">
            <tr>
                <td class="center strong">App-Version</td>
                <td class="center">{{ element.app_version }}</td>
            </tr>
            <tr>
                <td class="center strong">OS</td>
                <td class="center">{{ meta.os }}</td>
            </tr>
            <tr>
                <td class="center strong">Browser</td>
                <td class="center">{{ meta.browser }}</td>
            </tr>
            <tr>
                <td class="center strong">Device</td>
                <td class="center">{{ meta.device }}</td>
            </tr>
            <tr>
                <td class="center strong nowrap">Agent String</td>
                <td class="center">{{ meta.agent }}</td>
            </tr>
            <tr>
                <td class="center strong nowrap">letzte IP-Adresse</td>
                <td class="center">{{ meta.ip_address }}</td>
            </tr>
            <tr>
                <td class="center strong nowrap">letzte Host-Adresse</td>
                <td class="center">{{ meta.host_address }}</td>
            </tr>
            <tr>
                <td class="center strong nowrap">Stand der Informationen</td>
                <td class="center">{{ meta.datetime_updated }}</td>
            </tr>
        </table>
        <table class="margin-top infotable" v-else>
            <tr>
                <td class="center strong">&nbsp;</td>
                <td class="center"><h3 class="error">keine Informationen verfügbar</h3></td>
            </tr>
        </table>
    </div>
</template>

<script>

import useragent from 'useragent'

export default {

    name : 'UserMeta',
    props: [ 'element' ],

    data()
    {
        return {
            meta : false,
            ready: false
        }
    },

    mounted()
    {

        this.getMeta()

    },

    methods: {

        getMeta()
        {
            let message = {
                method : 'admin.users_getUserMeta',
                id_user: this.$props.element.id
            }

            this.$core.getClient()
                .request( message )
                .then( result =>
                {
                    this.meta = result.result[ 0 ]
                    if( undefined !== this.meta )
                    {

                        let parsed = useragent.parse( this.meta.agent )

                        if( parsed )
                        {
                            this.meta.browser = parsed.family + ' ' + parsed.major + '.' + parsed.minor + '.' + parsed.patch
                            this.meta.os = parsed.os.family + ' ' + parsed.os.major
                            this.meta.device = parsed.device.family
                        }

                    }

                    this.ready = true
                } )
                .catch( () =>
                {
                    this.ready = true
                } )
        }

    }

}
</script>