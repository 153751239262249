<template>
    <div v-if="ready">
        <Form v-if="editor"
              :formValues="formValues"
              @close="close()"
              @submit="storeVoucher"/>
        <VoucherUsage v-if="voucherCount !== undefined" :element="voucherCount" @close="voucherCount = undefined"/>
        <ElementFilter :viewKey="changeKey" :showAdder="true" @add="open()"/>
        <table>
            <thead>
            <Thd id="id" caption="#" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortVouchers"
                 @sort="handleSort"></Thd>
            <Thd id="name" caption="Name" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortVouchers"
                 @sort="handleSort"></Thd>
            <Thd id="code" caption="Code" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortVouchers"
                 @sort="handleSort"></Thd>
            <Thd id="valid_until" caption="Gültigkeit" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortVouchers"
                 @sort="handleSort"></Thd>
            <Thd id="valid_for_license_name" caption="gültig für Lizenz" :sortBy="sortBy" :sortDir="sortDir"
                 sortRule="sortVouchers" @sort="handleSort"></Thd>
            <Thd id="new_license_name" caption="Upgrade auf Lizenz" :sortBy="sortBy" :sortDir="sortDir"
                 sortRule="sortVouchers" @sort="handleSort"></Thd>
            <Thd id="date_license_extension" caption="Verlängerung" :sortBy="sortBy" :sortDir="sortDir"
                 sortRule="sortVouchers"
                 @sort="handleSort"></Thd>
            <Thd align="right" id="usage_count" caption="Nutzungen" :sortBy="sortBy" :sortDir="sortDir"
                 sortRule="sortVouchers" @sort="handleSort"></Thd>
            <th>Aktionen</th>
            </thead>
            <tbody class="filterable">
            <tr v-for="voucher in vouchers" :key="'row_'+voucher.id" class="clickable" @click="open(voucher)">
                <td>{{ voucher.id }}</td>
                <td><strong>{{ voucher.name }}</strong></td>
                <td>{{ voucher.code }}</td>
                <td v-if="'-' !== voucher.valid_from || '-' !== voucher.valid_until">
                    <span v-if="'-' !== voucher.valid_from"><strong>{{
                            voucher.valid_from_formatted
                        }}</strong>&nbsp;</span>
                    <span v-if="'-' !== voucher.valid_until"> bis <strong>{{
                            voucher.valid_until_formatted
                        }}</strong></span></td>
                <td v-else>&ndash;</td>
                <td v-if="0 <voucher.valid_for_license">#{{ voucher.valid_for_license }}:
                    <strong>{{ voucher.valid_for_license_name }}</strong></td>
                <td v-else>&ndash;</td>
                <td v-if="0 <voucher.new_license">#{{ voucher.new_license }}: <strong>{{
                        voucher.new_license_name
                    }}</strong></td>
                <td v-else>&ndash;</td>
                <td v-if="'-' !== voucher.date_license_extension">bis <strong>{{
                        voucher.date_license_extension_formatted
                    }}</strong></td>
                <td v-else-if="'-' !== voucher.period_license_extension">um <strong>{{
                        $core.t().translatePeriod( voucher.period_license_extension )
                    }}</strong></td>
                <td v-else>&ndash;</td>
                <td style="text-align:right;">
                    <strong :class="0 < voucher.usage_count ? 'clickable_user' : ''"
                            @click="handleShowCount( $event, voucher )">{{ voucher.usage_count }}</strong></td>
                <td>
                    <div class="button cancel" @click="handleDelete( $event, voucher )">X</div>
                </td>
            </tr>
            </tbody>
            <tfoot>
            <tr :id="viewId">
                <th colspan="99" style="text-align:right;" class="filtercount">...</th>
            </tr>
            </tfoot>
        </table>
    </div>
    <Spinner v-else title="Das kann dauern" message="Gutscheine werden abgerufen..."/>
</template>

<script>
/* eslint-disable */
import Spinner        from '@/components/elements/Spinner'
import Thd            from '@/components/elements/Thd'
import UserInfo       from '@/components/elements/fullscreen/UserInfo'
import ElementFilter  from '@/components/elements/ElementFilter'
import VoucherUsage   from '@/components/elements/fullscreen/VoucherUsage'
import MixinViewReady from '@/mixins/MixinViewReady'
import Form           from '@/components/elements/fullscreen/Form'

export default {

    name      : 'LicensesVouchers',
    components: { Form, VoucherUsage, ElementFilter, UserInfo, Thd, Spinner },
    mixins    : [ MixinViewReady ],

    data()
    {
        return {
            editor        : false,
            formValues    : false,
            ready         : false,
            changeKey     : false,
            vouchers      : [],
            sortBy        : 'id',
            sortDir       : 'ascending',
            voucherCount  : undefined,
            visibleElement: false
        }
    },

    mounted()
    {

        this.sortBy = null !== this.$store.getters.sortVouchers ? this.$store.getters.sortVouchers : 'id'
        this.sortDir = null !== this.$store.getters.sortVouchers ? this.$store.getters.sortVouchers : 'ascending'
        this.listAll()

    },

    methods: {

        listAll()
        {

            let message = {
                method: 'admin.licenses_listCoupons'
            }
            this.$core.getClient()
                .request( message )
                .then( result =>
                {

                    let voucherList = []

                    for( let r in result.result )
                    {

                        let voucher = result.result[ r ]

                        if( null === voucher.valid_from )
                        {
                            voucher.valid_from = '-'
                        }
                        if( null === voucher.valid_until )
                        {
                            voucher.valid_until = '-'
                        }
                        if( null === voucher.new_license_name )
                        {
                            voucher.new_license_name = '-'
                        }
                        if( null === voucher.date_license_extension )
                        {
                            voucher.date_license_extension = '-'
                        }
                        if( null === voucher.period_license_extension )
                        {
                            voucher.period_license_extension = '-'
                        }
                        voucherList.push( voucher )

                    }

                    this.ready = true
                    this.vouchers = voucherList
                    this.changeKey = this.$core.uuidHelper.generate()
                    this.sort()

                } )

        },

        sort()
        {
            this.vouchers = this.$core.getSorter().sortObjects( this.vouchers, this.sortBy, this.sortDir )
        },

        handleSort( args )
        {

            let newDir = args.sortDir === 'ascending' ? 'descending' : 'ascending'
            this.$store.commit( 'setSortVouchers', args.id )
            this.$store.commit( 'setSortVouchersDir', newDir )
            this.sortBy = args.id
            this.sortDir = newDir
            this.sort()

        },

        open( element )
        {
            this.formValues = {
                title         : '<strong>Gutschein</strong> ' + ( element !== undefined ? 'bearbeiten' : 'erstellen' ),
                subjectCaption: 'speichern',
                fields        : [
                    {
                        caption: 'Name',
                        type   : 'TextBox',
                        id     : 'name',
                        value  : element !== undefined ? element.name : ''
                    },
                    {
                        caption: 'Gutscheincode',
                        type   : 'TextBox',
                        id     : 'code',
                        value  : element !== undefined ? element.code : ''
                    },
                    {
                        caption: 'gültig von',
                        type   : 'TextBox',
                        id     : 'valid_from',
                        value  : element !== undefined ? element.valid_from_formatted : ''
                    },
                    {
                        caption: 'gültig bis',
                        type   : 'TextBox',
                        id     : 'valid_until',
                        value  : element !== undefined ? element.valid_until_formatted : ''
                    },
                    {
                        caption: 'für Lizenz',
                        type   : 'LicenseSelector',
                        id     : 'valid_for_license',
                        value  : element !== undefined ? element.valid_for_license : ''
                    },
                    {
                        caption: 'Upgrade auf Lizenz',
                        type   : 'LicenseSelector',
                        id     : 'new_license',
                        value  : element !== undefined ? element.new_license : ''
                    },
                    {
                        caption: 'Lizenzverlängerung fix',
                        type   : 'TextBox',
                        id     : 'date_license_extension',
                        value  : element !== undefined ? element.date_license_extension_formatted : ''
                    },
                    {
                        caption: 'Lizenzverlängerung variabel',
                        type   : 'PeriodSelector',
                        id     : 'period_license_extension',
                        value  : element !== undefined ? element.period_license_extension : ''
                    }

                ]
            }

            this.editor = true
        },

        deleteVoucher( id )
        {
            this.$core.getClient()
                .request( {
                    method: 'admin.licenses_deleteCoupon',
                    id    : id
                } )
                .then( () =>
                {
                    this.$core.f().uiMessage( 'success', '<strong>Gutschein gelöscht</strong>' )
                    this.listAll()
                } )
                .catch( () =>
                {
                    this.$core.f().uiMessage( 'error', '<strong>Gutschein konnte nicht gelöscht werden</strong>' )
                } )
        },

        handleDelete( event, voucher )
        {

            event.preventDefault()
            event.stopPropagation()

            let setup = {
                show   : true,
                caption: 'Gutschein ' + voucher.code + ' löschen',
                buttons: {
                    'left' : {
                        class   : 'default',
                        caption : 'lieber nicht',
                        callback: () =>
                        {
                            console.log( 'ney' )
                        }
                    },
                    'right': {
                        class   : 'delete',
                        caption : 'löschen',
                        callback: () =>
                        {
                            this.deleteVoucher( voucher.id )
                        }
                    }
                },
                message: '<strong>Bist du sicher</strong>, dass der Gutschein gelöscht werden soll?'
            }

            this.$core.getEventManager().dispatch( 'show-modal-dialog', setup )

        },

        handleShowCount( event, voucher )
        {
            event.preventDefault()
            event.stopPropagation()
            this.voucherCount = voucher
        },

        storeVoucher( voucher )
        {

            let message = {
                method: 'admin.licenses_storeVoucher',
                values: {}
            }

            for( let v in voucher )
            {
                let value = null,
                    temp
                switch( v )
                {
                    case 'valid_from':
                    case 'valid_until':
                    case 'date_license_extension':
                        if( '' !== voucher[v] )
                        {
                            temp = voucher[v].split( '.' )
                            value = temp.reverse().join( '-' )+' 00:00:00'
                        }
                        break
                    case 'code':
                    case 'name':
                        value = voucher[v].trim()
                        break
                    case 'new_license':
                    case 'valid_for_license':
                        value = '' !== voucher[v] ? parseInt( voucher[v] ) : -1
                        break
                    case 'period_license_extension':
                        value = '' !== voucher[v] ? voucher[v].trim() : null
                        break
                }
                message.values[v] = value
            }

            console.log( message )

            this.$core.getClient()
                .request( message )
                .then( result => {

                    if( 'created' === result.result )
                    {
                        this.$core.f().uiMessage( 'success', '<strong>Gutschein angelegt</strong>' )
                    }
                    if( 'updated' === result.result )
                    {
                        this.$core.f().uiMessage( 'success', '<strong>Gutschein aktualisiert</strong>' )
                    }
                    this.close( true )

                })
                .catch( e => {
                    this.$core.f().uiMessage( 'error', '<strong>Ooops</strong>: '+e )
                    this.close()
                })

        },

        close( refresh )
        {
            this.editor = false
            this.formValues = false
            this.visibleElement = false
            if( refresh )
            {
                this.listAll()
            }
        }

    }

}
</script>