<template>
    <transition appear name="fade">
        <div id="dash-licenses">
            <h2>Aktive Lizenzen</h2>
            <p>Aktive Lizenzen, die noch nicht abgelaufen sind:<br/><strong>{{ total }}</strong> Lizenzen insgesamt</p>
            <DisplayLicenseStats :height=300 v-if="isready" id="dash-licenses" :data="data" />
            <DisplayDummyDougnut :height=300 id="dash-licenses" v-else />
        </div>
    </transition>
</template>

<script>
import DisplayLicenseStats from '@/components/elements/boxes/stats/DisplayLicenseStats'
import DisplayDummyDougnut from '@/components/elements/boxes/stats/DisplayDummyDougnut'

export default {
    name      : 'DashLicenseStats',
    components: { DisplayDummyDougnut, DisplayLicenseStats },
    emits     : [ 'ready' ],

    data()
    {
        return {

            isready  : false,
            data   : {
                labels: [ '...', '...', '...' ],
                set   : [ 10, 20, 30 ]
            },
            tickKey: false,
            total  : 0,
            timer  : null
        }
    },

    created()
    {
        this.timer = setInterval( () =>
        {
            if( !this.isready )
            {
                let rand1 = Math.floor( Math.random() * 30 ),
                    rand2 = Math.floor( Math.random() * 30 ),
                    rand3 = Math.floor( Math.random() * 30 )
                this.data.set = [ rand1, rand2, rand3 ]
                this.tickKey = this.$core.uuid().generate()
            }
            else
            {
                clearTimeout( this.timer )
            }
        }, 1000 )
    },

    mounted()
    {
        let message = {
            method: 'admin.stats_getLicenseStatistics'
        }

        this.$core.getClient()
            .request( message )
            .then( result =>
            {

                let data = result.result
                this.data = data
                let total = 0
                for( let d in data.set )
                {
                    total += data.set[ d ]
                }
                this.total = total
                this.tickKey = this.$core.uuid().generate()

                this.$emit( 'ready' )
                this.isready = true

            } )
            .catch( () =>
            {
            } )
    }

}
</script>