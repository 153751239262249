<script>
export default {
    name: "MixinViewReady",
    
    created()
    {
        this.viewId = 'I-' + this.$core.uuid().generate()
    },
    updated()
    {
        this.$nextTick( () =>
        {
            this.$core.getEventManager()
                .dispatch( 'on-view-ready', ( this.viewId ) )
        } )
    },
    
    data()
    {
        return {
            viewId: false
        }
    }
}
</script>