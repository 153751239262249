<template>
    <transition appear name="fade">
        <div id="dash-regional">
            <h2>PLZ-Gebiete</h2>
            <p>Wo kommen unsere Nutzer:innen her?<br/><strong>{{ total }}</strong> auswertbare Datensätze insgesamt</p>
            <DisplayRegionalStats id="dash-regional" :height=300 v-if="isready" :data="data"/>
            <DisplayDummyDougnut id="dash-regional" :height=300 v-else/>
        </div>
    </transition>
</template>

<script>

import DisplayRegionalStats from '@/components/elements/boxes/stats/DisplayRegionalStats'
import DisplayDummyDougnut  from "@/components/elements/boxes/stats/DisplayDummyDougnut";

export default {
    name      : 'DashRegionalformStats',
    components: { DisplayDummyDougnut, DisplayRegionalStats },
    emits     : [ 'ready' ],

    data()
    {
        return {
            isready: false,
            data   : false,
            total  : false
        }
    },
    /*eslint-disable*/
    mounted()
    {
        let message = {
            method: 'admin.stats_getZipStatistics'
        }

        this.$core.getClient()
            .request( message )
            .then( response =>
            {

                let data  = response.result,
                    stats = {},
                    prep  = {
                        labels: [],
                        set   : []
                    }

                this.total = 0

                for( let d in data )
                {

                    this.total++

                    let row = data[ d ]
                    let splitted = row.split( / /g )
                    let zip = splitted[ 0 ]

                    if( isNaN( zip ) )
                    {
                        this.count( stats, 'invalid' )
                    }
                    else
                    {
                        if( 5 === zip.length )
                        {
                            this.count( stats, zip.substr( 0, 1 ) )
                        }
                        else
                        {
                            this.count( stats, 'non-de' )
                        }
                    }

                }

                for( let s in stats )
                {
                    let label = s
                    switch( s )
                    {
                        case 'non-de':
                            label = 'außerhalb DE'
                            break
                        case 'invalid':
                            label = 'ungültig'
                            break
                        default:
                            label = s + '...'
                            break
                    }
                    prep.labels.push( label )
                    prep.set.push( stats[ s ] )
                }

                this.data = prep
                this.isready = true
                this.$emit( 'ready' )

            } )
            .catch( () =>
            {
            } )
    },

    methods: {
        count( stats, key )
        {
            if( undefined === stats[ key ] )
            {
                stats[ key ] = 0
            }
            stats[ key ]++
        }
    }

}
</script>