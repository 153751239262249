export default class HelpdeskHelper
{

    constructor( core )
    {

        if( !HelpdeskHelper.instance )
        {

            this.store = core.getStore()
            this.client = core.getClient()
            this.eventManager = core.getEventManager()
            this.cacher = core.getCacher()
            this.badge = core.getBadge()

            this.f = core.f()

            this.boxes = {
                'inbox' : {
                    box  : 'INBOX',
                    mails: []
                },
                'outbox': {
                    box  : 'INBOX.Sent',
                    mails: []
                }
            }

            this.title = document.querySelector( 'title' ).textContent

            this.eventManager.append( 'on-usercache-ready', userlist =>
            {
                this.users = userlist
                this.userFor = {}
                this.ready = true
            } )

            this.workerTimer = setInterval( () =>
            {
                this.worker()
            }, 1000 )

            this.ready = false
            this.lastFetch = 0
            this.interval = 3000
            this.lastCount = 0

            HelpdeskHelper.instance = this

        }

        return HelpdeskHelper.instance

    }

    worker()
    {
        if( 1 === this.store.getters.authorized
            && Date.now() >= ( this.lastFetch + this.interval )
            && true === this.ready )
        {
            this.fetchNewCount()
        }
    }

    /*eslint-disable*/
    fetchBox( box, offset, limit )
    {
        return new Promise( ( resolve, reject ) =>
        {

            this.client.request( {
                method: 'admin.helpdesk_listAll',
                box   : box,
                offset: offset || 0,
                limit : limit
            } ).then( data =>
            {

                return resolve( data )

            } ).catch( e =>
            {
                return reject( e )
            } )

        } )
    }

    fetchMailCount( box )
    {
        return new Promise( resolve =>
        {
            this.client.request( {
                method: 'admin.helpdesk_mailCount',
                box   : this.boxes[ box ].box
            } ).then( data =>
            {
                return resolve( data.result.count_total )
            } )
        } )
    }

    fetchNewCount()
    {
        return new Promise( resolve =>
        {
            this.client.request( {
                method: 'admin.helpdesk_newCount'
            } ).then( data =>
                {

                    let count = parseInt( data.result.count_total )
                    if( count > this.lastCount )
                    {
                        this.eventManager
                            .dispatch( 'on-new-mails' )
                        this.f.uiMessage( 'info', 'Neue Nachricht empfangen: Im Helpdesk wartet eine neue Anfrage auf dich.' )
                    }
                    if( count !== this.lastCount )
                    {
                        this.eventManager
                            .dispatch( 'on-mail-count', count )
                        this.lastCount = count
                    }

                    this.badge.setCount( 'messages', count )

                } )
                .catch( e =>
                {
                    console.log( 'error:', e )
                } )
        } )

    }

    parseStatus( mail, marker )
    {

        if( null === mail.datetimeRead )
        {
            return {
                plain: 'a-0-unread',
                html : '<span class="mail-label unread">ungelesen</span>'
            }
        }

        let rawReaders = null !== mail.readers ? mail.readers.split( /\|/g ) : [],
            readers    = [],
            readerInfo = []

        for( let r in rawReaders )
        {

            let reader           = rawReaders[ r ],
                temp             = reader.split( /:::/g ),
                readerInfoSingle = {
                    reader: '' + temp[ 0 ],
                    tsmp  : '' + temp[ 1 ]
                }

            readers.push( temp[ 0 ] )
            readerInfo.push( JSON.parse( JSON.stringify( readerInfoSingle ) ) )

        }

        mail.readerInfo = readerInfo
        mail.readerList = readers

        if( -1 < readers.indexOf( '' + this.store.getters.idUser ) )
        {
            return {
                plain: 'b-0-read',
                html : '<span class="mail-label read">gelesen (' + ( readers.length ) + ')</span>'
            }
        }
        else
        {
            return {
                plain: 'b-0-read-but-new',
                html : '<span class="mail-label read-but-new">gelesen (' + ( readers.length ) + ')</span>'
            }
        }

    }

    parseBox( box )
    {
        for( let r in this.boxes[ box ].mails )
        {

            let res = this.boxes[ box ].mails[ r ]
            res.subject = decodeURIComponent( escape( atob( res.subject ) ) )
            let headers = JSON.parse( atob( res.headers ) )

            let regex = /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/g
            let matches = regex.exec( 'inbox' === box ? '' + res.sender : '' + res.recipient )
            if( undefined !== headers.replyTo && 'inbox' === box )
            {
                let regexTo = /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/g
                matches = regexTo.exec( headers.replyTo )
            }

            let test = ''
            if( null !== matches )
            {
                test = matches[ 0 ]
            }
            else
            {
                test = res.sender
            }

            for( let u in this.users )
            {
                if( null !== test && null !== this.users[ u ].email )
                {
                    if( this.users[ u ].email.toLowerCase().trim() === test.toLowerCase().trim() )
                    {

                        res.username = this.users[ u ].username
                        this.userFor[ res.id ] = this.users[ u ]
                        res.user = this.users[ u ]
                    }
                }
            }

            if( undefined === res.user )
            {
                res.username = 'ZZZZZZZZZZZZ_FALSE'
            }

            let format = res.datetimeReceived.replace( ',', '' ).split( ' ' )
            let date = format[ 0 ].split( '.' )
            let ami = date.reverse().join( '-' )
            let tsmp = new Date( ami + ' ' + format[ 1 ] ).getTime()
            res.tsmp = tsmp

            let status = this.parseStatus( res )
            res.status = status.plain
            res.statusHtml = status.html

            if( res.id > this.maxId )
            {
                this.maxId = res.id
                this.eventManager.dispatch( 'on-max-id', this.maxId )
            }

        }
    }

    fetchMails( box, offset )
    {
        return new Promise( resolve =>
        {

            if( !this.ready )
            {
                setTimeout( () =>
                {
                    return resolve( this.fetchMails( box, offset ) )
                }, 500 )
            }
            else
            {

                let promises = []

                if( undefined !== box )
                {
                    promises.push( () =>
                    {
                        return this.fetchBox( this.boxes[ box ].box, offset, 50 )
                                   .then( data =>
                                   {
                                       this.boxes[ box ].mails = data.result
                                   } )
                    } )
                }
                else
                {
                    for( let b in this.boxes )
                    {
                        promises.push( () =>
                        {
                            return this.fetchBox( this.boxes[ b ].box, offset, 50 )
                                       .then( data =>
                                       {
                                           this.boxes[ b ].mails = data.result
                                           this.parseBox( b )
                                       } )
                        } )
                    }
                }

                this.f.promiseRunner( promises )
                    .then( () =>
                    {

                        this.lastFetch = Date.now()
                        if( undefined !== box )
                        {
                            this.parseBox( box )
                            return resolve( this.boxes[ box ].mails )
                        }
                        else
                        {
                            return resolve()
                        }

                    } )

            }

        } )

    }

    markRead( box, message )
    {

        for( let m in this.boxes[ box ].mails )
        {
            if( this.boxes[ box ].mails[ m ].internalId === message.id )
            {

                let mail = this.boxes[ box ].mails[ m ]
                mail.readerList = mail.readerList || []
                mail.readers = mail.readers || ''
                if( -1 === mail.readerList.indexOf( '' + this.store.getters.idUser ) )
                {

                    mail.readers += '|' + this.store.getters.idUser + ':::NOW'
                    mail.datetimeRead = Date.now()
                    let status = this.parseStatus( mail, true )
                    mail.status = status.plain
                    mail.statusHtml = status.html

                }

            }
        }

    }

}
