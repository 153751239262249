<template>
    <div class="fullscreen-await">
        <div class="fsa-spinner">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                 style="margin:auto;background:transparent;display:block;" width="500px" height="500px"
                 viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                <path d="M10 50A40 41 0 0 0 90 50A40 41.5 0 0 1 10 50" fill="#1f2f4c" stroke="none">
                    <animateTransform attributeName="transform" type="rotate" dur="0.5s" repeatCount="indefinite"
                                      keyTimes="0;1" values="0 50 51;360 50 51"></animateTransform>
                </path>
            </svg>
        </div>
        <div class="logo"></div>
    </div>
</template>

<script>
export default {
    name: "FullscreenAwait"
}
</script>