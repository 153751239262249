<template>
    <vue3-chart-js :data="dataset"
                   :ref="'chartref-'+id"
                   :id="'chart-'+id"
                   type="doughnut"/>
</template>
<script>
import Vue3ChartJs from '@j-t-mcc/vue3-chartjs'

export default {
    name : 'DisplayDummyDougnut',
    props: [ 'id' ],

    components: {
        Vue3ChartJs
    },

    data()
    {
        return {

            dataset: {
                datasets: [ {

                    data           : [ 10, 20, 30, 40 ],
                    backgroundColor: [
                        'lightgreen',
                        'lightblue',
                        'lightyellow',
                        'salmon'
                    ]

                } ]
            }
        }
    },

    mounted()
    {

        let boundaries = document.querySelector( '#' + this.$props.id ),
            doughnut = boundaries.querySelector( '#chart-' + this.$props.id )

        doughnut.classList.add( 'spinning' )

        this.timer = setInterval( () =>
        {
            let rand1 = Math.floor( Math.random() * 30 ),
                rand2 = Math.floor( Math.random() * 30 ),
                rand3 = Math.floor( Math.random() * 30 ),
                rand4 = Math.floor( Math.random() * 30 )

            this.dataset.datasets[ 0 ].data = [ rand1, rand2, rand3, rand4 ]
            this.$refs[ 'chartref-' + this.$props.id ].update()

        }, 1000 )

    },

    beforeUnmount()
    {
        clearInterval( this.timer )
    }

}
</script>