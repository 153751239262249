<template>
    <transition appear name="next">
        <div v-if="display"
             v-html="message"
             :class="'message-space '+className"
             @click="display = false">
        </div>
    </transition>
</template>

<script>
export default {

    name: 'MessageSpace',
    data()
    {
        return {
            display  : false,
            className: 'info',
            message  : '',
            timeout  : 20000
        }
    },

    created()
    {
        this.$core.getEventManager()
            .add( 'on-message-out', setup => {

                this.display = true
                this.className = setup.className
                this.message = setup.message

                setTimeout( () => {

                    this.display = false

                }, this.timeout )

            })
    }
}
</script>