<template>
    <div>
        <MonitoringLegacy v-if="tab === 'legacy'"/>
        <MonitoringCluster v-if="tab === 'cluster'"/>
    </div>
</template>

<script>
import MonitoringLegacy  from '@/components/pages/tabs/monitoring/Legacy'
import MonitoringCluster from '@/components/pages/tabs/monitoring/Cluster'

export default {

    name      : 'Monitoring',
    components: { MonitoringCluster, MonitoringLegacy },
    data()
    {
        return {
            tab: 'cluster'
        }
    },
    created()
    {
        this.$core.getEventManager()
            .add( 'on-set-filter', () =>
            {
                this.initialize()
            } )
        this.initialize()
    },
    beforeDestroy()
    {
        this.$core.getEventManager()
            .remove( 'on-set-filter' )
    },
    methods: {
        initialize()
        {
            let sub = this.$core.getState( 'navigation.activeSub' )
            this.tab = undefined === sub || 'monitoring/legacy' === sub ? 'legacy' : 'cluster'
        }
    }
}
</script>