<template>
    <div>
        <vue3-chart-js :data="dataset"
                       :height="height"
                       :ref="'chartref-'+id"
                       :id="'chart-'+id"
                       type="line"/>
    </div>
</template>
<script>
import Vue3ChartJs from '@j-t-mcc/vue3-chartjs'

export default {
    name : 'DisplayDummyLines',
    props: [ 'id', 'height' ],

    components: {
        Vue3ChartJs
    },

    data()
    {
        return {

            dataset: {

                labels  : [ '...', '...', '...', '...' ],
                datasets: [
                    {

                        data           : [ 10, 20, 30, 40 ],
                        fill           : true,
                        label          : '...',
                        backgroundColor: 'lightgreen',
                        borderColor    : 'green',
                        borderWidth    : 1,
                        tension        : 0.5,
                        opacity        : 0.2

                    },
                    {

                        data           : [ 40, 30, 20, 10 ],
                        fill           : true,
                        label          : '...',
                        backgroundColor: 'lightblue',
                        borderColor    : 'blue',
                        borderWidth    : 1,
                        tension        : 0.3,
                        opacity        : 0.5

                    },
                    {

                        data           : [ 10, 20, 20, 10 ],
                        fill           : true,
                        label          : '...',
                        backgroundColor: 'lightyellow',
                        borderColor    : 'yellow',
                        borderWidth    : 1,
                        tension        : 0.9,
                        opacity        : 0.5

                    }
                ]
            }
        }
    },

    mounted()
    {

        this.timer = setInterval( () =>
        {

            for( let i = 0; i < 3; i++ )
            {
                let rand1 = Math.floor( Math.random() * 30 ),
                    rand2 = Math.floor( Math.random() * 30 ),
                    rand3 = Math.floor( Math.random() * 30 ),
                    rand4 = Math.floor( Math.random() * 30 )

                this.dataset.datasets[ i ].data = [ rand1, rand2, rand3, rand4 ]
            }
            this.$refs[ 'chartref-' + this.$props.id ].update()

        }, 1000 )

    },

    beforeUnmount()
    {
        clearInterval( this.timer )
    }

}
</script>