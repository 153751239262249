export default class CacheParserUsers
{

    constructor( parent )
    {
        
        this.parent = parent
        return this
        
    }
    
    parseServerList( serverList )
    {

        let userlist = [],
            users = serverList.result
        
        for( let u in users )
        {

            let user = users[ u ]

            user.type = this.parent.reformatter.formatUserType( user )
            user.type_plain = this.parent.reformatter.formatUserTypePlain( user )

            if( ( user.lastname === null || user.lastname === false || user.lastname === 'false' )
                && ( user.firstname === null || user.firstname === false || user.firstname === 'false' ) )
            {
                user.name = '-'
            }
            else
            {
                if( user.lastname.trim() === '' && user.firstname.trim() === '' )
                {
                    user.name = '-'
                }
                else
                {
                    user.name = '<strong>' + user.lastname + '</strong>, ' + user.firstname
                }
            }

            if( user.school === false || user.school === null )
            {
                user.school = '-'
            }

            if( user.school_zipcity === false || user.school_zipcity === null )
            {
                user.school_zipcity = '-'
            }

            if( user.datetime_last_meta_formatted === null )
            {
                user.datetime_updated = '1970-01-01 00:00:00'
                user.datetime_last_meta_formatted = '-'
            }

            user.datetime_last_meta_formatted = this.parent.reformatter.formatUserActivity( user.datetime_updated, user.datetime_last_meta_formatted )
            user.activityLevel = this.parent.reformatter.activityLevel( user.datetime_updated )
            if( user.app_version === null )
            {
                user.app_version = '-'
                user.app_version_sortable = 0
            }
            else
            {

                user.app_version_sortable = '' + user.app_version
                let test = user.app_version_sortable.split( '-' )
                user.app_version_sortable = test[ 0 ]
                let p = user.app_version_sortable.split( /\./ )
                let newSort = ''
                for( let i in p )
                {
                    newSort += p[ i ].length < 2 ? '0' + p[ i ] : p[ i ]
                }
                if( newSort.length < 8 )
                {
                    newSort += "00"
                }
                user.app_version_sortable = parseInt( newSort )

            }

            if( 0 < user.object_count )
            {
                user.status = 'active'
            }

            userlist.push( user )
        }

        if( -1 === this.userCount )
        {
            this.userCount = userlist.length
            this.diff = 0
        }
        else
        {
            this.diff = userlist.length - this.userCount
        }

        this.parent.eventManager
            .dispatch( 'on-user-count', this.diff )

        this.parent.store.commit( 'setUsersCache', userlist )
        return userlist
       
    }


}