<template>
    <transition appear name="slide-left">
        <div class="fullscreen">
            <MailEditor v-if="false !== email" :rcp="email" @close="email = false"/>
            <div class="content">
                <div class="title">
                    <div class="headline"
                         v-html="'#'+element.id+' | <strong>'+element.username+'</strong> | '+element.name"></div>
                    <div class="closer" @click="close()">
                        X
                    </div>
                </div>
                <div class="info">
                    <div class="row-container left">
                        <div class="infobox">
                            <h2>Stammdaten</h2>
                            <table class="margin-top infotable">
                                <tr>
                                    <td class="center strong">Benutzername</td>
                                    <td class="center">{{ element.username }}</td>
                                </tr>
                                <tr>
                                    <td class="center strong">Name</td>
                                    <td class="center">{{ element.lastname }}</td>
                                </tr>
                                <tr>
                                    <td class="center strong">Vorname</td>
                                    <td class="center">{{ element.firstname }}</td>
                                </tr>
                                <tr>
                                    <td class="center strong">E-Mail-Adresse</td>
                                    <td class="center"><strong class="clickable_user" @click="prepareMail()">
                                        {{ element.email }}
                                    </strong></td>
                                </tr>
                                <tr>
                                    <td class="center strong">Schule</td>
                                    <td class="center">{{ element.school }}</td>
                                </tr>
                                <tr>
                                    <td class="center strong">&nbsp;</td>
                                    <td class="center">{{ element.school_zipcity }}</td>
                                </tr>
                                <tr>
                                    <td class="center strong">&nbsp;</td>
                                    <td class="center">&nbsp;</td>
                                </tr>
                                <tr>
                                    <td class="center strong">angelegt am</td>
                                    <td class="center">{{ toLocal( element.datetime_created ) }}</td>
                                </tr>
                                <tr>
                                    <td class="center strong">Referrer</td>
                                    <td class="center"><a :href="element.referrer" rel="noreferrer"
                                                          target="_blank">{{ element.referrer || 'n/a' }}</a></td>
                                </tr>
                                <tr>
                                    <td class="center strong">Status</td>
                                    <td class="center"
                                        v-html="$core.getReformatter().translateStatus( element.status )"></td>
                                </tr>
                            </table>
                        </div>
                        <UserLicense :element="element" @localRefresh="init()" @refresh="$emit( 'refresh' )"/>
                        <UserInvoices :element="element"/>
                        <UserBillingAddress :element="element"/>
                        <div class="infobox">
                            <Opener id="simpleStats" :state="openSimpleStats" inline=true
                                    caption="Nutzungs- und Performancestatistiken"
                                    @clicked="openSimpleStats = !openSimpleStats"/>
                            <template v-if="openSimpleStats">
                                <h3>Nutzungsstatistik</h3>
                                <table v-if="false !== stats" class="margin-top infotable">
                                    <tr v-for="( value, s ) in stats"
                                        :key="'tr-stats-'+s">
                                        <td class="center strong">{{ s }}</td>
                                        <td class="center strong">{{ value }}</td>
                                    </tr>
                                </table>
                                <div class="spacer"></div>
                                <h3>Cryptostatistik (letzte aktive Session)</h3>
                                <table v-if="false !== cryptostats" class="margin-top infotable">
                                    <tr v-for="( value, s ) in cryptostats"
                                        :key="'tr-stats-'+s">
                                        <td class="center strong">{{ s }}</td>
                                        <td class="center strong">{{ value }}</td>
                                    </tr>
                                </table>
                            </template>
                        </div>
                        <UserLoginStats :element="element"/>
                        <UserSessionStats :element="element"/>
                    </div>
                    <div class="row-container right">
                        <UserEditor :element="element" @localRefresh="init()" @refresh="$emit( 'refresh' )"/>
                        <UserPlatformFlags :element="element"/>
                        <UserMeta :element="element"/>
                        <UserSessions :element="element"/>
                        <UserObjects :element="element"/>
                        <UserSharedObjects :element="element"/>
                        <UserFailCounters :element="element"/>
                        <UserNotes :element="element"/>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import UserMeta           from '@/components/elements/boxes/UserMeta'
import UserObjects        from '@/components/elements/boxes/UserObjects'
import UserPlatformFlags  from '@/components/elements/boxes/UserPlatformFlags'
import UserLicense        from '@/components/elements/boxes/UserLicense'
import UserEditor         from '@/components/elements/boxes/UserEditor'
import UserInvoices       from '@/components/elements/boxes/UserInvoices'
import UserBillingAddress from '@/components/elements/boxes/UserBillingAddress'
import UserSessions       from '@/components/elements/boxes/UserSessions'
import UserSharedObjects  from '@/components/elements/boxes/UserSharedObjects'
import UserFailCounters   from '@/components/elements/boxes/UserFailCounters'
import Opener             from '@/components/elements/Opener'
import UserSessionStats   from '@/components/elements/boxes/UserSessionStats'
import MailEditor         from '@/components/elements/helpdesk/MailEditor'
import UserNotes          from "@/components/elements/boxes/UserNotes";
import UserLoginStats     from "@/components/elements/boxes/UserLoginStats";

export default {

    name      : 'UserInfo',
    components: {
        UserLoginStats,
        UserNotes,
        MailEditor,
        UserSessionStats,
        UserFailCounters,
        UserSharedObjects,
        UserSessions,
        UserBillingAddress,
        UserInvoices,
        UserEditor,
        UserLicense,
        UserPlatformFlags,
        UserObjects,
        UserMeta,
        Opener
    },
    props     : [ 'element' ],
    emits     : [ 'refresh', 'close' ],

    data()
    {
        return {
            email          : false,
            stats          : false,
            cryptostats    : false,
            openSimpleStats: false
        }
    },

    created()
    {

        this.init()

    },

    methods: {

        prepareMail()
        {
            let rcp = '"' + this.$props.element.firstname + ' ' + this.$props.element.lastname + '" <' + this.$props.element.email + '>'
            this.email = rcp
        },

        close()
        {
            this.$emit( 'close' )
        },

        init()
        {

            let message = {
                method : 'admin.users_getStatistics',
                id_user: this.$props.element.id
            }

            this.$core.getClient()
                .request( message )
                .then( response =>
                {

                    let stats = {},
                        cryptoStats = {},
                        fetched = response.result

                    for( let f in fetched )
                    {

                        if( 'pagehit_' === fetched[ f ].statsKey.substr( 0, 8 ) )
                        {

                            let temp = fetched[ f ].statsKey.split( '_' ),
                                sub = temp[ 1 ].split( '-' ),
                                pagename = temp[ 0 ] + '_' + sub[ 0 ]

                            if( undefined !== stats[ pagename ] )
                            {
                                stats[ pagename ] += parseInt( fetched[ f ].statsValue )
                            }
                            else
                            {
                                stats[ pagename ] = parseInt( fetched[ f ].statsValue )
                            }
                        }
                        else
                        {
                            if( isNaN( fetched[ f ].statsKey ) )
                            {
                                switch( fetched[ f ].statsKey )
                                {
                                    case '_CryptoStatisticsLastTotalCount':
                                        cryptoStats[ 'Crypto-Operationen gesamt'] = fetched[ f ].statsValue
                                        break
                                    case '_CryptoStatisticsLastTotalTime':
                                        cryptoStats[ 'Crypto-Zeit gesamt (in ms)'] = fetched[ f ].statsValue
                                        break
                                    case '_CryptoStatisticsMean':
                                        cryptoStats[ 'Crypto-Zeit im Schnitt (ms/Operation)'] = ( fetched[ f ].statsValue / 1000 ).toFixed( 3 )
                                        break

                                    default:
                                        if( undefined !== this.stats[ fetched[ f ].statsKey ] )
                                        {
                                            stats[ fetched[ f ].statsKey ] += parseInt( fetched[ f ].statsValue )
                                        }
                                        else
                                        {
                                            stats[ fetched[ f ].statsKey ] = parseInt( fetched[ f ].statsValue )
                                        }
                                }
                            }
                        }

                    }

                    let prepared = {}
                    for( let key in stats )
                    {

                        prepared[ this.$core.t().translate( key ) ] = stats[ key ]

                    }

                    this.stats = this.$core.getSorter().plainSort( prepared )
                    this.cryptostats = this.$core.getSorter().plainSort( cryptoStats )
                    this.$forceUpdate()

                } )

        },

        toLocal( timestamp )
        {
            if( null !== timestamp )
            {
                let temp = timestamp.split( 'T' ),
                    date = temp[ 0 ].split( '-' ).reverse().join( '.' ),
                    time = temp[ 1 ].split( '.' ).shift() + ' Uhr'
                return date + ', ' + time
            }
            return 'n/a'
        }

    }
}
</script>