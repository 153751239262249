<template>
    <div class="filterbox" :key="key">
        <div id="filter">
            <div class="left">Ansicht filtern:</div>
            <div id="querybox" class="right"><input :key="'query-'+key" type="text" class="observable" :id="id+'query'"
                                                    @input="handleFilters( true )"/>
                <input type="text" :key="'hquery-'+key" class="observable" style="display:none; visibility:hidden;"
                       :id="id+'hquery'" @input="handleFilters()"/>
            </div>
            <div class="filter-clear" @click="handleFilterClear(true)">
                X
            </div>
            <div class="list-refresh" @click="$emit( 'refresh' )">
                &#10227;
            </div>
            <div v-if="showStopButton" :class="'list-stop'+( stopped ? ' active' : '')" @click="handleStop()">
                &Vert;
            </div>
            <div class="clearfix"></div>
        </div>
        <div v-if="filtering || initialFilter" :class="'filtering'+( showAdder ? ' with-adder' : '')">
        </div>
        <div id="filter_count"
             :class="'filtercount top'+( filtering || initialFilter ? ' hidden' : '' )+( showAdder ? ' with-adder' : '')">
            ...
        </div>
        <div v-if="showAdder" class="button add large right" @click="$emit( 'add' )">+</div>
    </div>
</template>

<script>
export default {

    name : 'ElementFilter',
    props: [ 'viewKey', 'filterKey', 'showAdder', 'noFilterStore', 'showHideFor', 'showStopButton', 'sub' ],
    emits: [ 'filtersActive', 'add', 'refresh' ],

    data()
    {
        return {
            filter        : false,
            filtered      : -1,
            prepared      : false,
            resultCount   : 0,
            originalBody  : '',
            keyupTimer    : false,
            filtering     : false,
            filtersUpdated: false,
            refreshTimer  : false,
            hasStopButton : false,
            stopped       : false,
            initialFilter : true,
            id            : '',
            key           : 'querybox-' + Date.now()
        }
    },

    created()
    {
        if( this.$props.sub === true )
        {
            this.id = 'sub-'
        }
        this.$core.getEventManager().add( 'reset-filter', () =>
        {
            this.handleFilterClear()
        } )
    },

    mounted()
    {
        setTimeout( () =>
        {
            this.refreshFilter()
        }, 1000 )
    },

    watch: {
        viewKey: {
            immediate: true,
            handler( newValue, oldValue )
            {
                if( newValue !== oldValue
                    && oldValue !== undefined )
                {
                    this.refreshFilter()
                }
            }
        },
        filterKey: {
            immediate: true,
            handler( newValue, oldValue )
            {
                if( newValue !== oldValue
                    && oldValue !== undefined )
                {
                    this.handleFilters()
                }
            }
        }
    },

    beforeDestroy()
    {
        this.$core.getEventManager().remove( 'reset-filter' )
        clearTimeout( this.keyupTimer )
    },

    methods: {

        refreshFilter()
        {
            this.$nextTick()
                .then( () =>
                {
                    this.waitForTable()
                        .then( () =>
                        {
                            this.handleFilters()
                            this.prepared = true
                        } )
                } )
        },

        waitForTable()
        {

            return new Promise( resolve =>
            {

                let tbody = document.querySelector( '.filterable' )
                if( null !== tbody )
                {

                    let table = document.querySelector( 'table' )
                    if( '' === this.originalBody )
                    {
                        this.originalBody = table
                    }

                    this.prepareFilterStore()
                    return resolve()

                } else
                {
                    setTimeout( () =>
                    {
                        return resolve( this.waitForTable() )
                    }, 300 )
                }

            } )

        },

        prepareFilterStore()
        {

            return new Promise( resolve =>
            {

                if( true === this.$props.noFilterStore )
                {

                    let queryElm = document.querySelector( '#' + this.id + 'query' )
                    let hqueryElm = document.querySelector( '#' + this.id + 'hquery' )

                    queryElm.value = ''
                    hqueryElm.value = ''

                    this.prepared = true
                    return resolve()
                }

                if( !this.prepared )
                {

                    let filters = this.$store.getters.filters,
                        route = this.$route.name

                    if( filters === null )
                    {
                        filters = {}
                    } else
                    {
                        filters = JSON.parse( filters )
                    }

                    if( undefined === filters[ route ] )
                    {
                        filters[ route ] = {
                            query : '',
                            hquery: ''
                        }
                    }

                    this.filter = filters
                    this.$store.commit( 'setFilters', JSON.stringify( this.filter ) )

                    let queryElm = document.querySelector( '#' + this.id + 'query' )
                    let hqueryElm = document.querySelector( '#' + this.id + 'hquery' )

                    queryElm.value = filters[ route ].query
                    hqueryElm.value = filters[ route ].hquery

                    return resolve()

                } else
                {
                    return resolve()
                }

            } )

        },

        handleFilters( timeoutFirst )
        {

            if( timeoutFirst )
            {
                if( this.keyupTimer )
                {
                    clearTimeout( this.keyupTimer )
                }
                setTimeout( () =>
                {
                    this.handleFilters()
                }, 1500 )

                return
            }

            if( !this.filtering )
            {

                let query = document.querySelector( '#' + this.id + 'query' ),
                    hidden = document.querySelector( '#' + this.id + 'hquery' )

                if( null !== query && null !== hidden )
                {

                    this.$emit( 'filtersActive', ( query.value !== '' || hidden.value !== '' ) )

                    this.filtering = true
                    this.$nextTick()
                        .then( () =>
                        {
                            setTimeout( () =>
                            {

                                this.updateFilter( query.value, hidden.value )
                                this.prepareUi( query.value, hidden.value )
                                    .then( () =>
                                    {
                                        this.haltObserver = false
                                        this.initialFilter = false
                                        this.filtering = false
                                    } )

                            }, 300 )

                        } )

                }

            }

        },

        updateFilter( query, hquery )
        {

            if( this.prepared )
            {
                if( true !== this.$props.noFilterStore )
                {
                    let route = this.$route.name
                    if( this.filter[ route ].query !== query
                        || this.filter[ route ].hquery !== hquery )
                    {

                        this.filtersUpdated = true

                        this.filter[ route ] = {
                            query : query,
                            hquery: hquery
                        }

                        this.$store.commit( 'setFilters', JSON.stringify( this.filter ) )

                    }
                }
            }

        },

        handleFilterClear( localOnly )
        {

            let query = document.querySelector( '#' + this.id + 'query' ),
                hidden = document.querySelector( '#' + this.id + 'hquery' )

            query.value = ''
            hidden.value = localOnly ? hidden.value : ''

            this.updateFilter( query.value, hidden.value )
            this.handleFilters()

        },

        prepareUi( query, hidden )
        {

            return new Promise( resolve =>
            {

                this.filtered = 0

                let tbody     = document.querySelector( '.filterable' )
                if( undefined === tbody )
                {
                    return resolve()
                }

                let rows      = tbody.querySelectorAll( 'tr' ),
                    check     = query.toLowerCase(),
                    hiddenVal = hidden.trim(),
                    nocount   = false

                for( let r in rows )
                {
                    let row = rows[ r ]
                    if( 'nocount' === row.id )
                    {
                        nocount = true
                    }

                    if( undefined !== row.innerHTML )
                    {

                        let test = row.innerText.toLowerCase()
                        if( ( hiddenVal.length === 0 || -1 < test.indexOf( hiddenVal ) )
                            && ( check.length < 2 || -1 < test.indexOf( check ) ) )
                        {
                            this.filtered++
                            row.classList.remove( 'hidden' )
                        } else
                        {
                            row.classList.add( 'hidden' )
                        }
                    }
                }

                this.resultCount = nocount ? 0 : rows.length

                if( this.filtered === rows.length )
                {
                    this.filtered = -1
                }

                this.initialFilter = false
                this.setFilterResults()

                return resolve()

            } )

        },

        setFilterResults()
        {

            let count = document.querySelectorAll( '.filtercount' )
            let text = '<strong>' + this.resultCount + '</strong> Ergebnis' + ( 1 !== this.resultCount ? 'se' : '' ) + ' insgesamt'
            if( -1 !== this.filtered )
            {
                text += ', <strong>' + this.filtered + '</strong> gefiltert'
            }

            for( let t in count )
            {
                let topCount = count[ t ]
                if( topCount instanceof HTMLElement )
                {
                    topCount.innerHTML = text
                }
            }

        },

        handleStop()
        {
            this.stopped = !this.stopped
            this.$emit( 'stop' )
        }

    }
}
</script>