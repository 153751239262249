<template>
    <vue3-chart-js :data="dataset"
                   :ref="'chartref-'+id"
                   :id="'chart-'+id"
                   type="doughnut"/>
</template>
<script>
import Vue3ChartJs from '@j-t-mcc/vue3-chartjs'

export default {
    name : 'DisplayLicenseStats',
    props: [ 'id', 'data', 'tick', 'height' ],

    components: {
        Vue3ChartJs
    },

    data()
    {
        return {
            dataset: {
                datasets: [ {

                    data           : [ 10, 20, 30, 40 ],
                    backgroundColor: [
                        'lightgreen',
                        'lightblue',
                        'lightyellow',
                        'salmon'
                    ]

                } ]
            }
        }
    },

    created()
    {
        this.prepare()
    },

    mounted()
    {
        this.render()
    },

    watch: {
        tick: {
            handler()
            {
                this.render()
            }
        }
    },

    methods: {

        prepare()
        {

            let labels   = [],
                dataset  = [],
                colorSet = [
                    'lightgreen',
                    'orange',
                    'lightblue',
                    'pink',
                    'lightyellow',
                    'cyan',
                    'red',
                    'lightgrey',
                    'lightbrown',
                    'greenyellow',
                    'salmon',
                    'lavender',
                    'fuchsia',
                    'springgreen'
                ]


            for( let d = 0; d < this.$props.data.set.length; d++ )
            {

                let set = this.$props.data.set[ d ]
                if( 0 < set )
                {
                    labels.push( this.$props.data.labels[ d ] )
                    dataset.push( set )
                }

            }

            this.dataset = {
                labels  : labels,
                datasets: [
                    {
                        backgroundColor: colorSet,
                        data           : dataset
                    }
                ]
            }

        },

        render()
        {
            this.$refs[ 'chartref-' + this.$props.id ].update()
        }

    }

}
</script>