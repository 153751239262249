<template>
    <div v-if="ready">
        <ElementFilter @refresh="handleRefresh()"
                       :showAdder="!$core.r().hasFlag( 'adminOnlySelf' )"
                       @add="handleForm( {} )"/>
        <Form v-if="editor"
              :formValues="formValues"
              :errors="formErrors"
              @close="editor = false; formErrors = undefined"
              @doubleClick="handleDoubleClick"
              @submit="storeAdmin"/>
        <table>
            <thead>
            <tr>
                <Thd id="message_id" caption="#" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortAdmins"
                     @sort="handleSort"/>
                <Thd id="last_name" caption="Nachname" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortAdmins"
                     @sort="handleSort"/>
                <Thd id="first_name" caption="Vorname" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortAdmins"
                     @sort="handleSort"/>
                <Thd id="username" caption="Benutzername" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortAdmins"
                     @sort="handleSort"/>
                <Thd id="role" caption="Rolle" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortAdmins"
                     @sort="handleSort"/>
                <Thd id="email" caption="E-Mail-Adresse" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortAdmins"
                     @sort="handleSort"/>
                <Thd id="created_formatted" caption="angelegt" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortAdmins"
                     @sort="handleSort"/>
                <Thd id="active_formatted" caption="aktiv" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortAdmins"
                     @sort="handleSort"/>
                <Thd id="updated_formatted" caption="aktualisiert" :sortBy="sortBy" :sortDir="sortDir"
                     sortRule="sortAdmins"
                     @sort="handleSort"/>
            </tr>
            </thead>
            <tbody class="filterable">
            <template v-for="admin in admins" :key="'row_'+admin.id">
                <template v-if="!$core.r().hasFlag( 'adminOnlySelf' ) || admin.id === $store.getters.idUser">
                    <tr class="clickable" @click="handleForm( admin )">
                        <td>{{ admin.id }}</td>
                        <td><strong>{{ admin.last_name }}</strong></td>
                        <td>{{ admin.first_name }}</td>
                        <td><strong>{{ admin.username }}</strong></td>
                        <td>{{ admin.role }}</td>
                        <td><strong>{{ admin.email }}</strong></td>
                        <td>{{ admin.created_formatted.replace( /__/g, ' ' ) }}</td>
                        <td>
                            <span v-if="sessions[admin.id][0].active > 0" class="good"><strong>aktiv</strong></span>
                            <span v-else class="error">inaktiv</span>
                        </td>
                        <td>{{
                                ( admin.updated_formatted === null || admin.updated_formatted.indexOf( '00.00.0000' ) > -1 ? 'noch nie' : admin.updated_formatted.replace( /__/g, ' ' ) )
                            }}
                        </td>
                    </tr>
                </template>
            </template>
            </tbody>
            <tfoot>
            <tr :id="viewId">
                <th colspan="99" style="text-align:right;" class="filtercount">...</th>
            </tr>
            </tfoot>
        </table>
    </div>
    <Spinner v-else title="Gleich geht's weiter" message="Administrator-Konten werden abgerufen..."/>
</template>

<script>

import ElementFilter  from '@/components/elements/ElementFilter'
import Spinner        from '@/components/elements/Spinner'
import Thd            from '@/components/elements/Thd'
import MixinViewReady from '@/mixins/MixinViewReady'
import Form           from '@/components/elements/fullscreen/Form'

export default {
    name      : 'AdminUsers',
    components: { Form, Thd, Spinner, ElementFilter },
    mixins    : [ MixinViewReady ],

    data()
    {
        return {
            ready         : false,
            editor        : false,
            formValues    : {},
            formErrors    : false,
            sortBy        : 'id',
            sortDir       : 'ascending',
            admins        : [],
            sessions      : {},
            visibleElement: false,
            openRows      : []
        }
    },

    created()
    {
        this.init()
        this.crypto = require( 'crypto' )
    },

    methods: {

        init()
        {

            this.$core.getClient()
                .request( {
                    method: 'admin.admin_listAll'
                } )
                .then( response =>
                {

                    this.admins = response.result.accounts
                    this.sessions = response.result.sessions
                    this.sort()

                } )

        },

        generateSecurePassword( length )
        {

            let result           = '',
                characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789üöäÜÖÄß!§$%&/(),;.:-_',
                charactersLength = characters.length

            for( let i = 0; i < length; i++ )
            {
                result += characters.charAt( Math.floor( Math.random() *
                                                         charactersLength ) )
            }

            return result

        },

        handleDoubleClick( event )
        {
            if( 'password' === event )
            {
                let elm = document.querySelector( '#password' )
                if( null !== elm )
                {
                    elm.value = this.generateSecurePassword( 24 )
                }
            }
        },

        handleForm( values )
        {

            this.formValues = {
                title : '<strong>Neues Administrator-Konto</strong> anlegen',
                fields: [
                    {
                        caption: 'Benutzername',
                        type   : 'TextBox',
                        id     : 'username',
                        value  : values.username || ''
                    },
                    {
                        caption    : 'Passwort',
                        type       : 'TextBox',
                        id         : 'password',
                        value      : values.username !== undefined ? '' : this.generateSecurePassword( 24 ),
                        placeholder: 'nur bei Änderung ausfüllen oder Doppelklicken'
                    },
                    {
                        caption: 'Vorname',
                        type   : 'TextBox',
                        id     : 'first_name',
                        value  : values.first_name || ''
                    },
                    {
                        caption: 'Nachname',
                        type   : 'TextBox',
                        id     : 'last_name',
                        value  : values.last_name || ''
                    },
                    {
                        caption: 'E-Mail-Adresse',
                        type   : 'TextBox',
                        id     : 'email',
                        value  : values.email || ''
                    },
                    {
                        caption: 'Absendername im Helpdesk',
                        type   : 'TextBox',
                        id     : 'helpdesk_signature',
                        value  : values.helpdesk_signature || ''
                    },
                    {
                        caption: 'Rolle',
                        type   : this.$core.r().hasFlag( 'adminOnlySelf' ) ? 'hidden' : 'RoleSelector',
                        id     : 'id_role',
                        value  : values.id_role || ''
                    },
                    {
                        type : 'hidden',
                        id   : 'id',
                        value: values.id
                    },
                    {
                        type : 'hidden',
                        id   : 'mode',
                        value: values.username !== undefined ? 'edit' : 'create'
                    }
                ]
            }

            this.editor = true

        },

        storeAdmin( values )
        {

            if( values.password.trim() !== ''
                && 16 > values.password.length )
            {
                this.formErrors = 'Es darf gerne etwas sicherer sein: 16 Zeichen minimum.'
                return
            }

            if( '' === values.username.trim()
                || '' === values.email.trim()
                || undefined === values.id_role )
            {
                this.formErrors = 'So ein paar Informationen werden durchaus benötigt.'
                return
            }

            if( values.password.trim() !== '' )
            {
                let hash = this.crypto.createHash( 'sha256' ).update( values.password ).digest( 'hex' )
                values.password = hash
            }

            this.$core.getClient()
                .request( {
                    method: 'admin.admin_storeUser',
                    values: values
                } )
                .then( () =>
                {
                    this.editor = false
                    this.$core.f().uiMessage( 'success', 'Administrator-Konto ' + ( values.mode === 'edit' ? 'gespeichert' : 'angelegt' ) )
                    this.handleRefresh()
                } )
                .catch( e =>
                {
                    this.formErrors = e
                } )

        },

        sort()
        {
            this.admins = this.$core.getSorter().sortObjects( this.admins, this.sortBy, this.sortDir )
            this.ready = true
        },

        handleRefresh()
        {
            this.init()
        },

        handleSort( args )
        {

            let newDir = args.sortDir === 'ascending' ? 'descending' : 'ascending'
            this.$store.commit( 'setSortAdmins', args.id )
            this.$store.commit( 'setSortAdminsDir', newDir )
            this.sortBy = args.id
            this.sortDir = newDir
            this.sort()

        }

    }

}
</script>