<template>
    <div v-if="ready">
        <ElementFilter/>
        <table>
            <thead>
            <Thd id="id" caption="#" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortLicenses"
                 @sort="handleSort"></Thd>
            <Thd id="name" caption="Name" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortLicenses"
                 @sort="handleSort"></Thd>
            <Thd align="right" id="costs" caption="Preis" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortLicenses"
                 @sort="handleSort"></Thd>
            <Thd align="right" id="usage_period" caption="Laufzeit" :sortBy="sortBy" :sortDir="sortDir"
                 sortRule="sortLicenses" @sort="handleSort"></Thd>
            <Thd id="feature_set" caption="Feature-Set" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortLicenses"
                 @sort="handleSort"></Thd>
            <Thd id="automatic_prolong" caption="Verlängerung" :sortBy="sortBy" :sortDir="sortDir"
                 sortRule="sortLicenses" @sort="handleSort"></Thd>
            <Thd id="customer_visible" caption="Sichtbarkeit" :sortBy="sortBy" :sortDir="sortDir"
                 sortRule="sortLicenses" @sort="handleSort"></Thd>
            <Thd align="right" id="count_users" caption="Nutzer" :sortBy="sortBy" :sortDir="sortDir"
                 sortRule="sortLicenses" @sort="handleSort"></Thd>
            </thead>
            <tbody class="filterable">
            <tr v-for="license in licenses" :key="'row_'+license.id" class="clickable" @click="open(license)">
                <td>{{ license.id }}</td>
                <td><strong>{{ license.name }}</strong></td>
                <td style="text-align: right;"><strong
                    v-html="$core.getReformatter().numberFormat( license.costs, 'price' )"></strong></td>
                <td style="text-align: right;"
                    v-html="$core.getReformatter().numberFormat( license.usage_period, 'period' )"></td>
                <td>{{ $core.getReformatter().featureSet( license.feature_set ) }}</td>
                <td v-html="$core.getReformatter().prolong( license.automatic_prolong )"></td>
                <td v-html="$core.getReformatter().customerVisible( license.customer_visible )"></td>
                <td style="text-align:right;"><strong>{{ license.count_users }}</strong></td>
            </tr>
            </tbody>
            <tfoot>
            <tr :id="viewId">
                <th colspan="99" style="text-align:right;" class="filtercount">...</th>
            </tr>
            </tfoot>
        </table>
    </div>
    <Spinner v-else title="Das kann dauern" message="Lizenzen werden abgerufen..."/>
</template>

<script>
/* eslint-disable */
import Spinner        from '@/components/elements/Spinner'
import Thd            from '@/components/elements/Thd'
import UserInfo       from '@/components/elements/fullscreen/UserInfo'
import ElementFilter  from '@/components/elements/ElementFilter'
import MixinViewReady from '@/mixins/MixinViewReady'

export default {
    
    name      : 'LicensesList',
    components: { ElementFilter, UserInfo, Thd, Spinner },
    mixins    : [ MixinViewReady ],
    data()
    {
        return {
            ready         : false,
            licenses      : [],
            sortBy        : 'id',
            sortDir       : 'ascending',
            visibleElement: false
        }
    },
    
    mounted()
    {
        
        this.sortBy = null !== this.$store.getters.sortUsers ? this.$store.getters.sortUsers : 'id'
        this.sortDir = null !== this.$store.getters.sortUsersDir ? this.$store.getters.sortUsersDir : 'ascending'
        this.listAll()
        
    },
    
    methods: {
        
        listAll()
        {
            
            if( this.$store.getters.licensesCache === null )
            {
                let message = {
                    method: 'admin.licenses_listAll'
                }
                this.$core.getClient()
                    .request( message )
                    .then( result =>
                    {
                        
                        let licenseList = []
                        
                        for( let r in result.result )
                        {
                            
                            let license = result.result[ r ]
                            licenseList.push( license )
                            
                        }
                        
                        this.ready = true
                        
                        this.$store.commit( 'setLicensesCache', licenseList )
                        this.licenses = licenseList
                        this.sort()
                        
                    } )
            }
            else
            {
                this.licenses = this.$store.getters.licensesCache
                this.sort()
                this.ready = true
            }
            
        },
        
        sort()
        {
            this.licenses = this.$core.getSorter().sortObjects( this.licenses, this.sortBy, this.sortDir )
        },
        
        handleSort( args )
        {
            
            let newDir = args.sortDir === 'ascending' ? 'descending' : 'ascending'
            this.$store.commit( 'setSortLicenses', args.id )
            this.$store.commit( 'setSortLicensesDir', newDir )
            this.sortBy = args.id
            this.sortDir = newDir
            this.sort()
            
        },
        
        open( element )
        {
            this.visibleElement = element
        },
        
        close()
        {
            this.visibleElement = false
        }
        
        
    }
    
    
}
</script>