<template>
    <div class="infobox" v-if="ready">
        <Opener id="objectcounts" :state="open" inline=true
                :caption="'Fremde Objekte im Zugriff'+( !open ? '<span style=\'font-weight:normal\'>: '+info.total+' insgesamt</span>' : '' )"
                @clicked="open = !open"/>
        <template v-if="open">
            <table class="margin-top infotable" v-if="undefined !== info">
                <tr>
                    <td class="center strong">Schüler</td>
                    <td class="center">{{ info.student }}</td>
                </tr>
                <tr>
                    <td class="center strong">Klassen</td>
                    <td class="center">{{ info.class }}</td>
                </tr>
                <tr>
                    <td class="center strong">Gruppen</td>
                    <td class="center">{{ info.group }}</td>
                </tr>
                <tr>
                    <td class="center strong">Listen</td>
                    <td class="center">{{ info.list }}</td>
                </tr>
                <tr>
                    <td class="center strong">Notizen</td>
                    <td class="center">{{ info.note }}</td>
                </tr>
                <tr>
                    <td class="center strong">Termine</td>
                    <td class="center">{{ info.date }}</td>
                </tr>
                <tr>
                    <td class="center strong">Todos</td>
                    <td class="center">{{ info.todo }}</td>
                </tr>
                <tr>
                    <td class="center strong">Vorlagen</td>
                    <td class="center">{{ info.template }}</td>
                </tr>
                <tr>
                    <td class="center strong">Profilbilder</td>
                    <td class="center">{{ info.avatar }}</td>
                </tr>
                <tr>
                    <td class="center strong">Nachrichten</td>
                    <td class="center">{{ info.message }}</td>
                </tr>
                <tr>
                    <td class="center strong">Kolleg:innen</td>
                    <td class="center">{{ info.colleague }}</td>
                </tr>
                <tr>
                    <td class="center strong">Teams</td>
                    <td class="center">{{ info.team }}</td>
                </tr>
            </table>
            <table class="margin-top infotable" v-else>
                <tr>
                    <td class="center strong">&nbsp;</td>
                    <td class="center"><h3 class="error">keine Informationen verfügbar</h3></td>
                </tr>
            </table>
        </template>
    </div>
</template>

<script>

import Opener from '@/components/elements/Opener'

export default {
    
    name      : 'UserSharedObjects',
    components: { Opener },
    props     : [ 'element' ],
    
    data()
    {
        return {
            info : {
                student  : 0,
                class    : 0,
                group    : 0,
                list     : 0,
                note     : 0,
                date     : 0,
                todo     : 0,
                colleague: 0,
                total    : 0,
                message  : 0,
                template : 0,
                team     : 0,
                avatar   : 0
            },
            open : false,
            ready: false
        }
    },
    
    mounted()
    {
        
        this.getMeta()
        
    },
    
    methods: {
        
        getMeta()
        {
            let message = {
                method : 'admin.users_getSharedObjects',
                id_user: this.$props.element.id
            }
            
            this.$core.getClient()
                .request( message )
                .then( result =>
                {
                    
                    for( let r in result.result )
                    {
                        let row = result.result[ r ]
                        this.info[ row.objectType ] = parseInt( row.objectCount )
                        this.info[ 'total' ] += parseInt( row.objectCount )
                    }
                    this.ready = true
                    
                } )
                .catch( () =>
                {
                    this.ready = true
                } )
        }
        
    }
    
}
</script>