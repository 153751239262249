function Event( name )
{
    this.name = name
    this.callbacks = []
    this.multis = {}
}

Event.prototype.registerCallback = function( callback )
{
    this.callbacks.push( callback )
}

function Reactor()
{
    this.events = {}
}

Reactor.prototype.registerEvent = function( eventName )
{
    this.events[ eventName ] = new Event( eventName )
}

Reactor.prototype.unregisterEvent = function( eventName )
{
    delete ( this.events[ eventName ] )
}

Reactor.prototype.dispatchEvent = function( eventName, eventArgs )
{
    this.events[ eventName ].callbacks.forEach( function( callback )
    {
        callback( eventArgs )
    } )
}

Reactor.prototype.isRegistered = function( eventName )
{

    return undefined !== this.events[ eventName ]

}

Reactor.prototype.addEventListener = function( eventName, callback )
{
    this.events[ eventName ].registerCallback( callback )
}

export default class EventManager
{

    constructor( core )
    {

        if( !EventManager.instance )
        {

            this.core = core
            this.reactor = new Reactor()

            EventManager.instance = this

        }

        return EventManager.instance

    }

    destruct()
    {

        delete this.reactor
        this.reactor = null
        delete EventManager.instance

    }

    add( eventName, callback )
    {

        this.reactor.registerEvent( eventName )
        this.reactor.addEventListener( eventName, callback )

    }

    append( eventName, callback )
    {

        if( !this.reactor.isRegistered( eventName ) )
        {
            this.reactor.registerEvent( eventName )
        }
        this.reactor.addEventListener( eventName, callback )

    }

    addOnce( eventName, callback )
    {
        if( !this.isRegistered( eventName ) )
        {
            this.add( eventName, callback )
        }
    }

    replace( eventName, callback )
    {

        if( this.isRegistered( eventName ) )
        {
            this.remove( eventName )
        }

        this.add( eventName, callback )

    }

    remove( eventName )
    {

        if( this.isRegistered( eventName ) )
        {
            this.reactor.unregisterEvent( eventName )
        }

    }

    dispatch( eventName, args )
    {

        if( this.isRegistered( eventName ) )
        {
            this.reactor.dispatchEvent( eventName, args )
        }

    }

    dispatchAndRemove( eventName, args )
    {

        if( this.isRegistered( eventName ) )
        {
            this.reactor.dispatchEvent( eventName, args )
            this.reactor.unregisterEvent( eventName )
        }

    }

    waitAndDispatch( interval, lastTick, eventName, args )
    {

        if( this.isRegistered( eventName ) )
        {
            this.reactor.dispatchEvent( eventName, args )
        }
        else
        {
            let now = new Date().getTime()
            if( lastTick === 0 )
            {
                lastTick = now
            }
            if( now > ( lastTick + interval ) )
            {
                return
            }
            else
            {
                setTimeout( () => {
                    this.waitAndDispatch( interval, lastTick, eventName, args )
                }, 100 )
            }
        }

    }

    isRegistered( eventName )
    {

        return this.reactor.isRegistered( eventName )

    }

}