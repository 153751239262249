<template>
    <div class="mail-attachment" v-if="ready">
        {{ isPdf }}
        <ImageZoom v-if="zoom && isImage"
                   :filename="filename"
                   :isVideo="isVideo"
                   :isPdf="isPdf"
                   :data-uri="downloadable"
                   @close="zoom = false"/>
        <TextZoom v-if="zoom && isText"
                  :filename="filename"
                  :data-uri="this.downloadable"
                  @close="zoom = false"/>
        <template v-if="isImage">
            <div class="attachment-image"
                 @click="zoom = true"
                 :style="'background-image:url('+downloadable+')'"></div>
        </template>
        <template v-if="isText">
            <div class="attachment-text"
                 @click="zoom = true">
                <span><strong>DATEI ÖFFNEN</strong></span>
            </div>
        </template>
        <strong>{{ filename || 'kein Name' }}</strong><br/>
        {{ mimeType }} ({{ fileSize }}kb)
    </div>
</template>

<script>
import ImageZoom from './ImageZoom'
import TextZoom  from '@/components/elements/helpdesk/TextZoom'

export default {
    name      : 'Attachment',
    components: { TextZoom, ImageZoom },
    props     : {
        attachment: { Type: Object, required: true }
    },
    data()
    {
        return {
            downloadable: false,
            mimeType    : false,
            filename    : '',
            fileSize    : 0,
            ready       : false,
            isImage     : false,
            isVideo     : false,
            isPdf       : false,
            isText      : false,
            zoom        : false
        }
    },
    created()
    {

        let att = this.$props.attachment.attachment

        let binaryBuffer = new Buffer( new Buffer( att.data, 'base64' ).toString( 'binary' ), 'base64' )
        let base64 = btoa( binaryBuffer )

        this.downloadable = 'data:' + this.$props.attachment.mime_type + ';base64,' + base64
        this.filename = this.$props.attachment.filename
        this.mimeType = this.$props.attachment.mime_type
        let mimeSplit = this.mimeType.split( '/' )

        this.isImage = mimeSplit[ 0 ] === 'image'
        this.isText = mimeSplit[ 0 ] === 'text' || mimeSplit[ 0 ] === 'message'
        if( 'video' === mimeSplit[ 0 ] )
        {
            this.isImage = true
            this.isVideo = true
        }
        if( 'pdf' === mimeSplit[ 1 ] )
        {
            this.isImage = true
            this.isPdf = true
        }

        this.fileSize = ( this.$props.attachment.file_size / 1024 ).toFixed( 2 )

        this.ready = true

    },

    methods: {}

}
</script>