<template>
    <div>
        <template v-for="( row, index ) in display" :key="'row-'+index">
            <div class="row-adder-container">
                <div class="width-90">
                    <select class="select-css inset" :id="'type_'+index" @change="fill( row, index )">
                        <option :selected="undefined === row.type" value="">bitte wählen...</option>
                        <option :selected="'time' === row.type" value="time">Zeit</option>
                        <option :selected="'freetext' === row.type" value="freetext">Freitext</option>
                    </select>
                    <select class="select-css inset" :id="'height_'+index" @change="fill( row, index )">
                        <option :selected="undefined === row.height" value="">bitte wählen...</option>
                        <template v-for="( value, key ) in slotHeights" :key="'slot_'+key">
                            <option :value="key" :selected="row.height === value">
                                {{ key }} ({{ value }}%)
                            </option>
                        </template>
                    </select>
                    <input type="text" placeholder="Beschriftung" :id="'val_'+index" :value="row.value"
                           @change="fill( row, index )"/>
                    <input type="hidden" :id="'value_'+index"/>
                </div>
                <div class="width-10">
                    <template v-if="index === ( display.length - 1 )">
                        <div class="button round large add" @click="add()">
                            +
                        </div>
                    </template>
                    <template v-else>
                        <div class="button round large delete" @click="remove( index )">
                            x
                        </div>
                    </template>
                </div>
                <div class="clearfix"></div>
            </div>
        </template>
    </div>
</template>

<script>

export default {

    name : 'SlotSetup',
    props: [ 'value', 'id', 'rowType', 'setup' ],

    data()
    {
        return {
            ready      : true,
            slotHeights: {
                'XS': 7.5,
                'S' : 10,
                'M' : 15,
                'L' : 20,
                'XL': 30
            },
            display    : []
        }
    },

    created()
    {

        this.display = this.$props.value ? JSON.parse( this.$props.value ) : []
        if( undefined === this.$props.value )
        {
            this.add()
        }
    },

    mounted()
    {
        for( let i in this.display )
        {
            this.fill( undefined, i )
        }
    },

    beforeDestroy()
    {
        this.ready = false
    },

    methods: {

        handleValue( index, row, key, event, value )
        {

            value = value || event.target.value
            this.display[ index ][ key ] = value
            row[ key ] = value
            this.fillSelectAndText( row, index )

        },

        /*eslint-disable*/
        fill( row, idx )
        {

            let elmInput  = document.querySelector( '#value_' + idx ),
                elmType   = document.querySelector( '#type_' + idx ),
                elmHeight = document.querySelector( '#height_' + idx ),
                elmValue  = document.querySelector( '#val_' + idx )

            if( null !== elmInput && null !== elmType && null !== elmHeight && null !== elmValue )
            {
                this.display[ idx ] = {
                    type  : elmType.value,
                    height: this.slotHeights[ elmHeight.value ],
                    value : elmValue.value
                }
                elmInput.value = JSON.stringify( this.display[ idx ] )
            }
        },

        add()
        {
            this.display.push( { type: undefined, value: undefined, height: undefined } )
        },

        remove( index )
        {
            this.display.splice( index, 1 )
        }

    }

}
</script>