<template>
    <div>
        <select class="select-css inset" :id="id" @change="select">
            <option v-if="undefined === preselect" value="">bitte wählen...</option>
            <template v-for="item in cols">
                <option v-if="undefined === preselect || item.key === preselect"
                        :key="'opt-'+item.key"
                        :value="item.key"
                        :selected="item.key === value">
                    {{ item.caption }}
                </option>
            </template>
        </select>
    </div>
</template>

<script>
export default {

    name : 'ColumnTypeSelector',
    props: [ 'value', 'id' ],
    emits: [ 'select' ],

    data()
    {
        return {
            ready    : true,
            preselect: undefined,
            cols     : [
                {
                    key    : 'checkbox',
                    caption: 'Checkbox',
                    type   : 'text'
                },
                {
                    key    : 'textbox',
                    caption: 'Textzeile',
                    type   : 'text'
                },
                {
                    key    : 'textarea',
                    caption: 'Freitext',
                    type   : 'text'
                },
                {
                    key    : 'datebox',
                    caption: 'Datum',
                    type   : 'text'
                },
                {
                    key    : 'numberbox',
                    caption: 'Zahl',
                    type   : 'text'
                },
                {
                    key    : 'rateselector',
                    caption: 'Bewertung',
                    type   : 'text'
                },
                {
                    key    : 'rateplantselector',
                    caption: 'Feedback-Element "Pflanze"',
                    type   : 'text'
                },
                {
                    key    : 'ratesmileselector',
                    caption: 'Feedback-Element "Smileys" | :-( bis :-D',
                    type   : 'text'
                },
                {
                    key    : 'ratesmileselectorreverse',
                    caption: 'Feedback-Element "Smileys" | :-D bis :-(',
                    type   : 'text'
                },
                {
                    key    : 'scoreBox',
                    caption: 'Benotung',
                    type   : 'scoreBox'
                },
                {
                    key    : 'threewaytoggle',
                    caption: 'Ja / Nein / Keine Angabe',
                    type   : 'text'
                },
                {
                    key    : 'studentData',
                    caption: 'Schülerdaten',
                    type   : 'studentData'
                }
            ]
        }
    },

    created()
    {
    },

    mounted()
    {
        this.preselection()
        this.$core.getEventManager()
            .add( 'on-listtype-select', () => {
                this.preselection()
            })
    },

    beforeDestroy()
    {
        this.ready = false
        this.cols = []
        this.$core.getEventManager()
            .remove( 'on-listtype-select' )
    },

    methods: {

        select( event, value )
        {
            this.$emit( 'select', value || event.target.value )
        },

        preselection()
        {
            let elm = document.querySelector( '#listType' )
            if( null !== elm )
            {
                switch( elm.value )
                {
                    case 'checklist':
                        this.preselect = 'checkbox'
                        this.select( undefined, this.preselect )
                        break
                    case 'ratinglist':
                        this.preselect = 'rateselector'
                        this.select( undefined, this.preselect )
                        break
                    default:
                        this.preselect = undefined
                        break
                }
            }
        }
    }

}
</script>