<template>
    <div>
        <div v-if="assignedTo !== false">
            <h3>Zugewiesen</h3>
            <br/>
            <div class="button nonfixed delete"
                 @click="unassign()">{{ assignedName }}
            </div>
            <div v-if="assignedTo !== this.$store.getters.idUser"
                 class="button nonfixed set"
                 @click="reassign()">übernehmen
            </div>
            <div class="clearfix"></div>
        </div>
        <div v-else>
            <h3>niemandem zugewiesen</h3>
            <br/>
            <div class="button nonfixed set"
                 @click="assign()">mir zuweisen
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
</template>

<script>
export default {

    name: "HelpdeskAssignment",

    emits: [ 'refresh' ],

    props: {
        mail: { type: Object, required: true }
    },

    data()
    {
        return {
            assignedTo  : false,
            assignedName: false
        }
    },

    created()
    {
        this.checkAssignment( true )
    },

    methods: {

        checkAssignment( autoTakeOver )
        {

            let message = {
                method: 'admin.helpdesk_getAssignment',
                id    : this.$props.mail.internalId
            }

            this.$core.getClient()
                .request( message )
                .then( response =>
                {

                    this.assignedTo = response.id_assigned
                    this.assignedName = response.first_name

                } )
                .catch( () => {

                    this.assignedTo = false
                    this.assignedName = false

                    if( autoTakeOver === true )
                    {
                        this.assign()
                    }

                })

        },

        assign()
        {
            let message = {
                method: 'admin.helpdesk_setAssignment',
                id    : this.$props.mail.internalId
            }

            this.$core.getClient()
                .request( message )
                .then( () =>
                {

                    this.$emit( 'refresh' )
                    this.checkAssignment()

                } )
        },

        unassign()
        {
            let message = {
                method: 'admin.helpdesk_clearAssignment',
                id    : this.$props.mail.internalId
            }

            this.$core.getClient()
                .request( message )
                .then( () =>
                {

                    this.$emit( 'refresh' )
                    this.checkAssignment()

                } )
        },

        reassign()
        {
            let message = {
                method: 'admin.helpdesk_updateAssignment',
                id    : this.$props.mail.internalId
            }

            this.$core.getClient()
                .request( message )
                .then( () =>
                {

                    this.$emit( 'refresh' )
                    this.checkAssignment()

                } )
        }


    }
}
</script>