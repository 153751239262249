<template>
    <transition appear name="slide-left">
        <div class="fullscreen">
            <div class="content">
                <div class="title">
                    <div class="headline">Transaktionsdetails zu PayPal-Zahlung #<strong>{{ data.order.id }}</strong>
                    </div>
                    <div class="closer" @click="$emit( 'close' )">
                        X
                    </div>
                </div>
                
                <div class="info padded noscroll">
                    <div class="log-space payment-details">
                        <h2>Order</h2>
                        <div class="form-row">
                            <div class="form-left">
                                <strong>Order-ID</strong>
                            </div>
                            <div class="form-right">
                                <strong>{{ data.order.id }}</strong>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-left">
                                <strong>Intent</strong>
                            </div>
                            <div class="form-right">
                                {{ data.order.intent }}
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-left">
                                <strong>Status</strong>
                            </div>
                            <div class="form-right">
                                {{ data.order.status }}
                            </div>
                        </div>
                        <div class="clearfix"></div>
                        <div class="spacer"></div>
                        <h2>Käufer</h2>
                        <div class="form-row">
                            <div class="form-left">
                                <strong>Name</strong>
                            </div>
                            <div class="form-right">
                                <strong>{{ info.shipping.name.full_name }}</strong>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-left">
                                <strong>Anschrift</strong>
                            </div>
                            <div class="form-right">
                                {{ info.shipping.address.address_line_1 }}
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-left">
                                &nbsp;-
                            </div>
                            <div class="form-right">
                                {{ info.shipping.address.address_line_2 }}
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-left">
                                <strong>&nbsp;-</strong>
                            </div>
                            <div class="form-right">
                                {{ info.shipping.address.country_code }} {{ info.shipping.address.postal_code }} {{ info.shipping.address.admin_area_2 }}
                            </div>
                        </div>
                        <div class="clearfix"></div>
                        <div class="spacer"></div>
                        <h2>Transaktion</h2>
                        <div class="form-row">
                            <div class="form-left">
                                <strong>Transaktions-ID</strong>
                            </div>
                            <div class="form-right">
                                <strong>{{ info.payments.captures[0].id }}</strong>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-left">
                                <strong>Status</strong>
                            </div>
                            <div class="form-right">
                                {{ info.payments.captures[0].status }}
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-left">
                                <strong>Betrag</strong>
                            </div>
                            <div class="form-right">
                                {{ info.payments.captures[0].amount.value }} &euro;
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-left">
                                <strong>Final Capture</strong>
                            </div>
                            <div class="form-right">
                                {{ info.payments.captures[0].final_capture }}
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-left">
                                <strong>Zeitstempel</strong>
                            </div>
                            <div class="form-right">
                                {{ info.payments.captures[0].create_time }}
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-left">
                                <strong>Zeitstempel (Update)</strong>
                            </div>
                            <div class="form-right">
                                {{ info.payments.captures[0].update_time }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    
    name : 'PaymentData',
    props: [ 'data' ],
    emits: [ 'refresh', 'close' ],
    
    data()
    {
        return {
            info: {}
        }
    },
    
    created()
    {
        for( let u in this.$props.data.order.purchase_units )
        {
            let unit = this.$props.data.order.purchase_units[u]
            this.info = unit
        }
    }
}
</script>