<template>
    <div>
        <select class="select-css inset" :id="id" @change="$core.getEventManager().dispatch( 'on-listtype-select' )">
            <option value="">bitte wählen...</option>
            <option v-for="item in listTypes" :key="'opt-'+item" :value="item" :selected="item === value">
                {{ translateListType( item ) }}
            </option>
        </select>
    </div>
</template>

<script>
export default {
    
    name : 'ListTypeSelector',
    props: [ 'value', 'id' ],
    emits: [ 'select' ],

    data()
    {
        return {
            ready   : true,
            listTypes : [
                'checklist', 'ratinglist', 'customFixed', 'recallList', 'test'
            ]
        }
    },
    
    created()
    {
    },

    beforeDestroy()
    {
        this.ready = false
    },
    
    methods: {

        translateListType( type )
        {
            switch( type )
            {
                case 'checklist':
                    return 'einfache Checkliste'
                case 'ratinglist':
                    return 'einfache Bewertungsliste'
                case 'customFixed':
                    return 'Flex-Liste'
                case 'recallList':
                    return 'Rücklaufliste'
                case 'test':
                    return 'Klassenarbeit'
            }
        }
        
    }
    
}
</script>