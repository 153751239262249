<template>
    <div v-if="ready">
        <ElementFilter @refresh="handleRefresh()" id="listTemplates" :showAdder="true" @add="add()"/>
        <Form v-if="editor"
              :formValues="formValues"
              @close="close()"
              @submit="storeTemplate"/>
        <table>
            <thead>
            <tr>
                <Thd id="id" caption="#" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortTemplates"
                     @sort="handleSort"/>
                <Thd id="name" caption="Name in der App" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortTemplates"
                     @sort="handleSort"/>
                <Thd id="listType" caption="Listen-Typ" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortTemplates"
                     @sort="handleSort"/>
                <Thd id="featureSet" caption="verfügbar in Lizenz" :sortBy="sortBy" :sortDir="sortDir"
                     sortRule="sortTemplates"
                     @sort="handleSort"/>
                <Thd id="datetime_created" caption="erstellt" :sortBy="sortBy" :sortDir="sortDir"
                     sortRule="sortTemplates"
                     @sort="handleSort"/>
                <Thd id="datetime_updated" caption="aktualisiert" :sortBy="sortBy" :sortDir="sortDir"
                     sortRule="sortTemplates"
                     @sort="handleSort"/>
                <th>Aktionen</th>
            </tr>
            </thead>
            <tbody class="filterable">
            <tr v-for="template in templates" :key="'row_'+template.id" class="clickable" @click="open(template)">
                <td><strong>{{ template.id }}</strong></td>
                <td>{{ template.name }}</td>
                <td><strong>{{ translateListType( template.listType ) }}</strong></td>
                <td v-html="translateLicensing( template.featureSet )"></td>
                <td>{{ $core.getReformatter().formatTimestamp( template.datetimeCreated ) }}</td>
                <td>{{ $core.getReformatter().formatTimestamp( template.datetimeUpdated ) }}</td>
                <td>
                    <div class="button cancel" :id="'delete_'+template.id" @click="handleDelete">X</div>
                </td>
            </tr>
            </tbody>
            <tfoot>
            <tr :id="viewId">
                <th colspan="99" style="text-align:right;" class="filtercount">...</th>
            </tr>
            </tfoot>
        </table>
    </div>
    <Spinner v-else title="Gleich geht's weiter" message="Listen-Vorlagen werden abgerufen..."/>
</template>

<script>

import ElementFilter  from '@/components/elements/ElementFilter'
import Spinner        from '@/components/elements/Spinner'
import Thd            from '@/components/elements/Thd'
import Form           from '@/components/elements/fullscreen/Form'
import MixinViewReady from '@/mixins/MixinViewReady'

export default {
    name      : 'ToolsListtemplates',
    components: { Form, Thd, Spinner, ElementFilter },
    mixins    : [ MixinViewReady ],

    data()
    {
        return {
            ready     : false,
            editor    : false,
            listTypes : [
                'checklist', 'ratinglist', 'customFixed', 'recallList', 'test'
            ],
            formValues: {},
            sortBy    : 'identifier',
            sortDir   : 'ascending',
            templates : []
        }
    },

    created()
    {
        this.init()
    },

    methods: {

        init()
        {

            this.templates = []
            this.$core.getClient()
                .request( {
                    method: 'admin.tools_listListTemplates'
                } )
                .then( response =>
                {
                    let list = response.result
                    for( let l in list )
                    {
                        this.templates.push( list[ l ] )
                    }
                    this.sort()
                } )

        },

        _fetchColumns( id )
        {
            return new Promise( ( resolve, reject ) =>
            {

                this.$core.getClient()
                    .request( {
                        method    : 'admin.tools_listListTemplatesColumns',
                        templateId: id
                    } )
                    .then( response =>
                    {
                        return resolve( response.result )
                    } )
                    .catch( () =>
                    {
                        return reject()
                    } )

            } )
        },

        open( template )
        {

            this._fetchColumns( template.id )
                .then( columns =>
                {

                    this.formValues = {
                        title : 'Template <strong>' + template.name + '</strong> bearbeiten',
                        fields: [
                            {
                                caption: 'Identifier (automatisch)',
                                type   : 'hidden',
                                id     : 'identifier',
                                value  : template.identifier
                            },
                            {
                                caption: 'Name in der App',
                                type   : 'TextBox',
                                id     : 'name',
                                value  : template.name,
                                hook   : {
                                    id  : 'identifier',
                                    type: 'cleanId'
                                }
                            },
                            {
                                caption: 'Listen-Typ',
                                type   : 'ListTypeSelector',
                                id     : 'listType',
                                value  : template.listType
                            },
                            {
                                caption: 'verfügbar in Lizenz',
                                type   : 'FeatureSetSelector',
                                id     : 'featureSet',
                                value  : template.featureSet
                            },
                            {
                                caption: 'Spalten',
                                type   : 'RowAdder',
                                rowType: 'selectAndText',
                                setup  : {
                                    select: {
                                        key: 'columnType'
                                    },
                                    text  : {
                                        key: 'caption'
                                    },
                                    hidden: {
                                        contains  : true,
                                        idTemplate: template.id,
                                        identifier: {
                                            type  : 'cleanId',
                                            source: 'caption'
                                        }
                                    },
                                    adder : {
                                        caption   : '',
                                        columnType: '',
                                        id        : '',
                                        idTemplate: template.id,
                                        identifier: ''
                                    }
                                },
                                id     : 'columns',
                                value  : columns
                            },
                            {
                                type : 'hidden',
                                id   : 'id_author',
                                value: this.$store.getters.idUser
                            },
                            {
                                type : 'hidden',
                                id   : 'id',
                                value: template.id
                            },
                            {
                                type : 'hidden',
                                id   : 'mode',
                                value: 'edit'
                            }
                        ]
                    }

                    this.editor = true

                } )

        },

        add()
        {

            this.formValues = {
                title : '<strong>Neues</strong> Template anlegen',
                fields: [
                    {
                        caption: 'Identifier (automatisch)',
                        type   : 'hidden',
                        id     : 'identifier',
                        value  : ''
                    },
                    {
                        caption: 'Name in der App',
                        type   : 'TextBox',
                        id     : 'name',
                        value  : '',
                        hook   : {
                            id  : 'identifier',
                            type: 'cleanId'
                        }
                    },
                    {
                        caption: 'Listen-Typ',
                        type   : 'ListTypeSelector',
                        id     : 'listType',
                        value  : ''
                    },
                    {
                        caption: 'verfügbar in Lizenz',
                        type   : 'FeatureSetSelector',
                        id     : 'featureSet',
                        value  : ''
                    },
                    {
                        caption: 'Spalten',
                        type   : 'RowAdder',
                        rowType: 'selectAndText',
                        setup  : {
                            select: {
                                key: 'columnType'
                            },
                            text  : {
                                key: 'caption'
                            },
                            hidden: {
                                contains  : true,
                                identifier: {
                                    type  : 'cleanId',
                                    source: 'caption'
                                }
                            },
                            adder : {
                                caption   : '',
                                columnType: '',
                                id        : '',
                                identifier: ''
                            }
                        },
                        id     : 'columns'
                    },
                    {
                        type : 'hidden',
                        id   : 'id_author',
                        value: this.$store.getters.idUser
                    },
                    {
                        type : 'hidden',
                        id   : 'mode',
                        value: 'add'
                    }
                ]
            }

            this.editor = true

        },

        storeTemplate( values )
        {

            if( 'add' === values.mode )
            {
                values.id = 'create'
            }

            this.$core.getClient()
                .request( {
                    method: 'admin.tools_storeListTemplate',
                    setup : values
                } )
                .then( () =>
                {
                    this.editor = false
                    this.$core.f().uiMessage( 'success', '<strong>Vorlage gespeichert</strong>' )
                    this.init()
                } )

        },

        deleteTemplate( id )
        {
            this.$core.getClient()
                .request( {
                    method: 'admin.tools_deleteListTemplate',
                    templateId: id
                })
                .then( () => {
                    this.$core.f().uiMessage( 'success', '<strong>Vorlage gelöscht</strong>' )
                    this.init()
                })
                .catch( () => {
                    this.$core.f().uiMessage( 'error', '<strong>Vorlage konnte nicht gelöscht werden</strong>' )
                })
        },

        handleDelete( event )
        {

            event.preventDefault()
            event.stopPropagation()

            let id = event.target.id.replace( 'delete_', '' )

            let setup = {
                show   : true,
                caption: 'Template '+id+' löschen',
                buttons: {
                    'left' : {
                        class   : 'default',
                        caption : 'lieber nicht',
                        callback: () =>
                        {
                            console.log( 'ney' )
                        }
                    },
                    'right': {
                        class   : 'delete',
                        caption : 'löschen',
                        callback: () =>
                        {
                            this.deleteTemplate( id )
                        }
                    }
                },
                message: '<strong>Bist du sicher</strong>, dass die Listenvorlage gelscht werden soll?'
            }

            this.$core.getEventManager().dispatch( 'show-modal-dialog', setup )

        },

        close()
        {
            this.formValues = {}
            this.editor = false
        },

        sort()
        {
            this.templates = this.$core.getSorter().sortObjects( this.templates, this.sortBy, this.sortDir )
            this.ready = true
        },

        translateListType( type )
        {
            switch( type )
            {
                case 'checklist':
                    return 'einfache Checkliste'
                case 'ratinglist':
                    return 'einfache Bewertungsliste'
                case 'customFixed':
                    return 'Flex-Liste'
                case 'recallList':
                    return 'Rücklaufliste'
                case 'test':
                    return 'Klassenarbeit'
            }
        },

        translateLicensing( licenseString )
        {
            let licenses = licenseString.split( ',' ),
                result   = ''

            licenses.forEach( ( license ) =>
            {
                if( 'networked' !== license )
                {
                    let string = license === 'full' ? 'pro' : 'basis'
                    result += '<span class="version ' + license + '">' + string + '</span>'
                }
            } )

            return result
        },

        handleRefresh()
        {
            this.init()
        },

        handleSort( args )
        {

            let newDir = args.sortDir === 'ascending' ? 'descending' : 'ascending'
            this.$store.commit( 'setSortTemplates', args.id )
            this.$store.commit( 'setSortTemplatesDir', newDir )
            this.sortBy = args.id
            this.sortDir = newDir
            this.sort()

        }

    }

}
</script>