<template>
    <div class="usernote">
        <span><strong>{{ note.datetime_created_formatted }}</strong>, {{ note.first_name }} {{ note.last_name }}</span><br/>
        <p v-html="$core.f().toHtml( note.text )"></p>
    </div>
</template>

<script>
export default {

    name      : 'UserNote',
    props     : [ 'note' ]

}
</script>