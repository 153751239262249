<template>
    <vue3-chart-js :data="dataset"
                   :ref="'chartref-'+id"
                   :id="'chart-'+id"
                   type="doughnut"/>
</template>
<script>
import Vue3ChartJs from '@j-t-mcc/vue3-chartjs'

export default {
    name : 'DisplayVersionStats',
    props: [ 'id', 'data', 'tick' ],

    components: {
        Vue3ChartJs
    },

    data()
    {
        return {
            dataset: {
                datasets: [ {

                    data           : [ 10, 20, 30, 40 ],
                    backgroundColor: [
                        'lightgreen',
                        'lightblue',
                        'lightyellow',
                        'salmon'
                    ]

                } ]
            }
        }
    },

    created()
    {
        this.prepare()
    },

    mounted()
    {
        this.render()
    },

    watch: {
        tick: {
            handler()
            {
                this.render()
            }
        }
    },

    methods: {

        prepare()
        {

            let labels  = [],
                dataset = []

            for( let l in this.$props.data.labels )
            {
                let label = this.$props.data.labels[ l ]
                labels.push( label )
                dataset.push( this.$props.data.set[ label ] )
            }

            this.dataset = {
                labels  : labels,
                datasets: [
                    {
                        backgroundColor: this.$core.getColorSet( 'doughnut' ),
                        data           : dataset
                    }
                ]
            }

        },

        render()
        {
            this.$refs[ 'chartref-' + this.$props.id ].update()
        }

    }

}
</script>