<template>
    <transition appear name="fade">
        <div id="dash-userstats">
            <h2>Benutzerstatistik</h2>
            <p>Entwicklung der Nutzerzahlen in den letzten 12 Wochen,<br/><strong>{{ total }}</strong> Registrierungen insgesamt</p>
            <DisplayUserStats :height=300 id="dash-userstats" :data=data v-if="ready" />
            <DisplayDummyLines :height=300 id="dash-userstats" :data=data v-else />
        </div>
    </transition>
</template>

<script>
import DisplayUserStats from '@/components/elements/boxes/stats/DisplayUserStats'
import DisplayDummyLines from "@/components/elements/boxes/stats/DisplayDummyLines";

export default {
    name      : 'DashUserStats',
    components: { DisplayDummyLines, DisplayUserStats },
    emits     : [ 'ready' ],

    data()
    {
        return {
            ready: false,
            data : {
                active: {
                    headline: '...',
                    labels: [],
                    set: [],
                    tendence: 'fixed'
                },
                grow: {
                    headline: '...',
                    labels: [],
                    set: [],
                    tendence: 'fixed'
                },
                total: {
                    headline: '...',
                    labels: [],
                    set: [],
                    tendence: 'fixed'
                },
                deletions: {
                    headline: '...',
                    labels: [],
                    set: [],
                    tendence: 'fixed'
                },
                labels: []
            },
            total: 0
        }
    },

    created()
    {

        let message = {
            method: 'admin.stats_getUserStatistics'
        }

        this.$core.getClient()
            .request( message )
            .then( result =>
            {

                let data = result.result
                this.total = data.total.set[ ( data.total.set.length - 1 ) ]
                this.data = data
                this.ready = true
                this.$emit( 'ready' )

            } )
            .catch( () =>
            {
            } )

    },

    mounted()
    {
    }

}
</script>