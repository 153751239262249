<template>
    <transition appear name="slide-left">
        <div class="fullscreen raised">
            <div class="content">
                <div class="title">
                    <div class="headline" v-html="'<strong>'+filename+'</strong>'"></div>
                    <div class="closer" @click="$emit( 'close' )">
                        X
                    </div>
                </div>
                <template v-if="isVideo">
                    <div class="video-container">
                        <video controls>
                            <source :src="dataUri" :type="type"/>
                        </video>
                    </div>
                </template>
                <template v-if="isPdf">
                    <div class="video-container">
                        <embed :src="dataUri" :type="type" width="100%" height="100%"/>
                    </div>
                </template>
                <template v-else>
                    <div class="info noscroll">
                        <div class="image-zoom"
                             :style="'background-image:url('+dataUri+')'">
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </transition>
</template>

<script>

export default {
    name : "ImageZoom",
    props: {
        filename: { Type: String, required: true },
        dataUri : { Type: String, required: true },
        type    : { Type: String, required: false, default: 'video/mp4' },
        isVideo : { Type: Boolean, required: false, default: false },
        isPdf   : { Type: Boolean, required: false, default: false }
    },
    emits: [ 'close' ]
}
</script>