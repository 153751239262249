<template>
    <div>
        <h1>Willkommen im Administrationsbereich von entzettelt</h1>
        <div class="stats-dashboard">
            <StatsBox className="stats box tl" component="DashUserStats"/>
            <StatsBox className="stats box tc" component="DashLicenseStats"/>
            <StatsBox className="stats box tr" component="DashLoginStats"/>
            <StatsBox className="stats box bl" component="DashObjectStats"/>
            <StatsBox className="stats box bc" component="DashVersionStats"/>
            <StatsBox className="stats box br" component="DashPlatformStats" />
            <StatsBox className="stats box bl" component="DashSessionStats" />
            <StatsBox className="stats box bc" component="DashRequestStats" />
            <StatsBox className="stats box br" component="DashRegionalStats" />
            <!--<StatsBox className="stats box bc full nohover" component="DashSettings" />-->
        </div>
    </div>
</template>

<script>
import StatsBox from '@/components/elements/boxes/StatsBox'

export default {
    name      : 'Dashboard',
    components: {
        StatsBox
    }
}
</script>