<template>
    <div class="infobox" v-if="ready">
        <Opener id="invoices" :state="open" inline=true
                caption="Rechnungsanschrift"
                @clicked="open = !open"/>
        <template v-if="open">
            <table class="margin-top infotable">
                <tbody>
                <tr>
                    <td class="strong"><strong>Vorname</strong></td>
                    <td><input type="text" id="firstname" :value="address.firstname"/></td>
                </tr>
                <tr>
                    <td class="strong"><strong>Nachname</strong></td>
                    <td><input type="text" id="lastname" :value="address.lastname"/></td>
                </tr>
                <tr>
                    <td class="strong"><strong>Anschrift</strong></td>
                    <td><input type="text" id="address_1" :value="address.address_1"/></td>
                </tr>
                <tr>
                    <td class="strong"><strong>&nbsp;</strong></td>
                    <td><input type="text" id="address_2" :value="address.address_2"/></td>
                </tr>
                <tr>
                    <td class="strong"><strong>PLZ</strong></td>
                    <td><input type="text" id="zip" :value="address.zip"/></td>
                </tr>
                <tr>
                    <td class="strong"><strong>Ort</strong></td>
                    <td><input type="text" id="city" :value="address.city"/></td>
                </tr>
                <tr>
                    <td class="strong"><strong>E-Mail</strong></td>
                    <td><input type="text" id="email" :value="address.email"/></td>
                </tr>
                <tr>
                    <td colspan="2"><input type="submit" class="submit" @click="update()" value="aktualisieren"/></td>
                </tr>
                </tbody>
            </table>
        </template>
    </div>
</template>

<script>

import Opener from '@/components/elements/Opener'

export default {

    name      : 'UserBillingAddress',
    components: { Opener },
    props     : [ 'element' ],

    data()
    {
        return {
            address: {},
            open   : false,
            ready  : false
        }
    },

    mounted()
    {

        this.init()

    },

    methods: {

        init()
        {
            this.$core.getClient()
                .request( {
                    method : 'admin.users_getBillingAddress',
                    id_user: this.$props.element.id
                } )
                .then( response =>
                {
                    this.ready = true
                    this.address = response.result

                } )
        },

        update()
        {

            this.$core.getEventManager().dispatch( 'showBlocker' )

            setTimeout( () => {

                let keys = [ 'firstname', 'lastname', 'address_1', 'address_2', 'zip', 'city', 'email' ]
                let request = {
                    method : 'admin.users_setBillingAddress',
                    id_user: this.$props.element.id
                }
                for( let k in keys )
                {
                    let elm = document.querySelector( '#' + keys[ k ] )
                    request[ keys[ k ] ] = elm.value.trim()
                }

                this.$core.getClient()
                    .request( request )
                    .then( () =>
                    {
                        this.ready = false
                        this.init()
                        this.$core.getEventManager().dispatch( 'hideBlocker' )
                    } )

            }, 500 )

        }

    }

}
</script>