<template>
    <transition appear name="fade">
        <div id="dash-platforms">
            <h2>Plattformen</h2>
            <p>Genutzte Plattformen:<br/><strong>{{ total }}</strong> auswertbare Datensätze insgesamt</p>
            <DisplayPlatformStats id="dash-platforms" :height=300 v-if="isready" :data="statsData"/>
            <DisplayDummyBars id="dash-platforms" :height=300 v-else/>
        </div>
    </transition>
</template>

<script>

import useragent            from 'useragent'
import DisplayPlatformStats from '@/components/elements/boxes/stats/DisplayPlatformStats'
import DisplayDummyBars     from "@/components/elements/boxes/stats/DisplayDummyBars";

export default {
    name      : 'DashPlatformStats',
    components: { DisplayDummyBars, DisplayPlatformStats },
    emits     : [ 'ready' ],

    data()
    {
        return {
            isready       : false,
            statsData     : false,
            total         : false,
            mountProcessed: false
        }
    },

    mounted()
    {
        if( !this.mountProcessed )
        {
            this.mountProcessed = true
            this.prepare()
        }
    },

    methods: {

        prepare()
        {
            let message = {
                method: 'admin.stats_getAllAgentsData'
            }

            this.$core.getClient()
                .request( message )
                .then( result =>
                {

                    let data  = result.result.data,
                        stats = {}
                    this.total = data.length

                    for( let d in data )
                    {

                        let row    = data[ d ],
                            parsed = useragent.parse( row.agent )

                        if( parsed )
                        {

                            let id = parsed.family + ', ' + parsed.os.family
                            if( undefined === stats[ id ] )
                            {
                                stats[ id ] = 0
                            }
                            stats[ id ]++

                        }

                    }

                    this.statsData = stats
                    this.isready = true
                    this.$emit( 'ready' )

                } )
                .catch( () =>
                {
                } )
        }

    }

}
</script>