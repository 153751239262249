<template>
    <transition appear name="fade">
        <div id="dash-logins">
            <h2>Login-Statistiken</h2>
            <p>Nutzer-Logins in den letzten Wochen,<br/>
                <strong>{{ total }}</strong> Nutzer:innen innerhalb der letzten 14 Tage aktiv</p>
            <DisplayLoginStats id="dash-logins" :height=300 v-if="isready" :data="data" />
            <DisplayDummyBars id="dash-logins" :height=300 v-else />
        </div>
    </transition>
</template>

<script>
import DisplayLoginStats from '@/components/elements/boxes/stats/DisplayLoginStats'
import DisplayDummyBars  from "@/components/elements/boxes/stats/DisplayDummyBars";

export default {
    name      : 'DashLoginStats',
    components: { DisplayDummyBars, DisplayLoginStats },
    emits     : [ 'ready' ],

    data()
    {
        return {
            isready: false,
            data   : false,
            total  : false
        }
    },

    mounted()
    {
        let message = {
            method: 'admin.stats_getLoginStatistics'
        }

        this.$core.getClient()
            .request( message )
            .then( result =>
            {

                let res = result.result
                let data = {
                    labels: [],
                    set   : []
                }

                for( let i = 7; i >= 0; i -= 1 )
                {
                    data.labels.push( res.labels[ i ] )
                    data.set.push( res.set[ i ] )
                }

                this.data = data
                this.total = res.last14
                this.$emit( 'ready' )
                this.isready = true

            } )
            .catch( () =>
            {
            } )
    }

}
</script>