<template>
    <th :class="'sortable '+( ( undefined !== id && sortBy === id ) ? 'sorted '+sortDir : '' )+( align !== undefined ? ' '+align : '' )"
        :style="( undefined !== maxWidth ? 'max-width:'+maxWidth+'px' : '' )"
        @click="setSort()">{{ caption }}</th>
</template>

<script>
export default {
    name : 'Thd',
    props: [ 'id', 'caption', 'sortBy', 'sortDir', 'align', 'maxWidth' ],
    methods: {

        setSort()
        {
            if( undefined !== this.$props.id )
            {
                this.$emit( 'sort', { id: this.$props.id, sortDir: this.$props.sortDir } )
            }
        }

    }
}
</script>