<template>
    <transition appear name="fade">
        <div id="header">
            <div id="logo"></div>
            <div id="header-content" :key="updateKey">
                <span v-html="getGreeting()"></span> | <span class="link" @click="logout()">abmelden</span>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'StatusHeader',

    data()
    {
        return {
            updateKey: this.$core.uuidHelper.generate()
        }
    },

    computed:
    {

    },

    mounted()
    {

        this.timer = setInterval( () => {
            this.updateKey = this.$core.uuidHelper.generate()
        }, 10000 )

        let logoElm = document.querySelector( '#logo' )
        this.$core.getEventManager().add( 'ping', () =>
        {

            logoElm.classList.add( 'ping' )

        } )
        this.$core.getEventManager().add( 'clearPing', () =>
        {

            setTimeout( () => {

                logoElm.classList.remove( 'ping' )

            }, 1200 )

        } )

    },

    beforeDestroy()
    {

        clearInterval( this.timer )
        this.$core.getEventManager().remove( 'ping' )
        this.$core.getEventManager().remove( 'clearPing' )

    },

    methods: {

        getGreeting()
        {

            let time = new Date().getHours(),
                greet = 'Wat willst du denn hier, ',
                after = '?'

            if( time < 7 )
            {
                greet = 'Konntest du nicht schlafen, '
                after = '?'
            }
            else if( time < 9 )
            {
                greet = 'Guten Morgen, '
                after = '!'
            }
            else if( time < 12 )
            {
                greet = 'Moin, '
                after = '!'
            }
            else if( time < 15 )
            {
                greet = 'Mahlzeit, '
                after = '!'
            }
            else if( time < 18 )
            {
                greet = 'Hallo, '
                after = '.'
            }
            else if( time < 20 )
            {
                greet = 'Mach Feierabend, '
                after = '!'
            }
            else if( time < 22 )
            {
                greet = 'Guten Abend, '
                after = '.'
            }

            greet += '<strong>'+this.$store.getters.firstname + '</strong>'+after + ' Du bist angemeldet als '
                     + this.$core.f().translateRole( this.$store.getters.idRole ) + ''

            return greet

        },

        logout()
        {
            this.$core.getSession().destroy()
            this.$core.getRouter().push( '/' )
        }

    }
}
</script>