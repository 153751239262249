<template>
    <div class="errorMessage" @click="$emit( 'close' )">
        <h2>{{ title }}</h2>
        <p v-html="message"></p>
    </div>
</template>

<script>
export default {
    name: "ErrorMessage",
    props: {
        title: { Type: String, required: true },
        message: { Type: String, required: true }
    },
    emits: [ 'close' ]
}
</script>