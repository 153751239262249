<template>
    <div><span v-html="value"></span></div>
</template>

<script>
export default {
    name: "Separator",
    props: [ 'value' ],
    created()
    {
        console.log( this.$props.value )
    }
}
</script>