<template>
    <div>
        <transition-group appear name="next">
            <AdminUsers v-if="tab === 'admin/users'" key="templates"/>
        </transition-group>
    </div>
</template>

<script>
import AdminUsers         from '@/components/pages/tabs/admin/AdminUsers'
export default {

    name: 'Admin',
    components: { AdminUsers  },
    data()
    {
        return {
            tab  : this.$core.getState( 'navigation.activeSub' ),
            timer: null
        }
    },

    created()
    {
        this.timer = setInterval( () => {
            this.tab = this.$core.getState( 'navigation.activeSub' )
        }, 200 )
    },

    beforeDestroy()
    {
        clearInterval( this.timer )
        this.timer = null
    }

}
</script>