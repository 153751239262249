<template>
    <div v-if="ready">
        <UserInfo v-if="visibleElement !== false" :element="visibleElement" @close="closeUserInfo()"
                  @refresh="handleRefresh( true )"/>
        <Mail v-if="visibleMail !== false" :box="box" :mail="visibleMail" @refresh="handleRefresh( true )"
              @markRead="markRead" @close="closeMail()"/>
        <MailEditor v-if="showEditor"
                    @close="showEditor = false"/>
        <ElementFilter :key="key" id="helpdesk" @refresh="handleRefresh( true )" :showAdder=true @add="showEditor = true"/>
        <div class="clearfix"></div>
        <Pagination addClass="right" :total="counter" :page="page" :pages="pages" @switch="switchPage"/>
        <div class="spacer"></div>
        <table>
            <thead>
            <tr>
                <Thd id="id" caption="#" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortMails" @sort="handleSort"/>
                <Thd v-if="'inbox' === box" id="status" caption="Status" :sortBy="sortBy" :sortDir="sortDir"
                     sortRule="sortMails"
                     @sort="handleSort"/>
                <Thd id="username" caption="Benutzername" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortMails"
                     @sort="handleSort"/>
                <Thd id="count_attachments" caption="Anhänge" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortMails"
                     @sort="handleSort"/>
                <Thd v-if="'INBOX' === box" maxWidth="300" id="sender" caption="Absender" :sortBy="sortBy"
                     :sortDir="sortDir" sortRule="sortMails"
                     @sort="handleSort"/>
                <Thd v-else id="recipient" maxWidth="300" caption="Empfänger" :sortBy="sortBy" :sortDir="sortDir"
                     sortRule="sortMails"
                     @sort="handleSort"/>
                <Thd id="subject" caption="Titel" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortMails"
                     @sort="handleSort" maxWidth="600"/>
                <Thd id="tsmp" caption="Zeitstempel" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortMails"
                     align="right"
                     @sort="handleSort" maxWidth="200"/>
                <th>zugewiesen</th>
                <th>Aktionen</th>
            </tr>
            </thead>
            <tbody class="filterable">
            <tr v-for="mail in mails" :key="'mail-'+mail.id" class="clickable" @click="handleMailOpen( $event, mail )">
                <td>{{ mail.id }}</td>
                <td v-if="'inbox' === box" v-html="mail.statusHtml"></td>
                <td><strong :class="undefined !== $core.getHelpdeskHelper().userFor[ mail.id ] ? 'clickable_user' : ''"
                            @click="handleUserInfo( $event, mail.user )">{{
                        undefined !== $core.getHelpdeskHelper().userFor[ mail.id ] ? $core.getHelpdeskHelper().userFor[ mail.id ].username : '-'
                    }}</strong></td>
                <td>{{
                        mail.count_attachments > 0 ? ' &#128206; (' + mail.count_attachments + ') ' + mail.size.toFixed( 2 ) + 'kb' : ''
                    }}
                </td>
                <td style="max-width: 300px;" v-if="'INBOX' === box">{{ mail.sender }}</td>
                <td style="max-width: 300px;" v-else>{{ mail.recipient }}</td>
                <td style="width:600px;"><strong>{{ mail.subject }}</strong></td>
                <td class="right monospace"><strong>{{ mail.datetimeReceived }}</strong></td>
                <td class="left">
                    <span v-if="mail.id_assigned !== null">
                        <strong>{{ mail.assigned_name }}</strong>
                    </span>
                    <span v-else>niemandem</span>
                </td>
                <td class="right monospace">
                    <div v-if="'inbox' === box" class="button delete" @click="handleDelete( $event, mail.id )">
                        <strong>X</strong>
                    </div>
                </td>
            </tr>
            </tbody>
            <tfoot>
            <tr :id="viewId">
                <th colspan="99" style="text-align:right;" class="filtercount">...</th>
            </tr>
            </tfoot>
        </table>
    </div>
    <Spinner v-else title="Das kann dauern" message="E-Mails werden abgerufen..."/>
</template>

<script>
/* eslint-disable */
import Spinner        from '@/components/elements/Spinner'
import Thd            from '@/components/elements/Thd'
import UserInfo       from '@/components/elements/fullscreen/UserInfo'
import ElementFilter  from '@/components/elements/ElementFilter'
import Mail           from '../elements/fullscreen/Mail'
import MixinViewReady from '@/mixins/MixinViewReady'
import MailEditor     from '@/components/elements/helpdesk/MailEditor'
import Pagination     from '@/components/elements/Pagination'

export default {

    name      : 'Helpdesk',
    components: { Pagination, MailEditor, Mail, ElementFilter, UserInfo, Thd, Spinner },
    mixins    : [ MixinViewReady ],
    data()
    {
        return {
            box           : 'INBOX',
            ready         : true,
            key           : false,
            mails         : [],
            counter       : 0,
            offset        : 0,
            page          : 0,
            pages         : 0,
            users         : [],
            userFor       : {},
            sortBy        : 'id',
            sortDir       : 'descending',
            visibleElement: false,
            visibleMail   : false,
            showEditor    : false,
            timer         : false,
            maxId         : 0
        }
    },

    created()
    {

        if( null === this.$store.getters.adminCache )
        {
            this.$core.getCacher().fillAdminCache()
        }

        this.helpdeskHelper = this.$core.getHelpdeskHelper()
        this.key = this.$core.uuid().generate()

        this.$core.getEventManager().add( 'on-max-id', maxId =>
        {
            this.maxId = maxId
        } )

        this.initialize()

    },

    mounted()
    {
        this.$core.getEventManager()
            .add( 'on-set-filter', () =>
            {
                this.initialize()
            } )
        this.$core.getEventManager()
            .add( 'on-new-mails', () =>
            {
                this.initialize()
            } )

    },

    beforeDestroy()
    {
        if( this.timer )
        {
            clearInterval( this.timer )
        }
        this.$core.getEventManager()
            .remove( 'on-max-id' )
        this.$core.getEventManager()
            .remove( 'on-new-mails' )
        this.$core.getEventManager()
            .remove( 'on-set-filter' )
    },

    methods: {

        initialize()
        {
            let sub = this.$core.getState( 'navigation.activeSub' )
            this.box = undefined === sub || 'helpdesk/inbox' === sub ? 'inbox' : 'outbox'
            this.ready = false
            this.maxId = 0
            this.handleRefresh()
        },

        handleRefresh( force, keepPosition )
        {

            if( force && false === this.visibleMail )
            {
                this.ready = false
            }

            if( !keepPosition )
            {
                this.counter = 0
                this.page = 0
                this.pages = 0
                this.offset = 0
            }

            this.$nextTick()
                .then( () =>
                {

                    this.init( force )
                        .then( () =>
                        {
                            this.ready = true
                        } )

                } )

        },

        handleMailOpen( event, mail )
        {
            event.stopPropagation()
            this.visibleMail = mail
        },

        handleUserInfo( event, user )
        {
            event.stopPropagation()
            if( undefined !== user )
            {
                this.visibleElement = user
            }
        },

        closeUserInfo()
        {
            this.visibleElement = false
        },
        closeMail()
        {
            this.visibleMail = false
        },

        sort()
        {
            this.mails = JSON.parse( JSON.stringify( this.$core.getSorter().sortObjects( this.mails, this.sortBy, this.sortDir ) ) )
            this.ready = true
        },

        handleSort( args )
        {

            let newDir = args.sortDir === 'ascending' ? 'descending' : 'ascending'
            this.$store.commit( 'setSortMails', args.id )
            this.$store.commit( 'setSortMailsDir', newDir )
            this.sortBy = args.id
            this.sortDir = newDir
            this.sort()

        },

        init( force )
        {
            return new Promise( resolve =>
            {

                this.fetchMailCount()
                    .then( count =>
                    {

                        this.counter = count
                        this.pages = Math.ceil( count / 50 )

                        this.$core.getHelpdeskHelper()
                            .fetchMails( this.box, this.offset )
                            .then( list =>
                            {
                                this.mails = list
                                return resolve()
                            } )

                    } )

            } )
        },

        fetchMailCount()
        {
            return new Promise( resolve =>
            {
                return resolve( this.$core.getHelpdeskHelper()
                                    .fetchMailCount( this.box ) )
            } )

        },

        performDelete( id )
        {
            this.$core.getClient().request( {
                method: 'admin.helpdesk_deleteMessage',
                id    : id
            } ).then( () =>
            {
                clearInterval( this.timer )
                this.handleRefresh( true, true )
            } ).catch( () =>
            {
            } )
        },

        handleDelete( event, id )
        {

            event.stopPropagation()

            let setup = {
                show   : true,
                caption: 'Nachricht löschen',
                buttons: {
                    'left' : {
                        class   : 'default',
                        caption : 'nein',
                        callback: () =>
                        {
                            console.log( 'delete aborted by user input.' )
                        }
                    },
                    'right': {
                        class   : 'delete',
                        caption : 'löschen',
                        callback: () =>
                        {
                            this.performDelete( id )
                        }
                    }
                },
                message: '<strong>Bist du sicher</strong>, dass du diese Nachricht löschen möchtest?'
            }

            this.$core.getEventManager().dispatch( 'show-modal-dialog', setup )

        },

        switchPage( page )
        {

            this.page = page
            this.offset = page * 50
            this.init()
            this.key = this.$core.uuid().generate()

        },

        markRead( message )
        {
            this.$core.getHelpdeskHelper()
                .markRead( this.box, message )
        }

    }

}

</script>