<template>
    <div v-if="ready">
        <ElementFilter @refresh="handleRefresh()" id="templates" :showAdder="true" @add="add()"/>
        <Form v-if="editor"
              :formValues="formValues"
              @close="close()"
              @submit="storeTemplate"/>
        <table>
            <thead>
            <tr>
                <Thd id="identifier" caption="Template-ID" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortTemplates"
                     @sort="handleSort"/>
                <Thd id="version" caption="Version" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortTemplates"
                     @sort="handleSort"/>
                <Thd id="subject" caption="Betreff" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortTemplates"
                     @sort="handleSort"/>
                <Thd id="datetime_created" caption="Zeitstempel" :sortBy="sortBy" :sortDir="sortDir"
                     sortRule="sortTemplates"
                     @sort="handleSort"/>
                <Thd id="author" caption="Autor" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortTemplates"
                     @sort="handleSort"/>
                <th>Aktionen</th>
            </tr>
            </thead>
            <tbody class="filterable">
            <tr v-for="template in templates" :key="'row_'+template.id" class="clickable" @click="open(template)">
                <td><strong>{{ template.identifier }}</strong></td>
                <td>{{ template.version }}</td>
                <td><strong>{{ template.subject }}</strong></td>
                <td>{{ $core.getReformatter().formatTimestamp( template.datetime_created ) }}</td>
                <td><strong>{{ template.author }}</strong></td>
                <td>
                    <div class="button cancel">X</div>
                </td>
            </tr>
            </tbody>
            <tfoot>
            <tr :id="viewId">
                <th colspan="99" style="text-align:right;" class="filtercount">...</th>
            </tr>
            </tfoot>
        </table>
    </div>
    <Spinner v-else title="Gleich geht's weiter" message="Mail-Templates werden abgerufen..."/>
</template>

<script>

import ElementFilter  from '@/components/elements/ElementFilter'
import Spinner        from '@/components/elements/Spinner'
import Thd            from '@/components/elements/Thd'
import Form           from '@/components/elements/fullscreen/Form'
import MixinViewReady from '@/mixins/MixinViewReady'

export default {
    name      : 'ToolsTemplates',
    components: { Form, Thd, Spinner, ElementFilter },
    mixins    : [ MixinViewReady ],
    
    data()
    {
        return {
            ready     : false,
            editor    : false,
            formValues: {},
            sortBy    : 'identifier',
            sortDir   : 'ascending',
            templates : []
        }
    },
    
    created()
    {
        this.init()
    },
    
    methods: {
        
        init()
        {
            
            this.templates = []
            this.$core.getClient()
                .request( {
                    method: 'admin.tools_listTemplates'
                } )
                .then( response =>
                {
                    let list = response.result.list
                    for( let l in list )
                    {
                        this.templates.push( list[ l ][ 0 ] )
                    }
                    this.sort()
                } )
            
        },
        
        open( template )
        {
            this.formValues = {
                title : 'Template <strong>' + template.identifier + '</strong> bearbeiten',
                fields: [
                    {
                        caption: 'Betreff',
                        type   : 'TextBox',
                        id     : 'subject',
                        value  : template.subject
                    },
                    {
                        caption: 'Textkörper',
                        type   : 'TextArea',
                        id     : 'text',
                        value  : template.text
                    },
                    {
                        type : 'hidden',
                        id   : 'identifier',
                        value: template.identifier
                    },
                    {
                        type : 'hidden',
                        id   : 'id_author',
                        value: this.$store.getters.idUser
                    },
                    {
                        type : 'hidden',
                        id   : 'mode',
                        value: 'edit'
                    }
                ]
            }
            
            this.editor = true
            
        },
        
        add()
        {
            
            this.formValues = {
                title : '<strong>Neues Template</strong> anlegen',
                fields: [
                    {
                        caption: 'Template-ID',
                        type   : 'TextBox',
                        id     : 'identifier',
                        value  : ''
                    },
                    {
                        caption: 'Betreff',
                        type   : 'TextBox',
                        id     : 'subject',
                        value  : ''
                    },
                    {
                        caption: 'Textkörper',
                        type   : 'TextArea',
                        id     : 'text',
                        value  : ''
                    },
                    {
                        type : 'hidden',
                        id   : 'id_author',
                        value: this.$store.getters.idUser
                    },
                    {
                        type : 'hidden',
                        id   : 'mode',
                        value: 'add'
                    }
                ]
            }
            
            this.editor = true
            
        },
        
        storeTemplate( values )
        {
            
            this.$core.getEventManager().dispatch( 'showBlocker' )
            setTimeout( () =>
            {
                
                this.$core.getClient()
                    .request( {
                        method: 'admin.tools_storeTemplate',
                        values: values
                    } )
                    .then( () =>
                    {
                        this.close()
                        this.init()
                        this.$core.getEventManager().dispatch( 'hideBlocker' )
                    } )
                    .catch( () =>
                    {
                        this.$core.getEventManager().dispatch( 'hideBlocker' )
                    } )
                
            } )
            
        },
        
        close()
        {
            this.formValues = {}
            this.editor = false
        },
        
        sort()
        {
            this.templates = this.$core.getSorter().sortObjects( this.templates, this.sortBy, this.sortDir )
            this.ready = true
        },
        
        handleRefresh()
        {
            this.init()
        },
        
        handleSort( args )
        {
            
            let newDir = args.sortDir === 'ascending' ? 'descending' : 'ascending'
            this.$store.commit( 'setSortTemplates', args.id )
            this.$store.commit( 'setSortTemplatesDir', newDir )
            this.sortBy = args.id
            this.sortDir = newDir
            this.sort()
            
        }
        
        
    }
    
}
</script>