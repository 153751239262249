<template>
    <div v-if="ready">
        <TestAutomailerRecipients v-if="false !== testRecipients"
                                  :element="testRecipients"
                                  @close="testRecipients = false"/>
        <AutomailerRecipients v-if="false !== showRecipients"
                              :element="showRecipients"
                              @close="showRecipients = false"/>

        <Form v-if="false !== mailerSetup"
              :formValues="formValues"
              @close="mailerSetup = false"
              @submit="handleSubmit"/>

        <UserInfo v-if="visibleElement !== false" :element="visibleElement" @close="closeUserInfo()"
                  @refresh="handleRefresh( true )"/>

        <ElementFilter @refresh="handleRefresh()" id="automails" :showAdder="true" @add="mailerSetup = true"/>
        <table>
            <thead>
            <tr>
                <Thd id="id" caption="#" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortAutomails"
                     @sort="handleSort"/>
                <Thd id="identifier" caption="Identifier" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortAutomails"
                     @sort="handleSort"/>
                <Thd id="priority" caption="Priorität" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortAutomails"
                     @sort="handleSort"/>
                <Thd id="count_sent" caption="# Empfänger" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortAutomails"
                     @sort="handleSort"/>
                <Thd id="datetime_formatted_last_send" caption="letzter Versand" :sortBy="sortBy" :sortDir="sortDir"
                     sortRule="sortAutomails"
                     @sort="handleSort"/>
                <Thd id="active" caption="aktiv" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortAutomails"
                     @sort="handleSort"/>
                <Thd id="options" caption="Optionen"/>
            </tr>
            </thead>
            <tbody class="filterable">
            <template v-for="mail in automails" :key="'automail_'+mail.id">
                <tr class="clickable" @click="mailerSetup = mail">
                    <td>{{ mail.id }}</td>
                    <td><strong>{{ mail.identifier }}</strong></td>
                    <td>{{ mail.priority }}</td>
                    <td>{{ mail.count_sent }}</td>
                    <td><strong>{{ mail.datetime_formatted_last_send }}</strong></td>
                    <td><strong :class="mail.active === 1 ? 'good' : 'error'">{{
                            mail.active === 1 ? 'aktiv' : 'inaktiv'
                        }}</strong></td>
                    <td>
                        <div class="button info" @click="$core.f().skip( $event ); testRecipients=mail">T</div>
                        <div class="button info" @click="$core.f().skip( $event ); showRecipients=mail">i</div>
                        <div v-if="0 === mail.active" class="button reactivate" @click="handleActivate( item.id )">A
                        </div>
                        <div v-if="1 === mail.active" class="button cancel" @click="handlDeactivate( item.id )">D</div>
                        <div class="button cancel" @click="handleDelete( item.id )">X</div>
                    </td>
                </tr>
            </template>
            </tbody>
            <tfoot>
            <tr :id="viewId">
                <th colspan="99" style="text-align:right;" class="filtercount">...</th>
            </tr>
            </tfoot>
        </table>
    </div>
    <Spinner v-else title="Gleich geht's weiter" message="Automatische E-Mails werden abgerufen..."/>
</template>

<script>

import ElementFilter            from '@/components/elements/ElementFilter'
import Spinner                  from '@/components/elements/Spinner'
import Thd                      from '@/components/elements/Thd'
import UserInfo                 from '../../../elements/fullscreen/UserInfo'
import MixinViewReady           from '@/mixins/MixinViewReady'
import AutomailerRecipients     from "@/components/elements/fullscreen/AutomailerRecipients";
import Form                     from "@/components/elements/fullscreen/Form";
import TestAutomailerRecipients from "@/components/elements/fullscreen/TestAutomailerRecipients";

export default {
    name      : 'ToolsAutomails',
    components: { TestAutomailerRecipients, Form, AutomailerRecipients, UserInfo, Thd, Spinner, ElementFilter },
    mixins    : [ MixinViewReady ],

    data()
    {
        return {
            ready         : false,
            editor        : false,
            sortBy        : 'identifier',
            sortDir       : 'ascending',
            automails     : [],
            users         : [],
            licenses      : [],
            userFor       : {},
            visibleElement: false,
            showRecipients: false,
            testRecipients: false,
            mailerSetup   : false,
            openRows      : []
        }
    },

    created()
    {
        this.init()
    },

    computed: {
        formValues()
        {

            return {
                title         : this.mailerSetup !== true
                                ? 'Regel "<strong>' + this.mailerSetup.identifier + '</strong>" bearbeiten'
                                : 'Neue Regel anlegen',
                subjectCaption: 'speichern',
                fields        : [
                    {
                        caption: 'Identifier',
                        type   : 'TextBox',
                        id     : 'identifier',
                        value  : this.mailerSetup !== true ? this.mailerSetup.identifier : ''
                    },
                    {
                        caption: 'Vorlage',
                        type   : 'NewsletterTemplate',
                        id     : 'id_mailtemplate',
                        value  : this.mailerSetup !== true ? this.mailerSetup.id_mailtemplate : undefined
                    },
                    {
                        caption: '',
                        type   : 'Separator',
                        value  : '<strong>statistische</strong> Schwellenwerte'
                    },
                    {
                        caption: 'min. Anzahl Elemente',
                        type   : 'NumberBox',
                        id     : 'min_elements',
                        value  : this.mailerSetup !== true
                                 ? ( -1 !== this.mailerSetup.min_elements ? this.mailerSetup.min_elements : undefined )
                                 : undefined
                    },
                    {
                        caption: 'max. Anzahl Elemente',
                        type   : 'NumberBox',
                        id     : 'count_elements',
                        value  : this.mailerSetup !== true
                                 ? ( -1 !== this.mailerSetup.count_elements ? this.mailerSetup.count_elements : undefined )
                                 : undefined
                    },
                    {
                        caption: 'max. Anzahl geklonter Elemente',
                        type   : 'NumberBox',
                        id     : 'count_statistics_cloned',
                        value  : this.mailerSetup !== true
                                 ? ( -1 !== this.mailerSetup.count_statistics_cloned ? this.mailerSetup.count_statistics_cloned : undefined )
                                 : undefined
                    },
                    {
                        caption: 'max. Anzahl Logins',
                        type   : 'NumberBox',
                        id     : 'count_statistics_login',
                        value  : this.mailerSetup !== true
                                 ? ( -1 !== this.mailerSetup.count_statistics_login ? this.mailerSetup.count_statistics_login : undefined )
                                 : undefined
                    },
                    {
                        caption: '',
                        type   : 'Separator',
                        value  : '<strong>zeitliche</strong> Schwellenwerte'
                    },
                    {
                        caption: 'Tage seit Registrierung',
                        type   : 'NumberBox',
                        id     : 'days_register',
                        value  : this.mailerSetup !== true
                                 ? ( -1 !== this.mailerSetup.days_register ? this.mailerSetup.days_register : undefined )
                                 : undefined
                    },
                    {
                        caption: 'Tage seit letztem Login',
                        type   : 'NumberBox',
                        id     : 'days_last_login',
                        value  : this.mailerSetup !== true
                                 ? ( -1 !== this.mailerSetup.days_last_login ? this.mailerSetup.days_last_login : undefined )
                                 : undefined
                    },
                    {
                        caption: '',
                        type   : 'Separator',
                        value  : '<strong>Flags</strong>'
                    },
                    {
                        caption: 'benötigte Lizenz',
                        type   : 'LicenseSelector',
                        id     : 'id_license',
                        value  : this.mailerSetup !== true
                                 ? ( -1 !== this.mailerSetup.id_license ? this.mailerSetup.id_license : undefined )
                                 : undefined
                    },
                    {
                        caption: 'aktive Lizenz ignorieren',
                        type   : 'CheckBox',
                        id     : 'ignore_active_license',
                        value  : this.mailerSetup !== true
                                 ? ( -1 !== this.mailerSetup.ignore_active_license ? this.mailerSetup.ignore_active_license : undefined )
                                 : undefined
                    },
                    {
                        caption: 'Mailhistorie ignorieren',
                        type   : 'CheckBox',
                        id     : 'ignore_mailhistory',
                        value  : this.mailerSetup !== true
                                 ? ( -1 !== this.mailerSetup.ignore_mailhistory ? this.mailerSetup.ignore_mailhistory : undefined )
                                 : undefined
                    },
                    {
                        caption: 'auch Poolaccounts kontaktieren',
                        type   : 'CheckBox',
                        id     : 'ignore_pool_accounts',
                        value  : this.mailerSetup !== true
                                 ? ( -1 !== this.mailerSetup.ignore_pool_accounts ? this.mailerSetup.ignore_pool_accounts : undefined )
                                 : undefined
                    },
                    {
                        caption: 'Regel aktivieren',
                        type   : 'CheckBox',
                        id     : 'active',
                        value  : this.mailerSetup !== true
                                 ? ( -1 !== this.mailerSetup.active ? this.mailerSetup.active : undefined )
                                 : undefined
                    }
                ]

            }
        }
    },

    methods: {

        init( force )
        {

            this.$core.getCacher()
                .fillUsersCache( force )
                .then( userlist =>
                {

                    this.$core.getCacher()
                        .fillLicensesCache()
                        .then( licenseList =>
                        {

                            this.licenses = licenseList
                            this.automails = []
                            this.$core.getClient()
                                .request( {
                                    method: 'admin.tools_listAutomails'
                                } )
                                .then( response =>
                                {
                                    this.automails = response.result
                                    this.sort()
                                } )

                        } )

                } )

        },

        sort()
        {
            this.automails = this.$core.getSorter().sortObjects( this.automails, this.sortBy, this.sortDir )
            this.ready = true
        },

        handleRefresh()
        {
            this.init()
        },

        handleSort( args )
        {

            let newDir = args.sortDir === 'ascending' ? 'descending' : 'ascending'
            this.sortBy = args.id
            this.sortDir = newDir
            this.sort()

        },

        handleUserInfo( event, user )
        {
            event.stopPropagation()
            if( undefined !== user )
            {
                this.visibleElement = user
            }
        },

        handleSubmit( values )
        {

            let message = {
                    method    : 'admin.tools_storeAutoMail',
                    parameters: {}
                },
                trans   = {
                    active                 : 'bool',
                    count_elements         : 'num_or_minus_1',
                    count_statistics_cloned: 'num_or_minus_1',
                    count_statistics_login : 'num_or_minus_1',
                    days_last_login        : 'num_or_minus_1',
                    days_register          : 'num_or_minus_1',
                    id_license             : 'num_or_minus_1',
                    id_mailtemplate        : 'num',
                    identifier             : 'string',
                    ignore_active_license  : 'bool_minus_1',
                    ignore_pool_accounts   : 'bool_minus_1',
                    ignore_mail_history    : 'bool',
                    min_elements           : 'num_or_minus_1'
                },
                errors  = []

            for( let t in trans )
            {
                switch( trans[ t ] )
                {
                    case 'bool':
                        if( values[ t ] === false )
                        {
                            message.parameters[ t ] = 0
                        }
                        if( values[ t ] === true )
                        {
                            message.parameters[ t ] = 1
                        }
                        break
                    case 'bool_minus_1':
                        if( values[ t ] === false )
                        {
                            message.parameters[ t ] = -1
                        }
                        if( values[ t ] === true )
                        {
                            message.parameters[ t ] = 1
                        }
                        break
                    case 'num':
                        if( 0 === parseInt( values[ t ] )
                            || isNaN( values[ t ] ) )
                        {
                            errors.push( t + ' not set' )
                        }
                        else
                        {
                            message.parameters[ t ] = parseInt( values[ t ] )
                        }
                        break
                    case 'num_or_minus_1':
                        if( values[ t ].trim() === '' )
                        {
                            message.parameters[ t ] = -1
                        }
                        if( 0 <= parseInt( values[ t ] ) )
                        {
                            message.parameters[ t ] = parseInt( values[ t ] )
                        }
                        break
                    case 'string':
                        if( values[ t ].trim() === '' )
                        {
                            errors.push( t + ' not set' )
                        }
                        else
                        {
                            message.parameters[ t ] = values[ t ].trim()
                        }
                        break
                }
            }

            if( this.mailerSetup !== true )
            {
                message.parameters.id = this.mailerSetup.id
            }
            else
            {
                message.parameters.id = -1
            }

        },

        closeUserInfo()
        {
            this.visibleElement = false
        },

        toggleOpenRow( messageId )
        {
            let index = this.openRows.indexOf( messageId )
            if( -1 < index )
            {
                this.openRows.splice( index, 1 )
            }
            else
            {
                this.openRows.push( messageId )
            }
        }


    }

}
</script>