<template>
    <transition-group appear name="slide-left">
        <MailEditor v-if="replyVisible"
                    :mail="mail"
                    key="editor"
                    :headers="headers"
                    @close="handleClose"/>
        <div class="fullscreen" key="fullscreen">
            <div class="content">
                <div class="title">
                    <div class="headline" v-html="'<strong>'+mail.sender+'</strong> &bull; '+mail.subject"></div>
                    <div class="closer" @click="$emit( 'close' )">
                        X
                    </div>
                </div>
                <SuccessMessage v-if="false !== message"
                                :title="message" @click="message = false"/>
                <div class="info noscroll">
                    <template v-if="prepared">
                        <div class="mail-body">
                            <div class="mail-headers">
                                <table class="mail-headers">
                                    <tr>
                                        <td>Betreff</td>
                                        <td>{{ mail.subject }}</td>
                                    </tr>
                                    <tr>
                                        <td>von</td>
                                        <td>{{ mail.sender }}</td>
                                    </tr>
                                    <tr>
                                        <td>an</td>
                                        <td>{{ mail.recipient }}</td>
                                    </tr>
                                    <tr>
                                        <td>Zeitstempel</td>
                                        <td>{{ mail.datetimeReceived }}</td>
                                    </tr>
                                    <tr>
                                        <td>Header</td>
                                        <td>
                                            <div class="button nonfixed" @click="headerVisible = !headerVisible">
                                                {{ headerVisible ? 'aus' : 'ein' }}blenden
                                            </div>
                                            <table class="inner" v-if="headerVisible">
                                                <tr v-for="( key, value ) in headers" :key="'tr-'+key">
                                                    <td>{{ value }}</td>
                                                    <td>{{ key }}</td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                                <div class="large-button" title="antworten" @click="replyVisible = true">&#8617;</div>
                            </div>
                            <div v-html="body"></div>
                        </div>
                        <div class="mail-sidebar">
                            <div class="infobox">
                                <template v-if="'inbox' === box">
                                    <h3>Absender-Informationen</h3>
                                    <UserSidebar :mailSender="mail.sender"/>
                                </template>
                                <template v-if="'outbox' === box">
                                    <h3>Empfänger-Informationen</h3>
                                    <UserSidebar :mailSender="mail.recipient"/>
                                </template>
                            </div>
                            <div class="infobox">
                                <HelpdeskAssignment :mail="mail" @refresh="$emit( 'refresh' )"/>
                            </div>
                            <div class="infobox">
                                <h3>Gelesen von</h3>
                                <br/>
                                <div v-for="r in mail.readerInfo"
                                     :key="'reader-'+r">
                                    <strong>{{ adminName( r.reader ) }}</strong>, {{ 'NOW' === r.tsmp ? 'jetzt' : r.tsmp }}
                                </div>
                            </div>
                            <div class="infobox">
                                <h3>{{ attachments.length }} Anhänge</h3>
                                <Attachment v-for="attachment in attachments"
                                            :key="'attach-'+attachment.id_attachment"
                                            :attachment="attachment"/>
                            </div>
                        </div>
                    </template>
                    <Spinner v-else title="Augenblick..." message="E-Mail wird geöffnet"/>
                </div>
            </div>
        </div>
    </transition-group>
</template>

<script>
/*eslint-disable*/
import ElementFilter      from '@/components/elements/ElementFilter'
import Thd                from '@/components/elements/Thd'
import Spinner            from '../Spinner'
import Attachment         from '../helpdesk/Attachment'
import UserSidebar        from '../helpdesk/UserSidebar'
import MailEditor         from '../helpdesk/MailEditor'
import SuccessMessage     from '@/components/elements/SuccessMessage'
import HelpdeskAssignment from "@/components/elements/helpdesk/HelpdeskAssignment";

export default {

    name      : 'Mail',
    components: { HelpdeskAssignment, SuccessMessage, MailEditor, UserSidebar, Attachment, Spinner, Thd, ElementFilter },
    props     : [ 'mail', 'box' ],
    emits     : [ 'refresh', 'close', 'markRead' ],

    data()
    {
        return {
            headers      : {},
            attachments  : [],
            sender       : '',
            body         : '',
            html         : false,
            prepared     : false,
            eventListener: false,
            headerVisible: false,
            replyVisible : false,
            message      : false
        }
    },

    created()
    {

        this.fetchAttachments()
            .then( () =>
            {

                this.init()

            } )

    },

    methods: {

        adminName( id )
        {
            return this.$store.getters.adminCache[id] ? this.$store.getters.adminCache[id].first_name : 'n/a'
        },

        fetchAttachments()
        {
            return new Promise( resolve =>
            {

                this.attachments = []

                let message = {
                    method: 'admin.helpdesk_listAttachments',
                    id    : this.$props.mail.internalId
                }

                this.$core.getClient()
                    .request( message )
                    .then( response =>
                    {

                        let attach = response.result
                        for( let a in attach )
                        {

                            this.attachments.push( attach[ a ] )

                        }

                        return resolve()

                    } )
                    .catch( () =>
                    {
                        return resolve()
                    } )

            } )
        },

        markRead()
        {

            if( 'inbox' === this.$props.box )
            {

                let message = {
                    method: 'admin.helpdesk_markRead',
                    id    : this.$props.mail.internalId,
                    user  : this.$store.getters.idUser
                }

                this.$core.getClient()
                    .request( message )

                this.$emit( 'markRead', message )

            }

        },

        init()
        {

            this.$core.getCacher()
                .awaitAdminCache()
                .then( () => {

                    this.markRead()

                    let headModified = -1 < document.head.innerHTML.indexOf( 'base target' )
                    if( !headModified )
                    {
                        let e = document.createElement( 'base' )
                        e.target = '_blank'
                        document.head.appendChild( e )
                    }

                    this.headers = JSON.parse( atob( this.$props.mail.headers ) )
                    let regex = /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/g
                    let matches = regex.exec( '' + this.$props.mail.sender )

                    let body = this.$props.mail.body_html

                    this.body = ''

                    if( undefined !== body
                        && body.trim() !== '' )
                    {
                        this.body += decodeURIComponent( escape( atob( body ) ) )
                        this.html = true
                    }
                    else
                    {
                        this.body += this.nl2br( decodeURIComponent( escape( atob( this.$props.mail.body ) ) ) )
                    }

                    let dom = new DOMParser().parseFromString( this.body, 'text/html' )
                    this.body = dom.querySelector( 'body' ).innerHTML

                    this.prepared = true

                    let test = ''
                    if( null !== matches )
                    {

                        test = matches[ 0 ]
                        let sender = ( '' + this.$props.mail.sender ).replace( '<' + test + '>', '' ).trim()
                        if( sender.length > 0 )
                        {
                            this.sender = sender
                            return
                        }
                    }

                    this.sender = '' + this.$props.mail.sender

                })

        },

        nl2br( text )
        {
            text = text.replace( /\r\n/g, '<br/>', text )
            text = text.replace( /\n/g, '<br/>', text )
            return text
        },

        handleClose()
        {
            this.replyVisible = false
            this.$emit( 'close' )
        }

    }

}
</script>