<template>
    <div>
        <select class="select-css inset" :id="id">
            <option value="">bitte wählen...</option>
            <option v-for="item in list" :key="'opt-'+item.id" :value="item.id" :selected="item.id === value">
                {{ item.name }}
            </option>
        </select>
    </div>
</template>

<script>
export default {
    
    name : 'PeriodSelector',
    props: [ 'value', 'id' ],
    
    data()
    {
        return {
            ready   : false,
            list    : []
        }
    },
    
    created()
    {
    },
    
    mounted()
    {
        this.listAll()
    },
    
    beforeDestroy()
    {
        this.ready = false
        this.list = []
    },
    
    methods: {
        
        listAll()
        {
            let prolongOptions = [ '3D', '7D', '14D', '1M', '2M', '3M', '6M', '12M' ]
            for( let p in prolongOptions )
            {
                this.list.push( {
                    id  : prolongOptions[ p ],
                    name: this.$core.t().translatePeriod( prolongOptions[ p ] )
                } )
            }
        }
        
    }
    
}
</script>