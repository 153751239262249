<template>
    <div v-if="ready">
        <UserInfo v-if="visibleElement !== false" :element="visibleElement" @close="closeUserInfo()"
                  @refresh="handleRefresh( true )"/>
        
        <ElementFilter @refresh="handleRefresh()" id="bounces" :showAdder="false"/>
        <table>
            <thead>
            <tr>
                <Thd id="message_id" caption="#" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortBounces"
                     @sort="handleSort"/>
                <Thd id="username" caption="Benutzername" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortBounces"
                     @sort="handleSort"/>
                <Thd id="email" caption="E-Mail-Adresse" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortBounces"
                     @sort="handleSort"/>
                <Thd id="bounce_type" caption="Bounce-Typ" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortBounces"
                     @sort="handleSort"/>
                <Thd id="rule_cat" caption="Regelkategorie" :sortBy="sortBy" :sortDir="sortDir"
                     sortRule="sortBounces"
                     @sort="handleSort"/>
                <Thd id="rule_no" caption="Regel-Nr." :sortBy="sortBy" :sortDir="sortDir"
                     sortRule="sortBounces"
                     @sort="handleSort"/>
                <Thd id="status_code" caption="Status-Code" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortBounces"
                     @sort="handleSort"/>
                <Thd id="datetime_parsed" caption="Zeitstempel" :sortBy="sortBy" :sortDir="sortDir"
                     sortRule="sortBounces"
                     @sort="handleSort"/>
            </tr>
            </thead>
            <tbody class="filterable">
            <template v-for="bounce in bounces" :key="'row_'+bounce.message_id">
                <tr class="clickable" @click="toggleOpenRow( bounce.message_id )">
                    <td>{{ bounce.message_id }}</td>
                    <td><strong :class="undefined !== userFor[ bounce.message_id ] ? 'clickable_user' : ''"
                                @click="handleUserInfo( $event, bounce.user )">{{
                            undefined !== userFor[ bounce.message_id ] ? userFor[ bounce.message_id ].username : '-'
                        }}</strong></td>
                    <td><strong>{{ bounce.email }}</strong></td>
                    <td><strong :style="'color:'+bounce.bounce_color+';'">{{ bounce.bounce_type }}</strong></td>
                    <td><strong>{{ bounce.rule_cat }}</strong></td>
                    <td>{{ bounce.rule_no }}</td>
                    <td><strong>{{ bounce.status_code }}</strong></td>
                    <td><strong>{{ bounce.datetime_parsed_formatted }}</strong></td>
                </tr>
                <tr v-if="-1 < openRows.indexOf( bounce.message_id )" class="clickable" :key="'tgl_'+bounce.message_id"
                    @click="toggleOpenRow( bounce.message_id )">
                    <td>&nbsp;</td>
                    <td colspan="7">
                        <pre>{{ bounce.diagnostic_code }}</pre>
                    </td>
                </tr>
            </template>
            </tbody>
            <tfoot>
            <tr :id="viewId">
                <th colspan="99" style="text-align:right;" class="filtercount">...</th>
            </tr>
            </tfoot>
        </table>
    </div>
    <Spinner v-else title="Gleich geht's weiter" message="E-Mail-Bounces werden abgerufen..."/>
</template>

<script>

import ElementFilter  from '@/components/elements/ElementFilter'
import Spinner        from '@/components/elements/Spinner'
import Thd            from '@/components/elements/Thd'
import UserInfo       from '../../../elements/fullscreen/UserInfo'
import MixinViewReady from '@/mixins/MixinViewReady'

export default {
    name      : 'ToolsBounces',
    components: { UserInfo, Thd, Spinner, ElementFilter },
    mixins    : [ MixinViewReady ],
    
    data()
    {
        return {
            ready         : false,
            editor        : false,
            formValues    : {},
            sortBy        : 'identifier',
            sortDir       : 'ascending',
            bounces       : [],
            users         : [],
            userFor       : {},
            visibleElement: false,
            openRows      : []
        }
    },
    
    created()
    {
        this.init()
    },
    
    methods: {
        
        init( force )
        {
            
            this.$core.getCacher()
                .fillUsersCache( force )
                .then( userlist =>
                {
                    
                    this.users = userlist
                    this.bounces = []
                    this.$core.getClient()
                        .request( {
                            method: 'admin.tools_listBounces'
                        } )
                        .then( response =>
                        {
                            
                            let list = response.result
                            for( let l in list )
                            {
                                
                                list[ l ].username = '- unbekannt'
                                let test = list[ l ].email
                                
                                for( let u in this.users )
                                {
                                    if( null !== test && null !== this.users[ u ].email )
                                    {
                                        if( this.users[ u ].email.toLowerCase().trim() === test.toLowerCase().trim() )
                                        {
                                            list[ l ].username = this.users[ u ].username
                                            this.userFor[ list[ l ].message_id ] = this.users[ u ]
                                            list[ l ].user = this.users[ u ]
                                        }
                                    }
                                }
                                
                                list[ l ].bounce_color = '#cecece'
                                switch( list[ l ].bounce_type )
                                {
                                    case 'hard':
                                        list[ l ].bounce_color = '#990000'
                                        break
                                    case 'soft':
                                        list[ l ].bounce_color = '#009900'
                                        break
                                }
                                this.bounces.push( list[ l ] )
                            }
                            this.sort()
                        } )
                    
                } )
            
        },
        
        sort()
        {
            this.bounces = this.$core.getSorter().sortObjects( this.bounces, this.sortBy, this.sortDir )
            this.ready = true
        },
        
        handleRefresh()
        {
            this.init()
        },
        
        handleSort( args )
        {
            
            let newDir = args.sortDir === 'ascending' ? 'descending' : 'ascending'
            this.$store.commit( 'setSortBounces', args.id )
            this.$store.commit( 'setSortBouncesDir', newDir )
            this.sortBy = args.id
            this.sortDir = newDir
            this.sort()
            
        },
        
        handleUserInfo( event, user )
        {
            event.stopPropagation()
            if( undefined !== user )
            {
                this.visibleElement = user
            }
        },
        
        closeUserInfo()
        {
            this.visibleElement = false
        },
        
        toggleOpenRow( messageId )
        {
            let index = this.openRows.indexOf( messageId )
            if( -1 < index )
            {
                this.openRows.splice( index, 1 )
            }
            else
            {
                this.openRows.push( messageId )
            }
        }
        
        
    }
    
}
</script>