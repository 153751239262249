<template>
    <div>
        <Blocker/>
        <ModalDialog/>
        <StatusHeader v-if="$store.getters.authorized === 1"/>
        <Navigation v-if="$store.getters.authorized === 1"/>
        <MessageSpace v-if="$store.getters.authorized === 1"/>
        <router-view v-slot="{ Component }"
                     :class="'router-main'+( subnav ? ' subnav-visible' : '' )"
                     id="base">
            <transition appear name="slide" mode="out-in">
                <component :is="Component"/>
            </transition>
        </router-view>
        <Footer/>
    </div>
</template>

<script>
import StatusHeader from '@/components/elements/StatusHeader'
import Navigation   from '@/components/elements/Navigation'
import Footer       from '@/components/elements/Footer'
import ModalDialog  from '@/components/elements/ModalDialog'
import Blocker      from '@/components/elements/Blocker'
import MessageSpace from '@/components/elements/MessageSpace'

export default {
    name      : 'App',
    components: { MessageSpace, Blocker, ModalDialog, Footer, Navigation, StatusHeader },

    watch: {
        '$store.getters.authorized': {
            immediate: true,
            handler( newValue, oldValue )
            {
                if( oldValue !== undefined
                    && newValue !== oldValue )
                {
                    switch( newValue )
                    {
                        case null:
                        case 0:
                            this.sessionPing()
                            break
                        default:
                            break

                    }
                }
            }
        }
    },

    data()
    {
        return {
            subnav: false
        }
    },

    created()
    {
        this.$core.getEventManager().add( 'on-subnav', state =>
        {
            this.subnav = state
        } )
    },

    beforeDestroy()
    {
        this.$core.getEventManager().remove( 'on-subnav' )
    },

    methods: {
        sessionPing()
        {
            this.$core.getSession()
                .ping()
        }
    }
}
</script>

<style lang="less">
@import './less/ui.less';
</style>