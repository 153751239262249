<template>
    <div>
        <h2>Server-Status</h2>
        <div class="stats box">
            <h3 :class="( act.dev.connections !== undefined ? 'good' : 'error' )">DEV</h3>
            <table class="margin-top infotable" v-if="undefined !== act.dev.connections">
                <tbody>
                <tr>
                    <td class="center"><strong>Server-Version</strong></td>
                    <td class="center" v-html="act.dev.version"></td>
                </tr>
                <tr>
                    <td class="center"><strong>Uptime</strong></td>
                    <td class="center" v-html="uptime( act.dev.uptime )"></td>
                </tr>
                <tr>
                    <td class="center"><strong>Speichernutzung</strong></td>
                    <td class="center" v-html="memory( act.dev.memory )"></td>
                </tr>
                <tr>
                    <td class="center"><strong>CPU-Auslastung</strong></td>
                    <td class="center">{{ act.dev.cpu }}%</td>
                </tr>
                <tr>
                    <td class="center"><strong>Verbindungen</strong></td>
                    <td class="center">{{ act.dev.connections }}</td>
                </tr>
                <tr>
                    <td class="center">aktive <strong>Sessions</strong></td>
                    <td class="center">{{ act.dev.sessions }}</td>
                </tr>
                <tr>
                    <td class="center"><strong>Requests</strong></td>
                    <td class="center">{{ act.dev.requests }}</td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="stats box">
            <h3 :class="( act.beta.connections !== undefined ? 'good' : 'error' )">BETA</h3>
            <table class="margin-top infotable" v-if="undefined !== act.beta.connections">
                <tbody>
                <tr>
                    <td class="center"><strong>Server-Version</strong></td>
                    <td class="center" v-html="act.beta.version"></td>
                </tr>
                <tr>
                    <td class="center"><strong>Uptime</strong></td>
                    <td class="center" v-html="uptime( act.beta.uptime )"></td>
                </tr>
                <tr>
                    <td class="center"><strong>Speichernutzung</strong></td>
                    <td class="center" v-html="memory( act.beta.memory )"></td>
                </tr>
                <tr>
                    <td class="center"><strong>CPU-Auslastung</strong></td>
                    <td class="center">{{ act.beta.cpu }}%</td>
                </tr>
                <tr>
                    <td class="center"><strong>Verbindungen</strong></td>
                    <td class="center">{{ act.beta.connections }}</td>
                </tr>
                <tr>
                    <td class="center">aktive <strong>Sessions</strong></td>
                    <td class="center">{{ act.beta.sessions }}</td>
                </tr>
                <tr>
                    <td class="center"><strong>Requests</strong></td>
                    <td class="center">{{ act.beta.requests }}</td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="stats box">
            <h3 :class="( act.live.connections !== undefined ? 'good' : 'error' )">LIVE</h3>
            <table class="margin-top infotable" v-if="undefined !== act.live.connections">
                <tbody>
                <tr>
                    <td class="center"><strong>Server-Version</strong></td>
                    <td class="center" v-html="act.live.version"></td>
                </tr>
                <tr>
                    <td class="center"><strong>Uptime</strong></td>
                    <td class="center" v-html="uptime( act.live.uptime )"></td>
                </tr>
                <tr>
                    <td class="center"><strong>Speichernutzung</strong></td>
                    <td class="center" v-html="memory( act.live.memory )"></td>
                </tr>
                <tr>
                    <td class="center"><strong>CPU-Auslastung</strong></td>
                    <td class="center">{{ act.live.cpu }}%</td>
                </tr>
                <tr>
                    <td class="center"><strong>Verbindungen</strong></td>
                    <td class="center">{{ act.live.connections }}</td>
                </tr>
                <tr>
                    <td class="center">aktive <strong>Sessions</strong></td>
                    <td class="center">{{ act.live.sessions }}</td>
                </tr>
                <tr>
                    <td class="center"><strong>Requests</strong></td>
                    <td class="center">{{ act.live.requests }}</td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="clearfix"></div>
        <div class="stats box" v-if="initialized">
            <h3 :class="( stats.dev !== undefined ? 'good' : 'error' )">Statistiken für den Test-Websocket</h3>
            <DisplayMonitoringStats :data="stats.dev" />
        </div>
        <div class="stats box" v-if="initialized">
            <h3 :class="( stats.dev !== undefined ? 'good' : 'error' )">Statistiken für den Beta-Websocket</h3>
            <DisplayMonitoringStats :data="stats.beta" />
        </div>
        <div class="stats box" v-if="initialized">
            <h3 :class="( stats.live !== undefined ? 'good' : 'error' )">Statistiken für den Live-Websocket</h3>
            <DisplayMonitoringStats :data="stats.live" />
        </div>

    </div>
</template>

<script>

import DisplayMonitoringStats from '@/components/elements/boxes/stats/DisplayMonitoringStats'
export default {

    name: 'MonitoringLegacy',
    components: { DisplayMonitoringStats },
    data()
    {
        return {
            stats: {
                dev : [],
                beta: [],
                live: []
            },
            act  : {
                dev : {},
                beta: {},
                live: {}
            },
            last : {
                dev : -1,
                beta: -1,
                live: -1
            },
            initialized: false
        }
    },

    mounted()
    {

        this.refresh()

        this.timer = setInterval( () =>
        {

            this.refresh()

        }, 30000 )

    },

    beforeDestroy()
    {
        clearInterval( this.timer )
        this.timer = null
    },

    methods: {

        refresh()
        {

            let message = {
                method: 'admin.stats_getMonitoringData'
            }

            this.$core.getClient()
                .request( message )
                .then( result =>
                {

                    for( let a in result.result.actual )
                    {
                        this.act[ a ] = result.result.actual[a]
                    }

                    for( let d in result.result.results )
                    {
                        let row = result.result.results[d]
                        this.stats[row.platform].push( row )
                    }

                    this.initialized = true

                } )
                .catch( () => {
                    clearInterval( this.timer )
                })

        },

        uptime( value )
        {

            let minutes = Math.floor( value / 60000 )

            let hours = Math.floor( minutes / 60 )
            minutes = minutes - ( hours * 60 )

            let days = Math.floor( hours / 24 )
            hours = hours - ( days * 24 )

            return '<strong>' + days + '</strong> Tage, <strong>' + hours + '</strong> Stunden, <strong>' + minutes + '</strong> Minuten'

        },

        memory( value )
        {

            let mega = ( value / 1024 / 1024 ).toFixed( 2 )
            return '<strong>' + mega + ' mb</strong> (' + value + ' b)'

        }

    }

}
</script>