<template>
    <transition appear name="slide-left">
        <div class="fullscreen">
            <div class="content" v-if="ready">
                <div class="title">
                    <div class="headline" v-html="'Logs für '+element.username+' | '+element.name"></div>
                    <div class="closer" @click="close()">
                        X
                    </div>
                </div>
                <div class="info padded noscroll">
                    <ElementFilter @refresh="handleRefresh()" :noFilterStore="true"
                                   :showStopButton="true" @stop="handleStop()"
                                   @filtersActive="handleFiltersActive"
                                   :filterKey="filterKey"
                                   :sub="true"/>
                    <div class="button-space">
                        <div :class="'style-button'+( mode === 'live' ? ' active' : '')" @click="set( 'live' )">
                            LIVE-LOG
                        </div>
                        <div :class="'style-button'+( mode === '24h' ? ' active' : '')" @click="set( '24h' )">0h-24h
                        </div>
                        <div :class="'style-button'+( mode === '48h' ? ' active' : '')" @click="set( '48h' )">24h-48h
                        </div>
                        <div :class="'style-button'+( mode === '72h' ? ' active' : '')" @click="set( '72h' )">48h-72h
                        </div>
                        <div :class="'style-button'+( mode === '96h' ? ' active' : '')" @click="set( '96h' )">72h-96h
                        </div>
                        <Pagination v-if="'live' !== mode && 0 < pages.length" :pages="pages.length" :page="page"
                                    @switch="page = $event"/>
                    </div>
                    <div class="log-space">
                        <table>
                            <thead>
                            <tr>
                                <Thd id="id" caption="#" @sort="ignore"/>
                                <Thd id="type_plain" caption="Zeitstempel" @sort="ignore"/>
                                <Thd id="type_plain" caption="Typ" @sort="ignore"/>
                                <Thd id="type_plain" caption="Log" @sort="ignore"/>
                            </tr>
                            </thead>
                            <tbody class="filterable">
                            <template v-if="'live' === mode">
                                <tr v-for="log in logs" :id="'log_'+log.id" :key="'logging_'+log.id"
                                    :style="log.style"><!--:class="filtersActive ? 'hidden' : ''"-->
                                    <td>{{ log.id }}</td>
                                    <td style="white-space: nowrap;">{{ log.date }},&nbsp;{{ log.time }} Uhr</td>
                                    <td>{{ log.severity }}</td>
                                    <td><span class="log" v-html="log.log"></span></td>
                                </tr>
                            </template>
                            <template v-else>
                                <tr v-for="log in pages[page]" :id="'log_'+log.id" :key="'logging_'+log.id"
                                    :style="log.style">
                                    <td>{{ log.id }}</td>
                                    <td style="white-space: nowrap;">{{ log.date }},&nbsp;{{ log.time }} Uhr</td>
                                    <td>{{ log.severity }}</td>
                                    <td><span class="log" v-html="log.log"></span></td>
                                </tr>
                            </template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Spinner v-else title="Das kann dauern" message="Log-Informationen werden abgerufen..."/>
        </div>
    </transition>
</template>

<script>
import ElementFilter from '@/components/elements/ElementFilter'
import Thd           from '@/components/elements/Thd'
import Spinner       from '../Spinner'
import Pagination    from '../Pagination'

export default {

    name      : 'LogView',
    components: { Pagination, Spinner, Thd, ElementFilter },
    props     : [ 'element' ],
    emits     : [ 'refresh', 'close' ],

    data()
    {
        return {
            ready        : false,
            logs         : [],
            pages        : [],
            page         : 0,
            since        : 0,
            totalCount   : 0,
            timer        : null,
            autoscroll   : true,
            firstFetch   : true,
            filterKey    : false,
            filtersActive: false,
            mode         : 'live'
        }
    },

    created()
    {

        this.init()

    },

    beforeDestroy()
    {
        clearTimeout( this.timer )
        this.timer = null
    },

    methods: {

        handleStop()
        {
            this.autoscroll = !this.autoscroll
        },

        handleFiltersActive( state )
        {
            this.filtersActive = state
        },

        handleRefresh()
        {
            clearTimeout( this.timer )
            this.logs = []
            this.pages = []
            this.page = 0
            this.timer = null
            this.since = 0
            this.firstFetch = true
            this.totalCount = 0
            this.init()
        },

        close()
        {
            this.$emit( 'close' )
        },

        ignore()
        {
            return
        },

        prepareLogline( element )
        {

            let logline = element.log
            let parts = logline.split( / /g )
            let prefix = parts.shift()
            let date = parts.shift()
            let time = parts.shift()
            parts.shift()
            let severity = parts.shift()
            severity = severity.substr( 1, ( severity.length - 2 ) )
            parts.shift()
            let style = ''
            switch( severity )
            {
                case 'CLASS_CONSTRUCTED':
                case 'SUCCESS':
                    style = 'background: #ceffce; font-weight:bold;'
                    break
                case 'ERROR':
                    style = 'background: #ffcece; font-weight:bold;'
                    break
                case 'CERROR':
                    style = 'background: #ff2c2c; color:white; font-weight:bold;'
                    break
                case 'DEBUG':
                case 'PARAMETER_DUMP':
                    style = 'background: #ffffce; font-weight:bold;'
                    break
            }

            let ll = parts.join( ' ' ).trim()
            ll = ll.replace( /\[\[BR\]\]/g, "<br/>&bull;&nbsp;" )

            let parsed = {
                id      : element.id,
                style   : style,
                date    : date,
                time    : time,
                severity: severity,
                prefix  : prefix,
                log     : ll,
                raw     : logline
            }

            return parsed
        },

        init( rep, still )
        {

            if( !this.autoscroll )
            {
                return
            }

            clearInterval( this.timer )
            this.timer = null

            let message = {
                method : 'admin.tools_logView',
                mode   : this.mode,
                since  : this.since,
                id_user: this.$props.element.id
            }

            this.$core.getClient()
                .request( message )
                .then( result =>
                {

                    let list = result.result
                    if( 'live' === this.mode )
                    {
                        for( let l in list )
                        {
                            if( rep )
                            {
                                this.logs.shift()
                            }
                            this.logs.push( this.prepareLogline( list[ l ] ) )
                            this.since = list[ l ].id
                        }

                        if( this.autoscroll
                            && undefined === still )
                        {
                            this.$nextTick()
                                .then( () =>
                                {
                                    this.filterKey = 'flt-' + Date.now()
                                    this.ready = true
                                    let elm = document.querySelector( '#log_' + this.since )
                                    if( null !== elm )
                                    {
                                        if( this.firstFetch )
                                        {
                                            elm.scrollIntoView()
                                            this.firstFetch = false
                                        }
                                        else
                                        {
                                            elm.scrollIntoView( { behavior: 'smooth' } )
                                        }
                                    }
                                } )
                        }

                        if( null === this.timer && 'live' === this.mode )
                        {
                            this.timer = setInterval( () =>
                            {
                                this.init( true )
                            }, 1000 )
                        }
                    }
                    else
                    {

                        this.totalCount = 0
                        let p = 0
                        let i = 0
                        for( let l in list )
                        {

                            if( rep )
                            {
                                this.logs.shift()
                            }
                            if( undefined === this.pages[ p ] )
                            {
                                this.pages[ p ] = []
                            }
                            this.pages[ p ].push( this.prepareLogline( list[ l ] ) )
                            i++
                            this.totalCount++
                            if( i % 1000 === 0 )
                            {
                                p++
                            }
                        }

                        this.ready = true

                    }

                } )
                .catch( () =>
                {
                    console.log( 'FOO' )
                } )

        },

        set( mode )
        {
            if( mode !== this.mode )
            {

                this.ready = false
                this.mode = mode
                this.since = 0
                clearInterval( this.timer )
                this.timer = null
                this.logs = []
                this.pages = null
                this.pages = []
                this.$nextTick()
                    .then( () =>
                    {
                        this.init( false, true )
                    } )

            }

        }

    }
}
</script>