<template>
    <transition appear name="fade">
        <div class="infobox">
            <Opener id="sessionsStats" :state="open" inline=true
                    caption="Session-Statistiken"
                    @clicked="open= !open"/>
            <div v-if="open">
                <div>
                    <p>Nutzungszeit je Session<br/><strong>{{ total }}</strong>
                        Werte insgesamt, Sitzungsdauer &Oslash;&nbsp;<strong>{{ average }}</strong> Minuten</p>
                    <DisplaySessionStats :height=300 v-if="ready" :data="data"/>
                </div>
                <div>
                    <h2>Requests</h2>
                    <p><strong>{{ totalR }}</strong> Requests insgesamt, <strong>{{ percentage }}</strong>% erfolgreich
                    </p>
                    <DisplaySessionStats :height=300 v-if="ready" :data="dataR"/>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import DisplaySessionStats from '@/components/elements/boxes/stats/DisplaySessionStats'
import Opener              from '@/components/elements/Opener'

export default {
    name      : 'UserSessionStats',
    components: { DisplaySessionStats, Opener },
    
    props: [ 'element' ],
    
    data()
    {
        return {
            ready     : false,
            data      : false,
            dataR     : false,
            average   : 0,
            total     : 0,
            totalR    : 0,
            percentage: 0,
            open      : false,
            trans     : {
                count_0_60     : 'weniger als 1 Minute',
                count_60_180   : '1-3 Minuten',
                count_180_300  : '3-5 Minuten',
                count_300_600  : '5-10 Minuten',
                count_600_1200 : '10-20 Minuten',
                count_1200_1800: '20-30 Minuten',
                count_1800_3600: '30-60 Minuten',
                count_gt_3600  : 'mehr als 1 Stunde',
                requests       : 'erfolgreiche Requests',
                errors         : 'fehlerhafte Requests'
            }
        }
    },
    
    mounted()
    {
        let message = {
            method : 'admin.stats_getUserSessionStatistics',
            id_user: this.$props.element.id
        }
        
        this.$core.getClient()
            .request( message )
            .then( result =>
            {
                
                let data = result.result
                let prep = {
                    labels: [],
                    set   : []
                }
                
                let prepR = {
                    labels: [],
                    set   : []
                }
                let count = 0
                let succ = 0
                
                for( let k in Object.keys( data ) )
                {
                    let key = Object.keys( data )[ k ]
                    if( key !== 'average'
                        && key !== 'requests'
                        && key !== 'errors' )
                    {
                        count += parseInt( data[ key ] )
                        prep.labels.push( this.trans[ key ] ? this.trans[ key ] : key )
                        prep.set.push( parseInt( data[ key ] ) )
                    }
                    else
                    {
                        switch( key )
                        {
                            case 'average':
                                this.average = ( parseFloat( data[ key ] ) / 60 ).toFixed( 2 )
                                break
                            case 'requests':
                                succ = parseInt( data[ key ] )
                                /*fallsthrough*/
                            case 'errors':
                                this.totalR += parseInt( data[ key ] )
                                prepR.labels.push( this.trans[ key ] )
                                prepR.set.push( parseInt( data[ key ] ) )
                                break
                        }
                        
                    }
                }
                
                this.percentage = ( ( succ / this.totalR ) * 100 ).toFixed( 2 )
                this.total = count
                this.data = prep
                this.dataR = prepR
                this.ready = true
                
            } )
            .catch( () =>
            {
            } )
    }
    
}
</script>