<template>
    <div class="attachment-preview" v-if="ready">
        <div>
            <div class="button delete raised" @click="$emit( 'delete' )">
                <strong>X</strong>
            </div>
            <ImageZoom v-if="zoom"
                       :filename="filename"
                       :data-uri="downloadable"
                       @close="zoom = false"/>
            <template v-if="isImage">
                <div class="attachment-image"
                     @click="zoom = true"
                     :style="'background-image:url('+downloadable+')'"></div>
            </template>
            <template v-else>
                <div class="attachment-image placeholder">
                    <div class="placeholder-text">{{ ext }}</div>
                </div>
            </template>
            <strong>{{ filename }}</strong><br/>
            {{ mimeType }} ({{ fileSize }}kb)
        </div>
        <div class="clearfix"></div>
    </div>
</template>

<script>
import ImageZoom from './ImageZoom'

export default {
    name      : 'AttachmentPreview',
    components: { ImageZoom },
    props     : {
        file: { Type: Object, required: true }
    },
    emits     : [ 'delete' ],
    data()
    {
        return {
            downloadable: false,
            mimeType    : false,
            filename    : '',
            ext         : '',
            fileSize    : 0,
            ready       : false,
            isImage     : false,
            zoom        : false
        }
    },
    created()
    {

        let att = this.$props.file

        let mimeSplit = att.type.split( '/' )
        this.isImage = mimeSplit[ 0 ] === 'image'
        this.ext = mimeSplit[ 1 ]
        this.filename = att.name
        this.mimeType = att.type
        this.fileSize = ( att.size / 1024 ).toFixed( 2 )

        if( this.isImage )
        {
            let reader = new FileReader()
            reader.readAsDataURL( att )
            reader.onloadend = () =>
            {
                this.downloadable = reader.result
                this.ready = true
            }
        } else
        {
            this.ready = true
        }

    },

    methods: {}


}
</script>