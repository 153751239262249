import BadgeCounter from "@/classes/helpers/BadgeCounter";

export default class Badge
{

    constructor()
    {
        if( !Badge.instance )
        {

            this.badgeCounter = new BadgeCounter()
            this.counter = 0
            this.counts = new Map()

            Badge.instance = this

        }
        return Badge.instance

    }

    setCount( topic, value )
    {
        this.counts.set( topic, value )
        this.updateBadge()
    }

    updateBadge()
    {

        let totalCount = 0
        for( let [ key, value ] of this.counts )
        {
            totalCount+= value
        }

        this.badgeCounter.value = totalCount

    }

}