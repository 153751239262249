<template>
    <div v-if="ready">
        <UserInfo v-if="visibleElement !== false" :element="visibleElement" @close="visibleElement = false"
                  @refresh="handleUserRefresh( true )"/>
        <ElementFilter @refresh="reset()" id="invoices"/>
        <PaymentData v-if="paymentData" :data="paymentData" @close="paymentData = false"/>
        <table>
            <thead>
            <Thd id="id_invoice" caption="#" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortInvoices"
                 @sort="handleSort"></Thd>
            <Thd id="status" caption="Status" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortInvoices"
                 @sort="handleSort"></Thd>
            <Thd id="name" caption="Kunde" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortInvoices"
                 @sort="handleSort"></Thd>
            <Thd id="email" caption="E-Mail" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortInvoices"
                 @sort="handleSort"></Thd>
            <Thd id="datetime_invoice" caption="Rechnungsdatum" :sortBy="sortBy" :sortDir="sortDir"
                 sortRule="sortInvoices" @sort="handleSort"></Thd>
            <Thd id="costs" caption="Rechnungsbetrag" style="text-align: right;" :sortBy="sortBy" :sortDir="sortDir"
                 sortRule="sortInvoices" @sort="handleSort"></Thd>
            <Thd id="datetime_paid" caption="Zahlungseingang" :sortBy="sortBy" :sortDir="sortDir"
                 sortRule="sortInvoices" @sort="handleSort"></Thd>
            <Thd id="datetime_cancel" caption="Storniert am" :sortBy="sortBy" :sortDir="sortDir"
                 sortRule="sortInvoices" @sort="handleSort"></Thd>
            <Thd caption="Optionen" style="width:100px;"></Thd>
            </thead>
            <tbody class="filterable">
            <tr v-for="invoice in invoices" :key="'row_'+invoice.id" class="clickable">
                <td>{{ invoice.id_invoice }} {{ invoice.id_user }}</td>
                <td><span v-if="'paid' === invoice.status" class="good"><strong>bezahlt</strong></span>
                    <span v-if="'cancelled' === invoice.status" class="error"><strong>storniert</strong></span>
                    <span v-if="'open' === invoice.status" class="comment"><strong>offen</strong></span></td>
                <td><strong class="clickable_user" @click="handleUserInfo( $event, invoice.id_user )">
                    {{ invoice.name }}</strong></td>
                <td>{{ invoice.email }}</td>
                <td v-html="invoice.datetime_invoice_formatted"></td>
                <td style="text-align:right;"><strong>{{ ( '' + invoice.costs.toFixed( 2 ) ).replace( '.', ',' ) }}
                    &euro;</strong></td>
                <td v-html="invoice.datetime_paid_formatted"></td>
                <td v-html="invoice.datetime_cancel_formatted"></td>
                <td class="nowrap">
                    <div class="button download" @click="getInvoice( invoice.id )"></div>
                    <template v-if="null === invoice.datetime_paid || '1970-01-01 00:00:00' === invoice.datetime_paid">
                        <div
                            v-if="null === invoice.datetime_cancel || '1970-01-01 00:00:00' === invoice.datetime_cancel"
                            class="button cancel"
                            @click="cancelInvoice( invoice.id )">S
                        </div>
                        <div
                            v-if="null !== invoice.datetime_cancel && '1970-01-01 00:00:00' !== invoice.datetime_cancel"
                            class="button reactivate" @click="reactivateInvoice( invoice.id )">R
                        </div>
                        <div
                            v-if="null === invoice.datetime_cancel || '1970-01-01 00:00:00' === invoice.datetime_cancel"
                            class="button setpaid" @click="setPaidInvoice( invoice.id )">B
                        </div>
                    </template>
                    <template v-if="null !== invoice.datetime_paid && '1970-01-01 00:00:00' !== invoice.datetime_paid">
                        <div
                            v-if="null === invoice.datetime_cancel || '1970-01-01 00:00:00' === invoice.datetime_cancel"
                            class="button cancel" @click="setUnpaidInvoice( invoice.id )"
                            title="als unbezahlt markieren">U
                        </div>
                    </template>
                    <template v-if="null !== invoice.paymentData">
                        <div class="button" @click="showDetails( invoice.paymentData )"><strong>i</strong></div>
                    </template>
                </td>
            </tr>
            <tr v-if="0 === invoices.length" id="nocount">
                <td colspan="9" style="text-align: center; line-height: 300px;">
                    <strong>keine Rechnungen</strong> für den ausgewählten Zeitraum vorhanden
                </td>
            </tr>
            </tbody>
            <tfoot>
            <tr v-for="(s, key) in sums" :key="'sums-'+key">
                <td colspan="4">&nbsp;</td>
                <td :class="classes[key]" style="text-align: right;"><strong>{{
                        $core.t().translate( 'sums-' + key )
                    }}</strong></td>
                <td :class="classes[key]" style="text-align: right;">
                    <strong>{{ ( '' + s.toFixed( 2 ) ).replace( '.', ',' ) }} &euro;</strong></td>
                <td colspan="3">&nbsp;</td>
            </tr>
            <tr :id="viewId">
                <th colspan="99" style="text-align:right;" class="filtercount">...</th>
            </tr>
            </tfoot>
        </table>
    </div>
    <Spinner v-else title="Das kann dauern" message="Rechnungen werden abgerufen..."/>
</template>

<script>
/* eslint-disable */
import Spinner        from '@/components/elements/Spinner'
import Thd            from '@/components/elements/Thd'
import UserInfo       from '@/components/elements/fullscreen/UserInfo'
import ElementFilter  from '@/components/elements/ElementFilter'
import PaymentData    from '../elements/fullscreen/PaymentData'
import MixinViewReady from '@/mixins/MixinViewReady'

export default {

    name      : 'Invoices',
    components: { PaymentData, ElementFilter, UserInfo, Thd, Spinner },
    mixins    : [ MixinViewReady ],
    data()
    {
        return {
            ready         : false,
            paymentData   : false,
            invoices      : [],
            sums          : { paid: 0, cancelled: 0, open: 0, total: 0 },
            classes       : { paid: 'good', cancelled: 'error', open: 'okay', total: 'comment' },
            sortBy        : 'id_invoice',
            sortDir       : 'descending',
            visibleElement: false
        }
    },

    mounted()
    {
        this.$core.getEventManager().add( 'on-set-filter', ( filter ) =>
        {
            this.ready = false
            this.$nextTick()
                .then( () =>
                {
                    this.listAll( filter )
                } )
        } )

        this.sortBy = null !== this.$store.getters.sortInvoices ? this.$store.getters.sortInvoices : 'id_invoice'
        this.sortDir = null !== this.$store.getters.sortInvoicesDir ? this.$store.getters.sortInvoicesDir : 'descending'
        this.listAll()

    },

    beforeDestroy()
    {

        this.$core.getEventManager().remove( 'on-set-filter' )

    },

    methods: {

        handleUserInfo( event, user )
        {
            event.stopPropagation()
            if( undefined !== user )
            {
                let cache = this.$store.getters.usersCache,
                    found = false
                for( let c in cache )
                {
                    if( cache[ c ].id === user )
                    {
                        this.visibleElement = cache[ c ]
                        found = true
                        continue
                    }
                }
                if( !found )
                {
                    this.$core.f().uiMessage( 'error', '<strong>Der Nutzeraccount scheint nicht (mehr) zu existieren</strong><br/>Entweder handelt es sich um einen sehr kaputten Testaccount oder der Account wurde gelöscht.' )
                }
            }
        },

        showDetails( paymentData )
        {
            this.paymentData = JSON.parse( paymentData )
        },

        reset()
        {
            this.ready = false
            this.invoices = []
            this.sums = { paid: 0, cancelled: 0, open: 0, total: 0 }
            this.$store.commit( 'setInvoicesCache', null )
            this.listAll()
        },

        getInvoice( id )
        {
            let invoiceTest = {
                method: 'admin.invoices_getById',
                id    : id
            }

            this.$core.getClient()
                .request( invoiceTest )
                .then( result =>
                {

                    let res         = result,
                        arrayBuffer = new Uint8Array( res.invoice.data ),
                        file        = new Blob( [ arrayBuffer ], { type: 'application/pdf' } ),
                        fileURL     = window.URL.createObjectURL( file )

                    const link = document.createElement( 'a' )
                    link.href = fileURL
                    link.setAttribute( 'download', res.id_invoice + '.pdf' )
                    document.body.appendChild( link )
                    link.click()

                } )

        },

        cancelInvoice( id )
        {
            let invoiceSet = {
                method: 'admin.invoices_cancel',
                id    : id
            }

            this.$core.getClient()
                .request( invoiceSet )
                .then( () =>
                {
                    setTimeout( () =>
                    {
                        this.reset()
                    }, 500 )
                } )
        },

        reactivateInvoice( id )
        {
            let invoiceSet = {
                method: 'admin.invoices_reactivate',
                id    : id
            }

            this.$core.getClient()
                .request( invoiceSet )
                .then( () =>
                {
                    setTimeout( () =>
                    {
                        this.reset()
                    }, 500 )
                } )
        },

        setPaidInvoice( id )
        {
            let invoiceSet = {
                method: 'admin.invoices_setPaid',
                id    : id
            }

            this.$core.getClient()
                .request( invoiceSet )
                .then( () =>
                {
                    setTimeout( () =>
                    {
                        this.reset()
                    }, 500 )
                } )
        },

        setUnpaidInvoice( id )
        {
            let invoiceSet = {
                method: 'admin.invoices_setUnpaid',
                id    : id
            }

            this.$core.getClient()
                .request( invoiceSet )
                .then( () =>
                {
                    setTimeout( () =>
                    {
                        this.reset()
                    }, 500 )
                } )
        },

        listAll( filter )
        {

            if( this.$store.getters.invoicesCache === null
                || undefined !== filter )
            {

                this.sums = { paid: 0, cancelled: 0, open: 0, total: 0 }

                let message = {
                    method: 'admin.invoices_listAll'
                }

                if( undefined !== filter
                    && 'reset' !== filter )
                {
                    message.filter = filter
                }

                this.$core.getClient()
                    .request( message )
                    .then( result =>
                    {

                        let invoiceList = []

                        for( let r in result.result )
                        {

                            let invoice = result.result[ r ]
                            invoice.status = 'open'
                            if( invoice.datetime_paid !== null )
                            {
                                invoice.status = 'paid'
                            }
                            if( invoice.datetime_cancel !== null )
                            {
                                invoice.status = 'cancelled'
                            }

                            invoice.name = invoice.lastname + ', ' + invoice.firstname
                            if( null === invoice.datetime_cancel )
                            {
                                invoice.datetime_cancel = '1970-01-01 00:00:00'
                                invoice.datetime_cancel_formatted = '---'
                            }
                            if( null === invoice.datetime_paid )
                            {
                                invoice.datetime_paid = '1970-01-01 00:00:00'
                                invoice.datetime_paid_formatted = '---'
                            }

                            this.sums[ invoice.status ] += invoice.costs
                            if( '1970-01-01 00:00:00' === invoice.datetime_cancel )
                            {
                                this.sums.total += invoice.costs
                            }
                            this.fixFormats( invoice )

                            invoiceList.push( invoice )

                        }

                        this.ready = true

                        this.$store.commit( 'setInvoicesCache', invoiceList )
                        this.invoices = invoiceList
                        this.sort()

                    } )
            }
            else
            {
                this.invoices = this.$store.getters.invoicesCache
                this.sort()
                this.ready = true
            }

        },

        fixFormats( invoice )
        {

            let formats = [ 'invoice', 'paid', 'cancel' ]
            for( let f in formats )
            {
                let key = 'datetime_' + formats[ f ] + '_formatted'
                let test = invoice[ key ].split( ', ' )
                if( 1 < test.length )
                {
                    invoice[ key ] = ( '<strong>' + test[ 0 ] + '</strong>, ' + test[ 1 ] ).replace( / /g, '&nbsp;' )
                }
            }

        },

        sort()
        {
            this.invoices = this.$core.getSorter().sortObjects( this.invoices, this.sortBy, this.sortDir )
        },

        handleSort( args )
        {

            let newDir = args.sortDir === 'ascending' ? 'descending' : 'ascending'
            this.$store.commit( 'setSortInvoices', args.id )
            this.$store.commit( 'setSortInvoicesDir', newDir )
            this.sortBy = args.id
            this.sortDir = newDir
            this.sort()

        },

        refreshUsers( force )
        {
            return new Promise( resolve =>
            {
                this.$core.getCacher()
                    .fillUsersCache( force )
                    .then( () =>
                    {

                        return resolve()

                    } )

            } )

        },

        handleUserRefresh( force )
        {

            this.refreshUsers( force )
                .then( () => {

                    this.reset()
                    setTimeout( () => {
                        this.$core.getEventManager().dispatch( 'hideBlocker' )
                    }, 500 )

                })

        }

        /*        open( element )
                {
                    this.visibleElement = element
                },

                close()
                {
                    this.visibleElement = false
                }*/


    }


}
</script>