<template>
    <div class="blocker" v-show="active">
        <div class="spinner"></div>
        <div class="message">Augenblick bitte...</div>
    </div>
</template>

<script>
export default {
    name: 'Blocker',

    data()
    {
        return {
            active: false
        }
    },

    created()
    {
        this.$core.getEventManager().add( 'showBlocker', () => {
            this.active = true
        })
        this.$core.getEventManager().add( 'hideBlocker', () => {
            this.active = false
        })
    }
}
</script>