<template>
    <div v-if="ready" style="margin-top:20px;">
        <UserInfo v-if="visibleElement !== false" :element="visibleElement" @close="closeUserInfo()"/>
        <template v-if="directMatch">
            <div>
                <strong class="clickable_user"
                        @click="handleUserInfo( $event, user )">{{ user.username }}</strong><br/>
                <span class="good">Zuordnung über E-Mail-Adresse</span>
            </div>
        </template>
        <template v-else>
            <template v-for="match in possibleMatches" :key="'tpl-'+match.id">
                <div>
                    <strong class="clickable_user"
                            @click="handleUserInfo( $event, match )">{{ match.username }}</strong><br/>
                    <span class="okay">Zuordnung über Absendernamen</span>
                </div>
            </template>
            <template v-if="0 === possibleMatches.length">
                <div>
                    <strong>&bull; kein User gefunden</strong>
                </div>
            </template>
        </template>
        <br/>
        <template v-if="directMatch">
            <div>
                <template v-if="user.lname !== null || user.lpname !== null">
                    {{ user.lname !== null ? user.lname : user.lpname }}<br/>
                </template>
                dabei seit: <strong>{{ toLocal( user.datetime_created ) }}</strong><br/>
                <strong>{{ user.object_count }}</strong> Elemente<br/>
            </div>
        </template>
        <template v-if="1 === possibleMatches.length">
            <div>
                <template v-if="possibleMatches[0].lname !== null || possibleMatches[0].lpname !== null">
                    {{ possibleMatches[0].lname !== null ? possibleMatches[0].lname : possibleMatches[0].lpname }}<br/>
                </template>
                dabei seit: <strong>{{ toLocal( possibleMatches[0].datetime_created ) }}</strong><br/>
                <strong>{{ possibleMatches[0].object_count }}</strong> Elemente<br/>
            </div>
        </template>

    </div>
</template>

<script>
/*eslint-disable*/
import UserInfo    from '../fullscreen/UserInfo'
import UserLicense from '../boxes/UserLicense'

export default {
    name      : "UserSidebar",
    components: { UserLicense, UserInfo },
    props     : {
        mailSender: { Type: String, required: true }
    },
    
    data()
    {
        return {
            users          : false,
            directMatch    : false,
            possibleMatches: [],
            user           : false,
            ready          : false,
            visibleElement : false
        }
    },
    
    created()
    {
        
        this.$core.getCacher()
            .fillUsersCache()
            .then( userlist =>
            {
                
                this.users = userlist
                this.parse()
                this.ready = true
                
            } )
        
    },
    
    methods: {
        
        closeUserInfo()
        {
            this.visibleElement = false
        },
    
        handleUserInfo( event, user )
        {
            event.stopPropagation()
            if( undefined !== user )
            {
                this.visibleElement = user
            }
        },
    
        toLocal( timestamp )
        {
            let temp = timestamp.split( 'T' )
            let date = temp[ 0 ].split( '-' ).reverse().join( '.' )
            return date
        },
        
        parse()
        {
            
            let regex = /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/g
            let matches = regex.exec( this.$props.mailSender )
            
            let test = ''
            if( null !== matches )
            {
                test = matches[ 0 ]
            }
            else
            {
                test = this.$props.mailSender
            }
            
            for( let u in this.users )
            {
                if( null !== test && null !== this.users[ u ].email )
                {
                    if( this.users[ u ].email.toLowerCase().trim() === test.toLowerCase().trim() )
                    {
                        this.directMatch = true
                        this.user = this.users[ u ]
                    }
                }
            }
            
            if( !this.directMatch )
            {
                
                let name = this.$props.mailSender.split( /\ /g )
                let firstname = name[ 0 ]
                let lastname = name[ 1 ]
                
                for( let u in this.users )
                {
                    if( undefined !== firstname
                        && undefined !== lastname
                        && undefined !== this.users[ u ].firstname
                        && null !== this.users[ u ].firstname
                        && false !== this.users[ u ].firstname )
                    {
                        if( ( this.users[ u ].firstname.toLowerCase() === firstname.toLowerCase()
                              && this.users[ u ].lastname.toLowerCase() === lastname.toLowerCase() )
                            || ( this.users[ u ].firstname.toLowerCase() === lastname.toLowerCase()
                                 && this.users[ u ].lastname.toLowerCase() === firstname.toLowerCase() ) )
                        {
                            this.possibleMatches.push( this.users[ u ] )
                        }
                    }
                }
                
            }
            
        }
        
    }
    
}
</script>