import { createApp }    from 'vue'
import App              from './App.vue'
import router           from '@/router'
import { store }        from '@/store'
import CommonComponents from "@/classes/commonComponents";

/**
 *  PLUGINS
 */
import CorePlugin       from '@/plugins/Core'

const app = createApp( {
    extends: App
} ).use( router )
   .use( store )
   .use( CorePlugin, store, router )

CommonComponents.forEach( component =>
{
    app.component( component.name, component )
} )

/*Vue.config.productionTip = false
Vue.use( CorePlugin, store, router )

new Vue( {
    router,
    store,
    render: h => h( App )
} ).$mount( '#app' )*/

app.mount( '#app' )
