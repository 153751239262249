function _isSortable( what )
{
    
    if( true === what
        || false === what
        || undefined === what
        || null === what
        || ( !isNaN( parseFloat( what ) ) && isFinite( what ) ) )
    {
        return false
    }
    return true
    
}

function _sortDefaultAsc( a, b )
{
    
    let keyA = a.sort_id
    let keyB = b.sort_id
    
    if( !_isSortable( keyA ) || !_isSortable( keyB ) )
    {
        if( keyA < keyB ) return -1
        if( keyA > keyB ) return 1
        return 0
    }
    
    return keyA.localeCompare( keyB )
    
}

function _sortDefaultDesc( a, b )
{
    
    let keyA = a.sort_id
    let keyB = b.sort_id
    
    if( !_isSortable( keyA ) || !_isSortable( keyB ) )
    {
        if( keyA > keyB ) return -1
        if( keyA < keyB ) return 1
        return 0
    }
    
    return keyB.localeCompare( keyA )
    
}

export default class Sorter
{
    
    constructor()
    {
        
        if( !Sorter.instance )
        {
            
            Sorter.instance = this
            
        }
        
        return Sorter.instance
        
    }
    
    plainSort( sortable )
    {
        
        let keys = []
        let sorted = {}
        
        for( let key in sortable )
        {
            keys.push( {
                sort_id : key,
                original: key
            } )
        }
        
        keys.sort( _sortDefaultAsc )
        
        for( let s in keys )
        {
            sorted[ keys[ s ].original ] = sortable[ keys[ s ].original ]
        }
        
        return sorted
        
    }
    
    sortObjects( sortable, sortby, direction )
    {
        
        let temp = []
        let sorted = []
        
        for( let n in sortable )
        {
            if( -1 < sortby.indexOf( '.' ) )
            {
                let t = sortby.split( '.' )
                temp.push( {
                    sort_id : sortable[ n ][ t[ 0 ] ][ t[ 1 ] ],
                    original: sortable[ n ]
                } )
            } else
            {
                let sortId = sortable[ n ][ sortby ]
                temp.push( {
                    sort_id : sortId,
                    original: sortable[ n ]
                } )
            }
        }
        
        if( 'ascending' == direction
            || undefined === direction )
        {
            temp.sort( _sortDefaultAsc )
        } else
        {
            temp.sort( _sortDefaultDesc )
        }
        
        for( let n in temp )
        {
            sorted.push( temp[ n ].original )
        }
        
        return sorted
        
    }
    
    multiSortObjects( sortable, sortby, ignorePinning )
    {
        
        let result = sortable
        let pinningSorted = false
        
        for( let i = 0; i < sortby.length; i++ )
        {
            
            let s = sortby[ i ]
            
            let temp = []
            let sorted = []
            
            let sort = s[ 0 ]
            let direction = s[ 1 ]
            
            if( sort === 'pinned' )
            {
                pinningSorted = true
            }
            
            for( let n in result )
            {
                
                let sortId = '' + result[ n ][ sort ]
                
                if( undefined == result[ n ][ sort ] )
                {
                    if( 'update' === sort
                        && undefined !== result[ n ][ 'timestamp' ] )
                    {
                        sortId = result[ n ][ 'timestamp' ]
                    } else
                    {
                        if( 'timestamp' === sort
                            && undefined !== result[ n ][ 'tsmpEnd' ] )
                        {
                            sortId = result[ n ][ 'tsmpEnd' ]
                        } else
                        {
                            if( 'update' === sort
                                && undefined !== result[ n ][ 'lists' ] )
                            {
                                
                                let minUpdate = undefined !== result[ n ][ 'tsmpEnd' ] ? result[ n ][ 'tsmpEnd' ] : -1
                                for( let l in result[ n ][ 'lists' ] )
                                {
                                    let list = result[ n ][ 'lists' ][ l ]
                                    if( list.update > minUpdate )
                                    {
                                        minUpdate = list.update
                                    }
                                }
                                sortId = minUpdate
                            } else
                            {
                                sortId = '0'
                            }
                        }
                    }
                    
                }
                
                if( sort == 'sortableTimestamp' )
                {
                    sortId = sortId.replace( '.', '' )
                    if( sortId.length < 6 )
                    {
                        sortId = sortId.substr( 0, 4 ) + '0' + sortId.substr( 4, 1 )
                    }
                    sortId = parseInt( sortId )
                }
                
                temp.push( {
                    sort_id : sortId,
                    original: result[ n ]
                } )
                
            }
            
            if( 'ascending' == direction
                || undefined === direction )
            {
                temp.sort( _sortDefaultAsc )
            } else
            {
                temp.sort( _sortDefaultDesc )
            }
            
            for( let n in temp )
            {
                sorted.push( temp[ n ].original )
            }
            
            if( !pinningSorted && undefined === ignorePinning )
            {
                sorted = this.sortObjects( sorted, 'pinned', 'descending' )
            }
            
            result = sorted
            
        }
        
        return result
        
    }
    
}