<template>
    <FullscreenAwait v-if="!ready"/>
    <div class="login-box" :style="'opacity:'+opacity+'; display:'+display+';'"
         v-else>
        <div class="logo login"></div>
        <transition appear name="fade" mode="in-out">
            <form v-if="whitelist && !logging">
                <div class="login-form">
                    <div class="left">Benutzername</div>
                    <div class="right"><input type="text" autocomplete="username" id="user"/></div>
                    <div class="left">Passwort</div>
                    <div class="right"><input type="password" id="pass" autocomplete="current-password"
                                              @keydown="checkEnter"/></div>
                    <div class="left">&nbsp;</div>
                    <div class="right">
                        <input type="button" value="anmelden" @click="submit()"/>
                    </div>
                </div>
            </form>
        </transition>
        <transition appear name="fade" mode="in-out">
            <form v-if="whitelist && logging">
                <div class="login-form" style="text-align: center;">
                    <p>Augenblickchen...</p>
                </div>
            </form>
        </transition>
        <transition appear name="fade" mode="in-out">
            <form v-if="denied">
                <div class="login-form" style="text-align: center;">
                    <p>Um aus Shakespeares Hamlet,<br/>Akt 4, Szene 5, Vers 28,<br/> zu zitieren:</p>
                    <p class="error">NEIN!</p>
                </div>
            </form>
        </transition>
    </div>
</template>

<script>
import FullscreenAwait from "@/components/elements/FullscreenAwait";

export default {

    name      : 'Login',
    components: { FullscreenAwait },
    data()
    {
        return {
            ready    : false,
            whitelist: false,
            logging  : false,
            denied   : false,
            opacity  : 1,
            display  : 'block'
        }
    },

    mounted()
    {

        this.$nextTick()
            .then( () =>
            {
                if( 1 === this.$store.getters.authorized )
                {
                    this.opacity = 0
                    this.ready = true
                }
            } )

        if( !this.$core.getClient().connected )
        {
            this.$core
                .getEventManager()
                .append( 'onSocketConnect', () =>
                {
                    setTimeout( () =>
                    {

                        this.checkWhitelist()

                    }, 500 )
                } )
        }
        else
        {
            this.checkWhitelist()
        }

    },

    methods: {

        checkEnter( event )
        {
            if( event.keyCode === 13 )
            {
                this.submit()
            }
        },

        checkWhitelist()
        {
            let message = {
                method: 'admin.whiteListRequest'
            }

            this.$core.getClient()
                .request( message )
                .then( () =>
                {
                    this.ready = true
                    this.whitelist = true
                } )
                .catch( () =>
                {
                    this.ready = true
                    this.denied = true
                } )
        },

        submit()
        {

            this.logging = true

            let user    = document.querySelector( '#user' ).value.trim(),
                pass    = document.querySelector( '#pass' ).value.trim(),
                message = {
                    method  : 'admin.tryLogin',
                    username: user,
                    password: pass
                }

            this.$core.getClient()
                .request( message )
                .then( result =>
                {
                    this.$core.getSession().register( result )
                        .then( () =>
                        {
                            this.opacity = 0
                            this.logging = false
                            setTimeout( () =>
                            {
                                this.display = 'none'
                            }, 500 )
                            setTimeout( () =>
                            {
                                this.$router.push( '/dashboard' )
                            }, 2000 )
                        } )
                } )
                .catch( error =>
                {
                    console.error( '<!>', error )
                    setTimeout( () =>
                    {

                        this.logging = false

                    }, 3000 )
                } )

        }

    }
}
</script>