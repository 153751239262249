<template>
    <div>
        <!--        {{ value }}
                {{ parent }}
                {{ id }}-->
        <Opener :id="'opener_'+id" caption="Kompetenzen" :inline="true" @state="handleState"/>
        <div v-show="open">

            <RowAdder rowType="subCompetence"
                      :setup="raSetup" :id="raId" :value="raValue.length > 0 ? raValue : [ 'create' ]"
                      @values="handleValues"/>

            <input type="hidden" :id="id" :value="jsonValue"/>

        </div>
    </div>
</template>

<script>

import Opener   from "@/components/elements/Opener.vue";
import RowAdder from "@/components/elements/formElements/RowAdder.vue";

export default {

    name      : 'OpenableSubCompetence',
    components: { RowAdder, Opener },
    props     : [ 'parent', 'value', 'id' ],
    emits     : [ 'update' ],

    data()
    {
        return {
            ready      : true,
            localId    : false,
            title      : false,
            description: false,
            open       : true,
            raSetup    : {
                title   : {
                    key: 'caption'
                },
                text    : {
                    key: 'description'
                },
                hidden  : {
                    contains  : true,
                    idTemplate: this.$props.id,
                    identifier: {
                        type  : 'localId',
                        source: null
                    }
                },
                adder   : {
                    caption   : '',
                    columnType: '',
                    id        : '',
                    idTemplate: this.$props.id,
                    identifier: ''
                },
                extended: true
            },
            raId       : 'competences_' + this.$props.id,
            raValue    : this.$props.value,
            jsonValue  : JSON.stringify( {} )
        }
    },

    created()
    {

        console.log( 'OSC > CREATED', this.$props.value )

        this.localId = this.$props.value ? this.$props.value.localId || this.$core.uuid().generate() : this.$core.uuid().generate()
        this.title = this.$props.value ? this.$props.value.title || '' : ''
        this.description = this.$props.value ? this.$props.value.description || '' : ''

        if( undefined !== this.$props.value )
        {

            this.jsonValue = JSON.stringify( this.$props.value )
            this.$emit( 'update', this.jsonValue )

        }


    },

    mounted()
    {
    },

    beforeDestroy()
    {
        this.ready = false
    },

    methods: {

        handleValues( event )
        {

            console.log( 'HANDLE OSC', event )
            this.jsonValue = JSON.stringify( event.values )
            this.$emit( 'update', this.jsonValue )

        },

        handleState( state )
        {
            this.open = state
        }

    }

}
</script>