<template>
    <div>
        <div class="width-70">
            <select class="select-css inset" :id="id" @change="applyTemplate">
                <option value="">bitte wählen...</option>
                <option v-for="item in copy" :key="'opt-'+item.raw.id" :value="item.raw.id"
                        :selected="versions[item.raw.identifier] && -1 < versions[item.raw.identifier].indexOf( value )">
                    {{ item.name }}
                </option>
            </select>
        </div>
        <div class="width-30">
            <input type="text" :id="'filter-'+id" placeholder="Filter" @change="applyFilter"/>
        </div>
    </div>
</template>

<script>
export default {

    name : 'NewsletterTemplate',
    props: [ 'id', 'value' ],

    data()
    {
        return {
            ready   : false,
            versions: {},
            list    : [],
            copy    : []
        }
    },

    created()
    {
        this.$core.getClient()
            .request( {
                method: 'admin.tools_listTemplates'
            } )
            .then( response =>
            {

                this.$core.getClient()
                    .request( {
                        method: 'admin.tools_listAllTemplatesAndVersions'
                    } )
                    .then( versionResponse =>
                    {
                        for( let v in versionResponse.result )
                        {
                            let ver = versionResponse.result[ v ]
                            if( '' !== ver.identifier )
                            {
                                if( undefined === this.versions[ ver.identifier ] )
                                {
                                    this.versions[ ver.identifier ] = []
                                }
                                this.versions[ ver.identifier ].push( ver.id )
                            }
                        }

                        let list = response.result.list
                        for( let l in list )
                        {

                            let item = {
                                raw : list[ l ][ 0 ],
                                name: list[ l ][ 0 ].subject + ' (' + list[ l ][ 0 ].identifier + ')'
                            }
                            this.list.push( item )

                        }
                        this.list = this.$core.getSorter().sortObjects( this.list, 'name', 'ascending' )
                        this.copy = JSON.parse( JSON.stringify( this.list ) )

                    } )

            } )
    },

    methods: {
        applyFilter()
        {

            let filterElm = document.querySelector( '#filter-' + this.$props.id )
            if( null !== filterElm )
            {
                let value = filterElm.value
                if( 3 <= value.length )
                {
                    let newCopy = []
                    for( let l in this.list )
                    {
                        if( -1 < this.list[ l ].name.toLowerCase().indexOf( value.toLowerCase() ) )
                        {
                            newCopy.push( this.list[ l ] )
                        }
                    }
                    this.copy = newCopy
                }
                else
                {
                    this.copy = JSON.parse( JSON.stringify( this.list ) )
                }
            }

        },

        fillTarget( subject, text )
        {
            let sElm = document.querySelector( '#subject' )
            let tElm = document.querySelector( '#text' )
            if( null !== sElm && null !== tElm )
            {
                sElm.value = subject
                tElm.value = text
            }
        },

        applyTemplate()
        {

            let selectElm = document.querySelector( '#' + this.$props.id )
            if( null !== selectElm )
            {
                let found = false
                for( let i in this.copy )
                {
                    let compare = this.copy[ i ].raw
                    if( parseInt( compare.id ) === parseInt( selectElm.value ) )
                    {
                        found = true
                        this.fillTarget( compare.subject, compare.text )
                    }
                }

                if( !found )
                {
                    this.fillTarget( '', '' )
                }

            }

        }
    }

}
</script>