<template>
    <div :class="'infobox'+' '+className" v-if="ready">
        <Opener id="licensing" :state="open" inline=true
                :caption="'Lizensierung'+( !open ? '<span style=\'font-weight:normal\'>: '+license.name+', '+( null !== license.days ? license.days : 'Poolnutzer' ) +licenseOptions+'</span>' : '' )"
                @clicked="open = !open"/>
        <template v-if="open">
            <table class="margin-top infotable" v-if="undefined !== license">
                <tr>
                    <td class="center strong">Aktive Lizenz</td>
                    <td class="center">{{ license.name }}</td>
                </tr>
                <tr v-if="license.poolMaster !== null">
                    <td class="center strong">Lizentyp</td>
                    <td class="center" v-if="license.poolMaster === true">Poolmaster</td>
                    <td class="center" v-if="license.poolMaster === false">Poolnutzer</td>
                </tr>
                <tr v-if="license.poolMaster === null">
                    <td class="center strong">Lizentyp</td>
                    <td class="center">Einzellizenz</td>
                </tr>
                <tr v-if="license.start !== null">
                    <td class="center strong">Lizenzbeginn</td>
                    <td class="center">{{ license.start }}</td>
                </tr>
                <tr v-if="license.end !== null">
                    <td class="center strong">Lizenzende</td>
                    <td class="center">{{ license.end }}</td>
                </tr>
                <tr v-if="license.days !== null">
                    <td class="center strong">Verbleibende Laufzeit</td>
                    <td class="center" v-html="license.days"></td>
                </tr>
                <tr v-if="license.days !== null">
                    <td class="center strong">Lizenz kündigen</td>
                    <td class="center">
                        <div class="button nonfixed delete"
                             @click="cancel()">kündigen
                        </div>
                    </td>
                </tr>
                <tr v-if="license.poolMaster === true">
                    <td class="top strong">Pool-Nutzer</td>
                    <td class="center">
                        <table class="infotable">
                            <thead>
                            <tr>
                                <td><strong>#</strong></td>
                                <td><strong>Benutzername</strong></td>
                                <td><strong>Name</strong></td>
                            </tr>
                            </thead>
                            <tr v-for="user in poolUsers" :key="'pu-'+user.id">
                                <td>{{ user.id }}</td>
                                <td>{{ user.username }}</td>
                                <td>{{ user.lastname }}, {{ user.firstname }}</td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>
            <table class="margin-top infotable" v-else>
                <tr>
                    <td class="center strong">&nbsp;</td>
                    <td class="center"><h3 class="error">keine Informationen verfügbar</h3></td>
                </tr>
            </table>
            <template v-if="0 < bookedOptions.length">
                <br/>
                <h3>Gebuchte Lizenz-Erweiterungen</h3>
                <table class="margin-top infotable">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Status</th>
                        <th>Laufzeit-Ende</th>
                        <th>Optionen</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="booked in bookedOptions"
                        :key="'bookey-'+booked.id_license_upgrade">
                        <td><strong>{{ booked.name }}</strong></td>
                        <td>{{ booked.cancellation_timestamp === null ? 'aktiv' : 'gekündigt' }}</td>
                        <td>{{ booked.end_formatted.replace( /__/g, ' ' )}}</td>
                        <td v-if="booked.cancellation_timestamp === null
                                  && null !== license.days" class="center">
                            <div class="button nonfixed delete"
                                 @click="cancelUpgrade( booked.id_license_upgrade )">kündigen
                            </div>
                        </td>
                        <td v-else>
                            keine verfügbar
                        </td>
                    </tr>
                    </tbody>
                </table>
                <br/>
                <h3>Belegter Speicherplatz</h3>
                -- Infos folgen --
            </template>
        </template>
    </div>
</template>

<script>

import Opener from '@/components/elements/Opener'

export default {

    name      : 'UserLicense',
    components: { Opener },
    props     : [ 'element', 'className' ],
    emits     : [ 'refresh', 'localRefresh' ],

    data()
    {
        return {
            license       : false,
            ready         : false,
            open          : false,
            poolUsers     : [],
            bookedOptions : [],
            poolUserCount : '',
            licenseOptions: ''
        }
    },

    mounted()
    {

        this.getLicense()
        this.getLicenseUpgrades()

    },

    methods: {

        getLicense()
        {

            let license = {}
            if( this.$props.element.lpname === null )
            {

                license.name = this.$props.element.lname
                license.poolMaster = null
                license.start = this.$props.element.license_start
                license.end = this.$props.element.license_end
                license.days = this.$core.getReformatter().formatLicenseDays( this.$props.element.license_tsmp_end )

            }
            else
            {
                license.name = this.$props.element.lpname
                license.poolMaster = this.$props.element.id_pool_user === this.$props.element.id
                license.start = license.poolMaster ? this.$props.element.license_start : null
                license.end = license.poolMaster ? this.$props.element.license_end : null
                license.days = license.poolMaster ? this.$core.getReformatter().formatLicenseDays( this.$props.element.license_tsmp_end ) : null
            }

            if( license.poolMaster === true )
            {

                this.$core.getClient()
                    .request( {
                        method: 'admin.licenses_listPoolUsers',
                        userId: this.$props.element.id
                    } )
                    .then( response =>
                    {
                        this.poolUsers = response.result
                        this.poolUserCount = ', ' + this.poolUsers.length + ' Poolnutzer'
                    } )

            }

            this.license = license
            this.ready = true

        },

        getLicenseUpgrades()
        {

            this.$core.getClient()
                .request( {
                    method: 'admin.licenses_listBookedUpgrades',
                    userId: this.$props.element.id
                } )
                .then( response =>
                {
                    if( Array.isArray( response.result )
                        && 0 < response.result.length )
                    {
                        this.bookedOptions = response.result
                        this.licenseOptions = ', ' + this.bookedOptions.length + ' Erweiterung' + ( 1 < this.bookedOptions.length ? 'en' : '' )
                    }
                } )

        },

        handleRefresh()
        {
            this.$emit( 'refresh' )
            setTimeout( () =>
            {

                this.$emit( 'localRefresh' )

            }, 500 )
        },

        cancel()
        {
            let newLicense = -1 < this.license.name.indexOf( 'Basis' ) ? 11 : 10
            this.$core.getClient()
                .request( {
                    method    : 'admin.licenses_cancel',
                    id_user   : this.$props.element.id,
                    newLicense: newLicense
                } )
                .then( () =>
                {

                    this.$nextTick()
                        .then( () =>
                        {

                            this.handleRefresh()
                            setTimeout( () =>
                            {
                                this.getLicense()
                            }, 500 )

                        } )

                } )


        }

    }

}
</script>