import { v4 } from "uuid"

export default class UUID {

    /**
     * class constructor
     */
    constructor()
    {
        /** nothing to init **/
    }

    generate()
    {

        return v4()

    }

    validate( uuid )
    {

        let expression = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
        let result     = expression.exec( uuid )

        return null !== result

    }

}