<template>
    <div>
        <div class="width-70">
            <select class="select-css inset" :id="id">
                <option value="">bitte wählen...</option>
                <option v-for="item in copy" :key="'opt-'+item.id" :value="item.id" :selected="item.id === value">
                    {{ item.name }}
                </option>
            </select>
        </div>
        <div class="width-30">
            <input type="text" :id="'filter-'+id" placeholder="Filter" @change="applyFilter"/>
        </div>
    </div>
</template>

<script>
export default {
    
    name : 'LicenseSelector',
    props: [ 'value', 'id' ],
    
    data()
    {
        return {
            ready   : false,
            copy    : [],
            licenses: []
        }
    },
    
    created()
    {
    },
    
    mounted()
    {
        this.listAll()
    },
    
    beforeDestroy()
    {
        this.ready = false
        this.copy = []
        this.licenses = []
    },
    
    methods: {
        
        listAll()
        {
            
            if( this.$store.getters.licensesCache === null )
            {
                let message = {
                    method: 'admin.licenses_listAll'
                }
                this.$core.getClient()
                    .request( message )
                    .then( result =>
                    {
                        
                        let licenseList = []
                        
                        for( let r in result.result )
                        {
                            
                            let license = result.result[ r ]
                            licenseList.push( license )
                            
                        }
                        
                        this.ready = true
                        
                        this.$store.commit( 'setLicensesCache', licenseList )
                        this.licenses = licenseList
                        this.applyFilter()
                        
                    } )
            }
            else
            {
                this.licenses = this.$store.getters.licensesCache
                this.applyFilter()
                this.ready = true
            }
        },
        
        applyFilter()
        {
            
            let filterElm = document.querySelector( '#filter-' + this.$props.id )
            if( null !== filterElm )
            {
                let value = filterElm.value
                if( 3 <= value.length )
                {
                    let newCopy = []
                    for( let l in this.licenses )
                    {
                        if( -1 < this.licenses[ l ].name.toLowerCase().indexOf( value.toLowerCase() ) )
                        {
                            newCopy.push( this.licenses[ l ] )
                        }
                    }
                    this.copy = newCopy
                }
                else
                {
                    this.copy = JSON.parse( JSON.stringify( this.licenses ) )
                }
            }
            
        },
        
        fillTarget( subject, text )
        {
            let sElm = document.querySelector( '#subject' )
            let tElm = document.querySelector( '#text' )
            if( null !== sElm && null !== tElm )
            {
                sElm.value = subject
                tElm.value = text
            }
        }
        
    }
    
}
</script>