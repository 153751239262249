<template>
    <transition appear name="slide-left">
        <div class="fullscreen">
            <UserInfo v-if="userInfo !== false" :element="userInfo" @close="userInfo = false"/>
            <div class="content" v-if="ready">
                <div class="title">
                    <div class="headline"
                         v-html="'Würde <strong>'+element.identifier+'</strong> jetzt verschickt werden: Das wären die Empfänger'"></div>
                    <div class="closer" @click="close()">
                        X
                    </div>
                </div>
                <div class="info padded">
                    <ElementFilter @refresh="handleRefresh()" :noFilterStore="true"
                                   :sub="true"/>
                    <table>
                        <thead>
                        <Thd id="id" caption="#" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortAutomailInfo"
                             @sort="handleSort"></Thd>
                        <Thd id="username" caption="Benutzername" :sortBy="sortBy" :sortDir="sortDir"
                             sortRule="sortAutomailInfo"
                             @sort="handleSort"></Thd>
                        <Thd id="count_elements" caption="# Elemente" :sortBy="sortBy" :sortDir="sortDir"
                             sortRule="sortAutomailInfo"
                             @sort="handleSort"></Thd>
                        <Thd id="datetime_created_formatted" caption="Account erstellt" :sortBy="sortBy" :sortDir="sortDir"
                             sortRule="sortAutomailInfo"
                             @sort="handleSort"></Thd>
                        <Thd id="last_login_formatted" caption="letzter Login" :sortBy="sortBy" :sortDir="sortDir"
                             sortRule="sortAutomailInfo"
                             @sort="handleSort"></Thd>
                        </thead>
                        <tbody class="filterable">
                        <template v-for="item in sent" :key="'row_'+item.id">
                            <tr v-if="undefined !== userFor[ item.id ]" class="clickable">
                                <td>{{ item.id }}</td>
                                <td><strong class="clickable_user" @click="handleUserInfo( $event, item.id )">
                                    {{ item.username }}</strong></td>
                                <td><strong>{{ item.count_elements }}</strong></td>
                                <td>{{ item.datetime_created_formatted }}</td>
                                <td>{{ item.last_login_formatted }}</td>
                            </tr>
                        </template>
                        </tbody>
                        <tfoot>
                        <tr>
                            <th colspan="99" style="text-align:right;" class="filtercount">...</th>
                        </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            <Spinner v-else title="Das kann dauern" message="Informationen werden abgerufen..."/>
        </div>
    </transition>
</template>

<script>
import ElementFilter from '@/components/elements/ElementFilter'
import Thd           from '@/components/elements/Thd'
import Spinner       from '@/components/elements/Spinner'
import UserInfo      from '@/components/elements/fullscreen/UserInfo'

/*eslint-disable*/
export default {

    name      : 'TestAutomailerRecipients',
    components: { UserInfo, Spinner, Thd, ElementFilter },
    props     : [ 'element' ],
    emits     : [ 'refresh', 'close' ],

    data()
    {
        return {
            ready     : false,
            sent     : [],
            users     : [],
            sortBy    : 'id',
            sortDir   : 'descending',
            userFor   : {},
            userInfo  : false,
            totalCount: 0
        }
    },

    created()
    {

        this.init()

    },

    beforeDestroy()
    {
    },

    methods: {

        close()
        {
            this.$emit( 'close' )
        },

        handleUserInfo( event, user )
        {
            event.stopPropagation()
            if( undefined !== user )
            {

                let cache = this.$store.getters.usersCache,
                    found = false

                for( let c in cache )
                {
                    if( cache[ c ].id === user )
                    {
                        this.userInfo = cache[ c ]
                        found = true
                        continue
                    }
                }
                if( !found )
                {
                    this.$core.f().uiMessage( 'error', '<strong>Der Nutzeraccount scheint nicht (mehr) zu existieren</strong><br/>Entweder handelt es sich um einen sehr kaputten Testaccount oder der Account wurde gelöscht.' )
                }
            }
        },

        init( force )
        {

            this.$core.getCacher()
                .fillUsersCache( force )
                .then( userlist =>
                {

                    for( let u in userlist )
                    {
                        this.userFor[userlist[u].id] = userlist[u]
                    }

                    this.users = userlist
                    let message = {
                        method: 'admin.tools_listAutomailsRecipientsTest',
                        idMail: this.$props.element.id
                    }
                    this.$core.getClient()
                        .request( message )
                        .then( response =>
                        {

                            console.log( response.query )

                            this.sent = response.result
                            this.sort()
                            this.ready = true

                        } )

                } )

        },

        sort()
        {
            this.sent = this.$core.getSorter().sortObjects( this.sent, this.sortBy, this.sortDir )
        },

        handleSort( args )
        {

            let newDir = args.sortDir === 'ascending' ? 'descending' : 'ascending'
            this.sortBy = args.id
            this.sortDir = newDir
            this.sort()

        }

    }
}
</script>