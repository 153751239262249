<template>
    <transition appear name="fade">
        <div id="footer">
            Admin UI | Version {{ $core.getConfig().version }} | &copy; 2019-{{ new Date().getFullYear() }} Hill-Commerce GmbH
        </div>
    </transition>
</template>

<script>
export default {
    name: 'Footer'
}
</script>