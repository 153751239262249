<template>
    <div :class="className+hoverable" :id="id" @mouseover="mouseEnter()" @mouseleave="mouseLeave()">
        <component v-bind:is="component" @ready="setHoverable()"></component>
    </div>
</template>

<script>

import DashUserStats     from '@/components/elements/boxes/DashUserStats'
import DashLicenseStats  from '@/components/elements/boxes/DashLicenseStats'
import DashVersionStats  from '@/components/elements/boxes/DashVersionStats'
import DashLoginStats    from '@/components/elements/boxes/DashLoginStats'
import DashObjectStats   from '@/components/elements/boxes/DashObjectStats'
import DashPlatformStats from '@/components/elements/boxes/DashPlatformStats'
import DashSessionStats  from '@/components/elements/boxes/DashSessionStats'
import DashRequestStats  from '@/components/elements/boxes/DashRequestStats'
import DashRegionalStats from '@/components/elements/boxes/DashRegionalStats'
import DashSettings      from "@/components/elements/boxes/DashSettings";

export default {
    name      : 'StatsBox',
    props     : [ 'component', 'className' ],
    components: {
        DashSettings,
        DashPlatformStats,
        DashObjectStats,
        DashLoginStats,
        DashVersionStats,
        DashLicenseStats,
        DashUserStats,
        DashSessionStats,
        DashRequestStats,
        DashRegionalStats
    },

    data()
    {
        return {
            id       : false,
            timer    : false,
            hoverable: ''
        }
    },

    created()
    {
        this.id = 'statsbox_' + new Date().getTime() + '_' + Math.floor( Math.random( 5 ) * 1000 )
    },

    methods: {

        setHoverable()
        {
            this.hoverable = ' hoverable'
        },

        mouseEnter()
        {

            let elm = document.querySelector( '#' + this.id )
            if( null !== elm
                && false === this.timer )
            {
                this.timer = setTimeout( () =>
                {

                    elm.classList.add( 'hovered' )

                }, 500 )
            }

        },

        mouseLeave()
        {
            clearTimeout( this.timer )
            this.timer = false
            let elm = document.querySelector( '#' + this.id )
            elm.classList.remove( 'hovered' )
        }

    }
}
</script>