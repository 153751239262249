<template>
    <div :class="addClass">
        <div class="style-button informative">
            Seite <strong>{{ page + 1 }}</strong> von <strong>{{ pages }}</strong>
            <span v-if="0 < total">&nbsp;| {{ total }} Elemente</span>
        </div>
        <div :class="'style-button mw'+( page > 0 ? '' : ' informative' )" @click="go( 'back' )">
            &larr;
        </div>
        <div v-for="p in displayedPages" :key="'paginate-'+p"
             :class="'style-button mw '+( -1 === p ? 'informative ' : '' ) + ( p === page ? 'active' : '' )"
             @click="$emit( 'switch', p ) ">
            <template v-if="-1 !== p">
                {{ ( p + 1 ) }}
            </template>
            <template v-else>
                ...
            </template>
        </div>
        <div :class="'style-button mw'+( page < ( pages - 1 ) ? '' : ' informative' )" @click="go( 'for' )">
            &rarr;
        </div>
    </div>
</template>

<script>
export default {

    name: 'Pagination',

    props: {
        pages   : { Type: Number, required: true },
        page    : { Type: Number, required: true },
        total   : { Type: Number, required: false, default: 0 },
        addClass: { Type: String, required: false, default: '' }
    },

    emits: [ 'switch' ],

    computed: {
        displayedPages()
        {

            if( this.$props.pages <= 5 )
            {
                return [ 0, 1, 2, 3, 4 ]
            } else
            {

                let pageArr = [ 0 ]

                let pageStart = this.$props.page - 1,
                    pageEnd   = pageStart + 3,
                    i

                if( pageStart > 1 )
                {
                    pageArr.push( -1 )
                }

                for( i = pageStart; i < pageEnd; i++ )
                {
                    if( i > -1 && -1 === pageArr.indexOf( i ) && i < this.$props.pages )
                    {
                        pageArr.push( i )
                    }
                }

                if( i < this.$props.pages - 1 )
                {
                    pageArr.push( -1 )
                }
                if( i < this.$props.pages )
                {
                    pageArr.push( ( this.$props.pages - 1 ) )
                }
                return pageArr
            }

        }
    },

    methods: {
        go( dir )
        {
            if( dir === 'for' && this.$props.page < ( this.$props.pages - 1 ) )
            {
                this.$emit( 'switch', ( this.$props.page + 1 ) )
            }
            if( dir === 'back' && this.$props.page > 0 )
            {
                this.$emit( 'switch', ( this.$props.page - 1 ) )
            }

        }
    }

}
</script>