<template>
    <div v-if="ready">
        <ElementFilter @refresh="handleRefresh()" id="server" :showAdder="true" @add="add()"/>
        <div class="spacer"></div>
        <Form v-if="editor"
              :formValues="formValues"
              @close="close()"
              @submit="storeValue"/>
        
        <div class="form-left"><strong>Plattform wählen</strong>:</div>
        <div class="form-right"><select name="select-platform" class="select-css" id="platform"
                                        @change="switchPlatform()">
            <option value="all" :selected="platform === 'all'">Alle Plattformen</option>
            <option value="dev" :selected="platform === 'dev'">DEV</option>
            <option value="beta" :selected="platform === 'beta'">BETA</option>
            <option value="live" :selected="platform === 'live'">LIVE</option>
        </select></div>
        <div class="spacer"></div>
        <table>
            <thead>
            <tr>
                <Thd id="prov_key" caption="Key" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortTemplates"
                     @sort="handleSort"/>
                <Thd id="prov_value" caption="Value" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortTemplates"
                     @sort="handleSort"/>
                <th>Aktionen</th>
            </tr>
            </thead>
            <tbody class="filterable">
            <tr v-for="( row, index ) in data" :key="'row_'+index" class="clickable" @click="edit(row)">
                <td><strong>{{ row.prov_key }}</strong></td>
                <td><strong>{{ row.prov_value.substr( 0, 64 ) + '...' }}</strong></td>
                <td>
                    <div class="button cancel">X</div>
                </td>
            </tr>
            </tbody>
            <tfoot>
            <tr :id="viewId">
                <th colspan="99" style="text-align:right;" class="filtercount">...</th>
            </tr>
            </tfoot>
        </table>
    </div>
    <Spinner v-else title="Gleich geht's weiter" message="Server-Variablen werden geprüft..."/>
</template>

<script>
import Thd            from '../../../elements/Thd'
import ElementFilter  from '../../../elements/ElementFilter'
import Form           from '../../../elements/fullscreen/Form'
import Spinner        from '../../../elements/Spinner'
import MixinViewReady from '@/mixins/MixinViewReady'

export default {
    
    name      : "ToolsServer",
    components: { Spinner, Form, ElementFilter, Thd },
    mixins    : [ MixinViewReady ],
    
    data()
    {
        return {
            ready     : false,
            data      : [],
            formValues: false,
            editor    : false,
            sortBy    : 'prov_key',
            sortDir   : 'ascending',
            platform  : 'dev'
        }
    },
    
    created()
    {
        this.refresh()
    },
    
    methods: {
        
        refresh()
        {
            this.ready = false
            this.$core.getClient()
                .request( {
                    method  : 'admin.tools_getProvisioning',
                    platform: this.platform
                } )
                .then( response =>
                {
                    this.data = response.result
                    this.sort()
                    this.ready = true
                } )
        },
        
        add()
        {
            
            this.formValues = {
                title         : '<strong>Variable</strong> setzen',
                subjectCaption: 'speichern',
                fields        : [
                    {
                        caption: 'Plattform',
                        type   : 'TextBox',
                        id     : 'platform',
                        value  : this.platform
                    },
                    {
                        caption: 'Key',
                        type   : 'TextBox',
                        id     : 'prov_key',
                        value  : ''
                    },
                    {
                        caption: 'Value',
                        type   : 'TextBox',
                        id     : 'prov_value',
                        value  : ''
                    }
                ]
            }
            
            this.editor = true
            
        },
        
        handleSort( args )
        {
            
            let newDir = args.sortDir === 'ascending' ? 'descending' : 'ascending'
            this.sortBy = args.id
            this.sortDir = newDir
            this.sort()
            
        },
        
        close()
        {
            this.editor = false
            this.formValues = null
        },
        
        sort()
        {
            this.data = this.$core.getSorter().sortObjects( this.data, this.sortBy, this.sortDir )
            this.ready = true
        },
        
        storeValue( values )
        {
            if( '' !== values.platform.trim() && '' !== values.prov_key.trim() && '' !== values.prov_value.trim() )
            {
                
                let config = {
                    platform  : values.platform.trim(),
                    prov_key  : values.prov_key.trim(),
                    prov_value: this.$core.getCrypto().serverCrypt( values.prov_value.trim() )
                }
                
                this.$core.getClient()
                    .request( {
                        method      : 'admin.tools_setProvisioning',
                        provisioning: config
                    } )
                    .then( () =>
                    {
                        this.close()
                        this.refresh()
                    } )
                
            }
        },
        
        switchPlatform()
        {
            
            let elm = document.querySelector( '#platform' )
            if( null !== elm )
            {
                this.platform = elm.value
                this.refresh()
            }
        }
        
    }
}
</script>