/*
 * VueX store implementation
 * part of the yotter-project / entzettelt.de
 * --
 * (c) 2019 Hill-Commerce GmbH
 *     All rights reserved.
 * --
 * Author:  manuel.pohl@hill-commerce.de
 */

import { createStore } from 'vuex'

/**
 * use IndexDB to persist state changes
 */

export const store = createStore( {

        state()
        {
            return {

                online             : false,
                authorized         : null,
                idSession          : null,
                idUser             : null,
                email              : null,
                username           : null,
                firstname          : null,
                lastname           : null,
                idRole             : null,
                usersCache         : null,
                adminCache         : null,
                licensesCache      : null,
                licensePoolsCache  : null,
                invoicesCache      : null,
                sortUsers          : null,
                sortUsersDir       : null,
                sortLicenses       : null,
                sortLicensesDir    : null,
                sortMails          : null,
                sortMailsDir       : null,
                sortAdmins         : null,
                sortAdminsDir      : null,
                sortLicensePools   : null,
                sortLicensePoolsDir: null,
                sortInvoices       : null,
                sortInvoicesDir    : null,
                sortTemplates      : null,
                sortTemplatesDir   : null,
                sortDeletions      : null,
                sortDeletionsDir   : null,
                sortNewsletter     : null,
                sortNewsletterDir  : null,
                sortBounces        : null,
                sortBouncesDir     : null,
                sortVouchers       : null,
                sortVouchersDir    : null,
                filters            : null
            }
        },

        mutations: {

            injectEventManager( state, value )
            {
                this.eventManager = value
            },

            setOnline( state, value )
            {
                state.online = value
            },

            setEmail( state, value )
            {
                state.email = value
            },

            setAuthorized( state, value )
            {
                state.authorized = value
                window.authKey = value === 1 ? 'auth' : 'unauth'
                if( this.eventManager )
                {
                    this.eventManager.dispatch( 'on-store-authstate-change', value )
                }
            },

            setIdSession( state, value )
            {
                state.idSession = value
            },

            setIdUser( state, value )
            {
                state.idUser = value
            },

            setUsername( state, value )
            {
                state.username = value
            },

            setFirstname( state, value )
            {
                state.firstname = value
            },

            setLastname( state, value )
            {
                state.lastname = value
            },

            setIdRole( state, value )
            {
                state.idRole = value
            },

            setUsersCache( state, value )
            {
                state.usersCache = value
            },

            setAdminCache( state, value )
            {
                state.adminCache = value
            },

            setLicensesCache( state, value )
            {
                state.licensesCache = value
            },

            setLicensePoolsCache( state, value )
            {
                state.licensePoolsCache = value
            },

            setInvoicesCache( state, value )
            {
                state.invoicesCache = value
            },

            setSortUsers( state, value )
            {
                state.sortUsers = value
            },

            setSortUsersDir( state, value )
            {
                state.sortUsersDir = value
            },

            setSortLicenses( state, value )
            {
                state.sortLicenses = value
            },

            setSortLicensesDir( state, value )
            {
                state.sortLicensesDir = value
            },

            setSortMails( state, value )
            {
                state.sortMails = value
            },

            setSortMailsDir( state, value )
            {
                state.sortMailsDir = value
            },
            setSortAdmins( state, value )
            {
                state.sortAdmins = value
            },
            setSortAdminsDir( state, value )
            {
                state.sortAdminsDir = value
            },
            setSortLicensePools( state, value )
            {
                state.sortLicensePools = value
            },
            setSortLicensePoolsDir( state, value )
            {
                state.sortLicensePoolsDir = value
            },
            setSortInvoices( state, value )
            {
                state.sortInvoices = value
            },
            setSortInvoicesDir( state, value )
            {
                state.sortInvoicesDir = value
            },
            setSortTemplates( state, value )
            {
                state.sortTemplates = value
            },
            setSortTemplatesDir( state, value )
            {
                state.sortTemplatesDir = value
            },
            setSortDeletions( state, value )
            {
                state.sortDeletions = value
            },
            setSortDeletionsDir( state, value )
            {
                state.sortDeletionsDir = value
            },
            setSortNewsletter( state, value )
            {
                state.sortNewsletter = value
            },
            setSortNewsletterDir( state, value )
            {
                state.sortNewsletterDir = value
            },
            setSortBounces( state, value )
            {
                state.sortBounces = value
            },
            setSortBouncesDir( state, value )
            {
                state.sortBouncesDir = value
            },
            setSortVouchers( state, value )
            {
                state.sortVouchers = value
            },
            setSortVouchersDir( state, value )
            {
                state.sortVouchersDir = value
            },
            setFilters( state, value )
            {
                state.filters = value
            }
        },

        actions: {
            destroySession()
            {

                try {
                    throw new Error( 'foo' )
                }
                catch( e )
                {
                    console.log( 'DESTROY', e )
                }

                this.commit( 'setAuthorized', null )
                this.commit( 'setIdSession', null )
                this.commit( 'setUsername', null )
                this.commit( 'setFirstname', null )
                this.commit( 'setLastname', null )
                this.commit( 'setIdRole', null )
                this.commit( 'setOnline', null )
                this.commit( 'setEmail', null )
                this.commit( 'setIdUser', null )
                this.commit( 'setUsersCache', null )
                this.commit( 'setAdminCache', null )
                this.commit( 'setLicensesCache', null )
                this.commit( 'setLicensePoolsCache', null )
                this.commit( 'setInvoicesCache', null )
                this.commit( 'setSortUsers', null )
                this.commit( 'setSortUsersDir', null )
                this.commit( 'setSortLicenses', null )
                this.commit( 'setSortLicensesDir', null )
                this.commit( 'setSortMails', null )
                this.commit( 'setSortMailsDir', null )
                this.commit( 'setSortAdmins', null )
                this.commit( 'setSortAdminsDir', null )
                this.commit( 'setSortLicensePools', null )
                this.commit( 'setSortLicensePoolsDir', null )
                this.commit( 'setSortInvoices', null )
                this.commit( 'setSortInvoicesDir', null )
                this.commit( 'setSortTemplates', null )
                this.commit( 'setSortTemplatesDir', null )
                this.commit( 'setSortDeletions', null )
                this.commit( 'setSortDeletionsDir', null )
                this.commit( 'setSortNewsletter', null )
                this.commit( 'setSortNewsletterDir', null )
                this.commit( 'setSortBounces', null )
                this.commit( 'setSortBouncesDir', null )
                this.commit( 'setSortVouchers', null )
                this.commit( 'setSortVouchersDir', null )

                this.commit( 'setFilters', null )

                document.cookie = null
            }

        },

        getters: {

            online             : state => state.online,
            authorized         : state => state.authorized,
            idSession          : state => state.idSession,
            idUser             : state => state.idUser,
            username           : state => state.username,
            email              : state => state.email,
            firstname          : state => state.firstname,
            lastname           : state => state.lastname,
            idRole             : state => state.idRole,
            usersCache         : state => state.usersCache,
            adminCache         : state => state.adminCache,
            licensesCache      : state => state.licensesCache,
            licensePoolsCache  : state => state.licensePoolsCache,
            invoicesCache      : state => state.invoicesCache,
            sortUsers          : state => state.sortUsers,
            sortUsersDir       : state => state.sortUsersDir,
            sortLicenses       : state => state.sortLicenses,
            sortLicensesDir    : state => state.sortLicensesDir,
            sortMails          : state => state.sortMails,
            sortMailsDir       : state => state.sortMailsDir,
            sortAdmins         : state => state.sortAdmins,
            sortAdminsDir      : state => state.sortAdminsDir,
            sortLicensePools   : state => state.sortLicensePools,
            sortLicensePoolsDir: state => state.sortLicensePoolsDir,
            sortInvoices       : state => state.sortInvoices,
            sortInvoicesDir    : state => state.sortInvoicesDir,
            sortTemplates      : state => state.sortTemplates,
            sortTemplatesDir   : state => state.sortTemplatesDir,
            sortDeletions      : state => state.sortDeletions,
            sortDeletionsDir   : state => state.sortDeletionsDir,
            sortNewsletter     : state => state.sortNewsletter,
            sortNewsletterDir  : state => state.sortNewsletterDir,
            sortBounces        : state => state.sortBounces,
            sortBouncesDir     : state => state.sortBouncesDir,
            sortVouchers       : state => state.sortVouchers,
            sortVouchersDir    : state => state.sortVouchersDir,
            filters            : state => state.filters

        }

    }
)
