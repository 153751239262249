import Core from '@/classes/core/Core'

const CorePlugin = {

    install( app, store, router ) {

        let _Core = new Core( store, router )
        app.config.globalProperties.$core = _Core

    }
};

export default CorePlugin;