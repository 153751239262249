const nacl = require( 'tweetnacl' )
const util = require( 'tweetnacl-util' )

export default class Crypto
{
    
    constructor( config )
    {
        this.messageKeyLength = 16
        this.paddingString = 'PaDdInG'
        this.paddingLength = 32
        this.randomCharacters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
        this.serverKey = config.serverPublicKey
    }
    
    encryptWithPublicKey( publicKey, text )
    {
        
        const localKey = nacl.box.keyPair()
        const pubKeyUInt8Array = util.decodeBase64( publicKey )
        const msgParamsUInt8Array = util.decodeUTF8( text )
        const nonce = nacl.randomBytes( nacl.box.nonceLength )
        
        const encryptedMessage = nacl.box(
            msgParamsUInt8Array,
            nonce,
            pubKeyUInt8Array,
            localKey.secretKey
        )
        
        let encryptedResult = {
            ciphertext : util.encodeBase64( encryptedMessage ),
            ephemPubKey: util.encodeBase64( localKey.publicKey ),
            nonce      : util.encodeBase64( nonce ),
            version    : 'x25519-xsalsa20-poly1305'
        }
        
        return btoa( JSON.stringify( encryptedResult ) )
        
    }
    
    serverCrypt( value )
    {
        return this.encryptWithPublicKey( this.serverKey, value )
    }
    
}