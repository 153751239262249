<template>
    <transition appear name="slide-left">
        <div class="fullscreen">
            <UserInfo v-if="userInfo !== false" :element="userInfo" @close="userInfo = false" />
            <div class="content" v-if="ready">
                <div class="title">
                    <div class="headline"
                         v-html="'Gutscheinnutzungsdetails für <strong>'+element.name+'</strong>'"></div>
                    <div class="closer" @click="close()">
                        X
                    </div>
                </div>
                <div class="info padded">
                    <ElementFilter @refresh="handleRefresh()" :noFilterStore="true"
                                   :sub="true"/>
                    <table>
                        <thead>
                        <Thd id="id" caption="#" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortVoucherUsage"
                             @sort="handleSort"></Thd>
                        <Thd id="username" caption="Benutzername" :sortBy="sortBy" :sortDir="sortDir"
                             sortRule="sortVoucherUsage"
                             @sort="handleSort"></Thd>
                        <Thd id="datetime_used" caption="eingelöst" :sortBy="sortBy" :sortDir="sortDir"
                             sortRule="sortVoucherUsage"
                             @sort="handleSort"></Thd>
                        </thead>
                        <tbody class="filterable">
                        <tr v-for="use in usage" :key="'row_'+use.id" class="clickable">
                            <td>{{ use.id_user }}</td>
                            <td><strong :class="undefined !== userFor[ use.id_user ] ? 'clickable_user' : ''"
                                        @click="userInfo = userFor[ use.id_user ]">{{
                                    use.username
                                }}</strong></td>
                            <td><strong>{{ use.datetime_used_formatted }}</strong></td>
                        </tr>
                        </tbody>
                        <tfoot>
                        <tr>
                            <th colspan="99" style="text-align:right;" class="filtercount">...</th>
                        </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            <Spinner v-else title="Das kann dauern" message="Informationen werden abgerufen..."/>
        </div>
    </transition>
</template>

<script>
import ElementFilter from '@/components/elements/ElementFilter'
import Thd           from '@/components/elements/Thd'
import Spinner       from '@/components/elements/Spinner'
import UserInfo      from '@/components/elements/fullscreen/UserInfo'

/*eslint-disable*/
export default {
    
    name      : 'VoucherUsage',
    components: { UserInfo, Spinner, Thd, ElementFilter },
    props     : [ 'element' ],
    emits     : [ 'refresh' ],
    
    data()
    {
        return {
            ready     : false,
            usage     : [],
            users     : [],
            sortBy    : 'id',
            sortDir   : 'ascending',
            userFor   : {},
            userInfo  : false,
            totalCount: 0
        }
    },
    
    created()
    {
        
        this.init()
        
    },
    
    beforeDestroy()
    {
    },
    
    methods: {
        
        close()
        {
            this.$emit( 'close' )
        },
        
        init( force )
        {
            
            this.$core.getCacher()
                .fillUsersCache( force )
                .then( userlist =>
                {
                    
                    this.users = userlist
                    let message = {
                        method  : 'admin.licenses_listCouponUsage',
                        idCoupon: this.$props.element.id
                    }
                    this.$core.getClient()
                        .request( message )
                        .then( result =>
                        {
                            let list = result.result
                            this.usage = []
                            for( let l in list )
                            {

                                list[l].id = 'usage_'+l
                                for( let u in this.users )
                                {
                                    if( this.users[ u ].id === list[ l ].id_user )
                                    {
                                        this.userFor[ list[ l ].id_user ] = this.users[ u ]
                                    }
                                }
                                
                                this.usage.push( list[ l ] )
                            }
                            this.ready = true
                        } )
                    
                } )
            
        },
    
        sort()
        {
            this.usage = this.$core.getSorter().sortObjects( this.usage, this.sortBy, this.sortDir )
        },
    
        handleSort( args )
        {
        
            let newDir = args.sortDir === 'ascending' ? 'descending' : 'ascending'
            this.sortBy = args.id
            this.sortDir = newDir
            this.sort()
        
        }
    
    }
}
</script>