<template>
    <div class="infobox" v-if="ready">
        <Opener id="notes" :state="open" inline=true
                :caption="'Notizen'+( !open ? '<span style=\'font-weight:normal\'>: '+notes.length+'</span>' : '' )"
                @clicked="open = !open"/>
        <template v-if="open">
            <div class="spacer"></div>
            <div class="button right atopener" @click="formVisible = !formVisible">+</div>
            <Form v-if="formVisible"
                  :formValues="formValues"
                  @close="formVisible = false"
                  @submit="handleSubmit"/>
            <template v-for="( note, index ) in notes"
                      :key="'note-'+index">
                <UserNote :note="note"/>
            </template>
            <template v-if="notes.length === 0">
                <p class="empty-notes">
                    Keine Notizen vorhanden
                </p>
            </template>
        </template>
    </div>
</template>

<script>
import Opener   from '@/components/elements/Opener'
import UserNote from "@/components/elements/boxes/UserNote";
import Form     from "@/components/elements/fullscreen/Form";

export default {

    name      : 'UserNotes',
    components: { Form, UserNote, Opener },
    props     : [ 'element' ],

    data()
    {
        return {
            notes      : false,
            open       : true,
            ready      : false,
            formVisible: false,
            formValues : {}
        }
    },

    mounted()
    {

        this.init()

    },

    methods: {

        init()
        {
            let message = {
                method : 'admin.users_listUserNotes',
                id_user: this.$props.element.id
            }

            this.$core.getClient()
                .request( message )
                .then( response =>
                {

                    let list = []
                    for( let r in response.result )
                    {
                        let set = response.result[ r ]
                        set.text = atob( set.text )
                        list.push( set )
                    }
                    this.notes = list
                    this.ready = true

                } )
                .catch( () =>
                {
                    this.notes = []
                    this.ready = true
                } )

            this.formValues = {
                title         : '<strong>Neue Notiz</strong>',
                subjectCaption: 'speichern',
                fields        : [
                    {
                        caption: 'Notiz',
                        type   : 'TextArea',
                        id     : 'body',
                        value  : ''
                    },
                ]
            }
        },

        handleSubmit( values )
        {

            if( '' !== values.body.trim() )
            {

                let message = {
                    method  : 'admin.users_storeUserNote',
                    id_user : this.$props.element.id,
                    body    : this.$core.getCrypto().serverCrypt( btoa( values.body ) )
                }

                this.$core.getClient()
                    .request( message )
                    .then( () => {

                        this.reset()

                    })

            }
            this.formVisible = false

        },

        reset()
        {
            this.notes = []
            this.ready = false
            this.init()
        }

    }


}
</script>