<template>
    <transition appear name="fade">
        <div id="dash-settings">
            <h2>Persönliche Einstellungen</h2>
            <p>Welche Einstellungen nutzen unsere Nutzer:innen?</p>
            <DisplayDummyBars id="dash-settings" :height="300"/>
            <!--            <DisplayRegionalStats id="dash-regional" :height=300 v-if="isready" :data="data"/>
                        <DisplayDummyDougnut id="dash-regional" :height=300 v-else/>-->
        </div>
    </transition>
</template>

<script>


import DisplayDummyBars from "@/components/elements/boxes/stats/DisplayDummyBars";

export default {
    name      : 'DashSettings',
    components: { DisplayDummyBars },
    emits     : [ 'ready' ],

    data()
    {
        return {
            isready: false,
            data   : false,
            total  : false
        }
    },
    /*eslint-disable*/
    mounted()
    {

        let message = {
            method: 'admin.stats_getAllSettings'
        }

        this.$core.getClient()
            .request( message )
            .then( response =>
            {

                console.log( response )

                let data  = response.result,
                    stats = {},
                    wanted = [
                        'animations',
                        'alwaysOn',
                        'archiveCachingDisabled',
                        'backgroundStyle',
                        'calendarDefaultMode',
                        'colorCount',
                        'combineShares',
                        'darkerColorMode',
                        'multiFilter',
                        'ratingSmiles',
                        'ratingStarts',
                        'selectedTheme',
                        'textAreaWidth',
                        'showSyncState'
                    ]

                for( let d in data )
                {

                    let settings = JSON.parse( data[ d ].settings )
                    for( let s in settings )
                    {

                        if( -1 < wanted.indexOf( s ) )
                        {

                            if( undefined === stats[ s ] )
                            {
                                stats[ s ] = {}
                            }
                            if( undefined === stats[ s ][ settings[ s ] ] )
                            {
                                stats[ s ][ settings[ s ] ] = 0
                            }
                            stats[ s ][ settings[ s ] ]++

                        }

                    }
                }

                console.log( stats )

            } )
            .catch( () =>
            {
            } )
    },

    methods: {
        count( stats, key )
        {
            if( undefined === stats[ key ] )
            {
                stats[ key ] = 0
            }
            stats[ key ]++
        }
    }

}
</script>