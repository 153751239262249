<template>
    <div class="infobox" v-if="ready">
        <Opener id="platformflags" :state="open"
                inline=true
                :caption="'Plattform-Berechtigungen'+( !open ? '<span style=\'font-weight:normal\'>: '+flagsInfo+'</span>' : '' )"
                @clicked="open = !open"/>
        <template v-if="open">
            <table class="margin-top infotable" v-if="undefined !== flags">
                <tr>
                    <td class="center strong">Status</td>
                    <td class="center">
                        <input type="button" :class="'third '+( flags.flag_dev_allowed === 1 ? 'ok' : 'error' )"
                               value="DEV"
                               @click="setPlatformFlag( 'dev' )"/>
                        <input type="button" :class="'third '+( flags.flag_beta_allowed === 1 ? 'ok' : 'error' )"
                               value="BETA" @click="setPlatformFlag( 'beta' )"/>
                        <input type="button" :class="'third '+( flags.flag_live_allowed === 1 ? 'ok' : 'error' )"
                               value="LIVE" @click="setPlatformFlag( 'live' )"/>
                    </td>
                </tr>
            </table>
            <table class="margin-top infotable" v-else>
                <tr>
                    <td class="center strong">&nbsp;</td>
                    <td class="center"><h3 class="error">keine Informationen verfügbar</h3></td>
                </tr>
            </table>
        </template>
    </div>
</template>

<script>

import Opener from '@/components/elements/Opener'
export default {

    name : 'UserPlatformFlags',
    components: { Opener },
    props: [ 'element' ],

    data()
    {
        return {
            flags    : {},
            flagsInfo: '',
            ready    : false,
            open     : false
        }
    },

    mounted()
    {

        this.getMeta()

    },

    methods: {

        getMeta()
        {
            let message = {
                method : 'admin.users_getUserPlatformFlags',
                id_user: this.$props.element.id
            }

            this.$core.getClient()
                .request( message )
                .then( result =>
                {

                    let todo = [ 'dev', 'beta', 'live' ]
                    let flagList = []
                    for( let t in todo )
                    {
                        this.flags['flag_'+todo[t]+'_allowed'] = result.result['flag_'+todo[t]+'_allowed']
                        if( result.result['flag_'+todo[t]+'_allowed'] )
                        {
                            flagList.push( todo[t] )
                        }
                    }

                    this.flagsInfo = flagList.join( ', ' )

                    this.ready = true

                } )
                .catch( () =>
                {
                    this.ready = true
                } )
        },

        setPlatformFlag( platform )
        {

            this.$core.getEventManager().dispatch( 'showBlocker' )
            let newState = this.flags[ 'flag_' + platform + '_allowed' ] === 1 ? 0 : 1
            let message = {
                method : 'admin.users_setUserPlatformFlag',
                id_user: this.$props.element.id,
                flag   : 'flag_' + platform + '_allowed',
                value  : newState
            }

            this.$core.getClient()
                .request( message )
                .then( () =>
                {

                    this.ready = false
                    this.flags = []
                    setTimeout( () =>
                    {
                        this.getMeta()
                        this.$core.getEventManager().dispatch( 'hideBlocker' )
                    }, 1000 )

                } )

        }

    }

}
</script>