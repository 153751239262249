<template>
    <div></div>
</template>
<script>

export default {

    name: 'Sesame',
    mounted()
    {

        this.tryIt()

    },

    methods: {

        tryIt()
        {

            let message = {
                method: 'admin.createWhitelist'
            }

            this.$core.getClient()
                .request( message )
                .then( () =>
                {

                    this.$router.push( { name: 'login' } )
                    setTimeout( () => {
                        location.reload()
                    }, 500 )

                } )
                .catch( e =>
                {
                    console.log( 'NOPE:', e )
                } )

        }

    }

}
</script>