<template>
    <transition appear name="fade">
        <div class="infobox">
            <Opener id="loginStats" :state="open" inline=true
                    :caption="'Login-Statistiken'+( !open ? '<span style=\'font-weight:normal\'>: '+ total +'</span>' : '' )"
                    @clicked="open= !open"/>
            <div v-if="open && ready">
                <template v-if="nothing">
                    <div class="box-nothing-indicator">
                        Es gibt noch keine Daten, die hier angezeigt werden könnten.
                    </div>
                </template>
                <template v-else>
                    <DisplayUserLoginStats :data="dataSet"/>
                </template>
            </div>
        </div>
    </transition>
</template>

<script>
import DisplaySessionStats   from '@/components/elements/boxes/stats/DisplaySessionStats'
import Opener                from '@/components/elements/Opener'
import DisplayUserLoginStats from "@/components/elements/boxes/stats/DisplayUserLoginStats";

export default {
    name      : 'UserLoginStats',
    components: { DisplayUserLoginStats, Opener },

    props: [ 'element' ],

    data()
    {
        return {
            ready  : false,
            dataSet: false,
            open   : false,
            total  : 0,
            nothing: true
        }
    },

    mounted()
    {
        let message = {
            method      : 'admin.stats_getLoginStatisticsHistory',
            id_user     : this.$props.element.id,
            count_months: 12
        }

        this.$core.getClient()
            .request( message )
            .then( result =>
            {

                let dataSet = result.result,
                    labels  = [],
                    set     = [],
                    total   = 0

                for( let d in dataSet )
                {
                    if( Array.isArray( dataSet[ d ] ) )
                    {
                        this.dataSet = dataSet[ d ]
                        let temp = this.$core.getSorter().sortObjects( dataSet[ d ], 'month', 'ascending' )
                        for( let t in temp )
                        {
                            labels.push( temp[ t ].month )
                            set.push( temp[ t ].counter )
                            if( 0 < parseInt( temp[ t ].counter ) )
                            {
                                total += parseInt( temp[ t ].counter )
                                this.nothing = false
                            }
                        }
                    }
                }

                this.dataSet = {
                    labels: labels,
                    set   : set
                }
                this.total = total
                this.ready = true

            } )
            .catch( () =>
            {
            } )
    }

}
</script>