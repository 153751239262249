<template>
    <div class="infobox">
        <h2>Funktionen</h2>
        <table class="margin-top infotable">
            <template v-if="$core.r().isAllowed( 'editLicense' )">
                <tr>
                    <td class="center strong">Lizenz wechseln</td>
                    <td class="center">
                        <select name="prolong-license" class="select-css" id="licensechange"
                                @change="checkLicenseChange()">
                            <option value="">bitte wählen...</option>
                            <option v-for="option in licenseOptions"
                                    :key="'lic-'+option.id"
                                    :value="option.id">{{ option.name }}
                            </option>
                        </select>
                        <div v-if="licenseChangeAvailable" class="button nonfixed right set top-margin"
                             @click="changeLicense()">Wechsel durchführen
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="center strong">Lizenz verlängern</td>
                    <td class="center">
                        <select name="prolong-license" class="select-css" id="prolong" @change="checkProlong()">
                            <option value="">bitte wählen...</option>
                            <optgroup label="Lizenz-Standard">
                                <option value="default">um {{ translateLicenseUsage( element.lusage ) }}</option>
                            </optgroup>
                            <optgroup label="Variabel">
                                <option v-for="option in prolongOptions"
                                        :key="'opt-'+option"
                                        :value="option">um {{ translateLicenseUsage( option ) }}
                                </option>
                            </optgroup>
                        </select>
                        <div v-if="prolongAvailable" class="button nonfixed right set top-margin"
                             @click="prolongLicense()">Verlängerung durchführen
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="center strong">Lizenz verkürzen</td>
                    <td class="center">
                        <select name="prolong-license" class="select-css" id="shorten"
                                @change="checkProlong( 'shorten' )">
                            <option value="">bitte wählen...</option>
                            <optgroup label="Lizenz-Standard">
                                <option value="default">um {{ translateLicenseUsage( element.lusage ) }}</option>
                            </optgroup>
                            <optgroup label="Variabel">
                                <option v-for="option in prolongOptions"
                                        :key="'opt-'+option"
                                        :value="option">um {{ translateLicenseUsage( option ) }}
                                </option>
                            </optgroup>
                        </select>
                        <div class="button nonfixed delete right set top-margin"
                             @click="invalidateLicense()">Lizenz sofort invalidieren
                        </div>
                        <div v-if="shortenAvailable" class="button nonfixed right set top-margin"
                             @click="shortenLicense()">Verkürzung durchführen
                        </div>
                    </td>
                </tr>
            </template>
            <tr>
                <td class="center strong">PUK</td>
                <td class="right">
                    <div v-if="isPUK === true" class="button nonfixed right delete"
                         @click="removePUK()">löschen
                    </div>
                    <span v-if="isPUK === true" class="good"
                          style="margin-right: 10px; line-height: 30px;">
                        <strong>vorhanden</strong>
                    </span>
                    <span v-if="isPUK !== true" class="error"
                          style="margin-right: 10px; line-height: 30px;">
                        <strong>nicht vorhanden</strong>
                    </span>
                </td>
            </tr>
            <tr>
                <td class="center strong">2-Faktor-Authentifizierung</td>
                <td class="right">
                    <div v-if="is2FA === true" class="button nonfixed right delete"
                         @click="remove2FA()">deaktivieren
                    </div>
                    <span v-if="is2FA === true" class="good"
                          style="margin-right: 10px; line-height: 30px;">
                        <strong>aktiv</strong>
                    </span>
                    <span v-if="is2FA !== true" class="error"
                          style="margin-right: 10px; line-height: 30px;">
                        <strong>inaktiv</strong>
                    </span>
                </td>
            </tr>
            <template v-if="$core.r().isAllowed( 'supportMirror' )">
                <tr>
                    <td class="center strong">Support-Mirror</td>
                    <td class="right">
                        <div v-if="isSupportMirror === false" class="button nonfixed right set"
                             @click="setFlag( 'support_mirror', 1)">aktivieren
                        </div>
                        <div v-if="isSupportMirror === true" class="button nonfixed right delete"
                             @click="setFlag( 'support_mirror', 0)">deaktivieren
                        </div>
                        <span v-if="isSupportMirror === true" class="good"
                              style="margin-right: 10px; line-height: 30px;">
                        <strong>aktiv</strong>
                    </span>
                    </td>
                </tr>
            </template>
            <template v-if="$core.r().isAllowed( 'setTestuserSupplier' )">
                <tr>
                    <td class="center strong">Quelle für Testaccount</td>
                    <td class="right">
                        <div v-if="isTestuserSupplier === false" class="button nonfixed right set"
                             @click="setFlag( 'testuser_supplier', 1)">aktivieren
                        </div>
                        <div v-if="isTestuserSupplier === true" class="button nonfixed right delete"
                             @click="setFlag( 'testuser_supplier', 0)">deaktivieren
                        </div>
                        <span v-if="isTestuserSupplier === true" class="good"
                              style="margin-right: 10px; line-height: 30px;">
                        <strong>aktiv</strong>
                    </span>
                    </td>
                </tr>
            </template>
            <tr>
                <td class="center strong">In-App-Nachricht schicken</td>
                <td class="center">
                </td>
            </tr>
            <tr v-if="isPoolUser">
                <td class="center strong">Sekretär</td>
                <td class="right">
                    <div v-if="isSecretary === false && secretaryInUse === false" class="button nonfixed right set"
                         @click="setFlag( 'secretary', 1)">Flag setzen
                    </div>
                    <span v-if="isSecretary === true && secretaryInUse === true" class="good"
                          style="margin-right: 10px; line-height: 30px;">
                        <strong>aktiv</strong>
                    </span>
                    <div v-if="isSecretary === true" class="button nonfixed right delete"
                         @click="setFlag( 'secretary', 0)">Flag entfernen
                    </div>
                    <div v-if="isSecretary === false && secretaryInUse === true" class="button nonfixed right disabled">
                        Flag bereits in Nutzung
                    </div>
                </td>
            </tr>
            <tr>
                <td class="center strong">IZeL</td>
                <td class="right">
                    <div v-if="hasIzel === false" class="button nonfixed right set"
                         @click="setFlag( 'izel', 1)">aktivieren
                    </div>
                    <div v-if="hasIzel === true" class="button nonfixed right delete"
                         @click="setFlag( 'izel', 0)">deaktivieren
                    </div>
                    <span v-if="hasIzel === true" class="good"
                          style="margin-right: 10px; line-height: 30px;">
                        <strong>aktiv</strong>
                    </span>
                </td>
            </tr>
            <template v-if="$core.r().isAllowed( 'setDemoUser' )">
                <tr>
                    <td class="center strong">Demo-Account</td>
                    <td class="right">
                        <div v-if="isDemoUser === false" class="button nonfixed right set"
                             @click="setFlag( 'demouser', 1)">Flag setzen
                        </div>
                        <div v-if="isDemoUser === true" class="button nonfixed right delete"
                             @click="setFlag( 'demouser', 0)">Flag entfernen
                        </div>
                        <span v-if="isDemoUser === true" class="good"
                              style="margin-right: 10px; line-height: 30px;">
                        <strong>aktiv</strong>
                    </span>
                    </td>
                </tr>
            </template>
            <template v-if="$core.r().isAllowed( 'deleteAccount' )">
                <tr>
                    <td class="center strong error">Account löschen</td>
                    <td class="right">
                        <div class="button right delete" @click="deleteUser()">X</div>
                    </td>
                </tr>
            </template>
        </table>
    </div>
</template>

<script>
export default {
    name : 'UserEditor',
    props: [ 'element' ],

    emits: [ 'refresh', 'localRefresh' ],

    data()
    {
        return {
            isPoolUser            : this.$props.element.id_license_pool > 0,
            isDemoUser            : false,
            is2FA                 : false,
            isPUK                 : false,
            isSecretary           : false,
            isSupportMirror       : false,
            isTestuserSupplier    : false,
            hasIzel               : false,
            secretaryInUse        : false,
            prolongOptions        : [ '1D', '2D', '3D', '7D', '14D', '3W', '1M', '2M', '3M', '6M', '9M', '12M' ],
            prolongAvailable      : false,
            shortenAvailable      : false,
            licenseOptions        : [],
            licenseChangeAvailable: false
        }
    },

    created()
    {

        this.initFlags()
        this.initLicenses()

    },

    methods: {

        initLicenses()
        {
            this.$core.getClient()
                .request( {
                    method: 'admin.licenses_listAll'
                } )
                .then( response =>
                {
                    this.licenseOptions = response.result
                } )
        },

        checkLicenseChange()
        {
            let elm = document.querySelector( '#licensechange' )
            this.licenseChangeAvailable = ( null !== elm && elm.value.trim() !== '' )
        },

        changeLicense()
        {

            this.$core.getEventManager().dispatch( 'showBlocker' )
            let elm = document.querySelector( '#licensechange' )

            this.$core.getClient()
                .request( {
                    method    : 'admin.licenses_change',
                    id_user   : this.$props.element.id,
                    id_license: elm.value
                } )
                .then( () =>
                {

                    elm.value = ''
                    this.handleRefresh()

                } )
        },

        handleRefresh()
        {
            this.$emit( 'refresh' )
            setTimeout( () =>
            {

                this.$emit( 'localRefresh' )

            }, 500 )
        },

        checkProlong( id )
        {
            id = id || 'prolong'
            let elm = document.querySelector( '#' + id )
            this[ id + 'Available' ] = ( null !== elm && elm.value.trim() !== '' )
        },

        prolongLicense()
        {

            this.$core.getEventManager().dispatch( 'showBlocker' )

            let elm = document.querySelector( '#prolong' )
            let prolongBy = elm.value === 'default' ? this.$props.element.lusage : elm.value
            let licenseValid = this.$core.getReformatter().licenseValid( this.$props.element.license_tsmp_end )

            this.$core.getClient()
                .request( {
                    method : 'admin.licenses_prolong',
                    mode   : ( licenseValid ? 'prolong' : 'recreate' ),
                    diff   : prolongBy,
                    id_user: this.$props.element.id
                } )
                .then( () =>
                {

                    elm.value = ''
                    this.handleRefresh()

                } )
        },

        shortenLicense()
        {

            this.$core.getEventManager().dispatch( 'showBlocker' )

            let elm = document.querySelector( '#shorten' )
            let prolongBy = elm.value === 'default' ? this.$props.element.lusage : elm.value
            let licenseValid = this.$core.getReformatter().licenseValid( this.$props.element.license_tsmp_end )

            this.$core.getClient()
                .request( {
                    method       : 'admin.licenses_prolong',
                    mode         : ( licenseValid ? 'prolong' : 'recreate' ),
                    diff         : prolongBy,
                    prolongMethod: 'shorten',
                    id_user      : this.$props.element.id
                } )
                .then( () =>
                {

                    elm.value = ''
                    this.handleRefresh()

                } )
        },

        invalidateLicense()
        {
            this.$core.getEventManager().dispatch( 'showBlocker' )
            this.$core.getClient()
                .request( {
                    method       : 'admin.licenses_invalidate',
                    id_user      : this.$props.element.id
                } )
                .then( () =>
                {

                    this.handleRefresh()

                } )
        },

        initFlags()
        {

            let message = {
                method : 'admin.users_getAllFlags',
                id_user: this.$props.element.id
            }

            this.$core.getClient()
                .request( message )
                .then( response =>
                {

                    this.isSecretary = response.result.flag_is_secretary === 1
                    this.isDemoUser = response.result.flag_is_demouser === 1
                    this.isSupportMirror = response.result.flag_is_support_mirror === 1
                    this.isTestuserSupplier = response.result.flag_is_testuser_supplier === 1
                    this.hasIzel = response.result.flag_has_izel === 1

                } )

            this.$core.getClient()
                .request( {
                    method : 'admin.users_getSecondFactor',
                    id_user: this.$props.element.id
                } )
                .then( response =>
                {
                    this.is2FA = response.result.is2FA
                    this.isPUK = response.result.isPUK
                } )

        },

        confirmDelete()
        {

            let message = {
                method : 'admin.users_delete',
                id_user: this.$props.element.id
            }

            this.$core.getClient()
                .request( message )
                .then( () =>
                {

                    this.$emit( 'refresh' )

                } )
                .catch( () =>
                {
                    console.error( 'users_delete: call unsuccessful.' )
                } )

        },

        deleteUser()
        {

            let setup = {
                show   : true,
                caption: 'Nutzerkonto löschen',
                buttons: {
                    'left' : {
                        class   : 'default',
                        caption : 'lieber nicht',
                        callback: () =>
                        {
                            console.log( 'ney' )
                        }
                    },
                    'right': {
                        class   : 'delete',
                        caption : 'löschen',
                        callback: () =>
                        {
                            this.confirmDelete()
                        }
                    }
                },
                message: '<strong>Bist du sicher</strong>, dass das Nutzerkonto <strong>' + this.$props.element.username + '</strong>' +
                         ' von <strong>' + this.$props.element.firstname + ' ' + this.$props.element.lastname + '</strong> gelöscht werden soll?<br/><br/>' +
                         '<strong>Wichtig:</strong> Die Aktion lässt sich nur mühsam rückgängig machen:<br/>Beim nächsten Cleanup-Lauf werden alle Elemente des Nutzers gelöscht.'
            }

            this.$core.getEventManager().dispatch( 'show-modal-dialog', setup )

        },

        setFlag( flag, value )
        {
            let message = {
                method : 'admin.users_setFlag',
                id_user: this.$props.element.id,
                flag   : ( 'izel' === flag ? 'flag_has_izel' : 'flag_is_' + flag ),
                value  : value
            }

            this.$core.getClient()
                .request( message )
                .then( () =>
                {

                    this.initFlags()

                } )
        },

        translateLicenseUsage( period )
        {

            if( null !== period )
            {
                let replace = {
                    D: ' Tag(e)',
                    M: ' Monat(e)',
                    W: ' Woche(n)'
                }

                for( let r in replace )
                {
                    period = period.replace( r, replace[ r ] )
                }

                return period
            }

        },

        remove2FA()
        {
            this.$core.getClient()
                .request( {
                    method : 'admin.users_dropSecondFactor',
                    id_user: this.$props.element.id
                } )
                .then( () =>
                {
                    this.$emit( 'refresh' )
                } )
        },

        removePUK()
        {
            this.$core.getClient()
                .request( {
                    method : 'admin.users_dropPUK',
                    id_user: this.$props.element.id
                } )
                .then( () =>
                {
                    this.$emit( 'refresh' )
                } )
        }

    }
}
</script>