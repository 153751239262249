<template>
    <div class="successMessage" @click="$emit( 'close' )">
        <h2>{{ title }}</h2>
    </div>
</template>

<script>
export default {
    name: "SuccessMessage",
    props: {
        title: { Type: String, required: true }
    },
    emits: [ 'close' ]
}
</script>