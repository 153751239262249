export default class Reformatter
{

    constructor()
    {
        return this
    }

    translateStatus( status )
    {
        switch( status )
        {
            case 'n/a':
                return '<span class="status unavailable">n/a</span>'
            case 'new':
                return '<span class="status new">neu</span>'
            case 'verified':
            case 'registered':
                return '<span class="status verified">angemeldet</span>'
            case 'active':
                return '<span class="status verified">aktiver nutzer</span>'
            case 'OPTED IN':
                return '<span class="status verified">verifiziert</span>'
            case 'OptCode_sent':
            case 'InviteCode_Sent':
                return '<span class="status new">code verschickt</span>'
            case 'OptCode_created':
            case 'InviteCode_new':
                return '<span class="status unavailable">code erzeugt</span>'
            case 'deleted':
                return '<span class="status unavailable">gelöscht</span>'
            default:
                return status
        }
    }

    formatTimestamp = timestamp =>
    {

        if( null === timestamp
            || undefined === timestamp )
        {
            return 'n/a'
        }

        let parts = timestamp.split( 'T' )
        let date = parts[ 0 ].split( '-' ).reverse().join( '.' )
        let time = parts[ 1 ].split( '.' ).shift()

        return date + ', ' + time + ' Uhr'

    }

    statsTimestamp = timestamp =>
    {

        if( null === timestamp
            || undefined === timestamp
            || '' === timestamp )
        {
            return null
        }

        let d          = new Date( parseInt( timestamp ) ),
            datestring = ( "0" + d.getDate() ).slice( -2 ) + "." + ( "0" + ( d.getMonth() + 1 ) ).slice( -2 ) + "." +
                         d.getFullYear() + " " + ( "0" + d.getHours() ).slice( -2 ) + ":00"

        return datestring

    }

    formatUserType = user =>
    {

        if( user.is_student === 1 )
        {
            return '<span class="status top verified">schüler</span>'
        }

        if( ( user.id_license > 0
              && user.lcost > 0 )
            || user.id_license === 18 )
        {

            return '<span class="status top verified">kunde</span>'

        }

        if( user.id_license_pool > 0
            && user.id_pool_user === user.id )
        {

            return '<span class="status top verified">poolkunde</span>'

        }

        if( user.id_pool_user > 0
            && user.id_pool_user !== user.id )
        {

            return '<span class="status top verified">poolnutzer</span>'

        }

        if( user.id_license === 1
            || user.id_license === 2 )
        {
            return '<span class="status top demo">test</span>'
        }

        if( user.id_license === 10
            || user.id_license === 11 )
        {
            return '<span class="status top error">gekündigt</span>'
        }

    }

    formatUserTypePlain = user =>
    {

        if( ( user.id_license > 0
              && user.lcost > 0 )
            || user.id_license === 18 )
        {

            return 'kunde'

        }

        if( user.id_license_pool > 0
            && user.id_pool_user === user.id )
        {

            return 'poolkunde'

        }

        if( user.id_pool_user > 0
            && user.id_pool_user !== user.id )
        {

            return 'poolnutzer'

        }

        if( user.id_license === 1
            || user.id_license === 2 )
        {

            return 'test'

        }

        if( user.id_license === 10
            || user.id_license === 11 )
        {
            return 'gekündigt'
        }

    }

    formatUserActivity = ( timestamp, text ) =>
    {

        let now = new Date().getTime()
        let tsmp = new Date( timestamp ).getTime()
        let diff = now - tsmp

        let day = 86400 * 1000

        if( diff <= ( 7 * day ) )
        {
            return '<span class="status top norm verified">' + text + '</span>'
        }
        if( diff <= ( 14 * day ) )
        {
            return '<span class="status top norm okay">' + text + '</span>'
        }
        if( diff <= ( 21 * day ) )
        {
            return '<span class="status top norm warning">' + text + '</span>'
        }
        if( diff > ( 21 * day ) )
        {
            return '<span class="status top norm error">' + text + '</span>'
        }

        return text

    }

    activityLevel = ( timestamp ) =>
    {

        let now = new Date().getTime()
        let tsmp = new Date( timestamp ).getTime()
        let diff = now - tsmp

        let day = 86400 * 1000

        if( diff <= ( 7 * day ) )
        {
            return 7
        }
        if( diff <= ( 14 * day ) )
        {
            return 14
        }
        if( diff <= ( 21 * day ) )
        {
            return 21
        }
        if( diff >= ( 365 * day ) )
        {
            return 365
        }
        if( diff > ( 21 * day ) )
        {
            return 99
        }

        return 9999

    }

    licenseValid = ( timestamp ) =>
    {

        let now = new Date().getTime()
        let end = new Date( timestamp ).getTime()

        let diff = ( ( end - now ) / 86400000 ).toFixed( 0 )

        if( diff >= 0 )
        {
            return true
        }

        return false

    }


    formatLicenseDays = ( timestamp ) =>
    {

        let now = new Date().getTime()
        let end = new Date( timestamp ).getTime()

        let diff = ( ( end - now ) / 86400000 ).toFixed( 0 )

        if( diff >= 30 )
        {
            return '<span class="status norm good">' + diff + ' Tage</span>'
        }
        if( diff >= 14 )
        {
            return '<span class="status norm okay">' + diff + ' Tage</span>'
        }
        if( diff >= 0 )
        {
            return '<span class="status norm warning">' + diff + ' Tag' + ( 1 !== diff ? 'e' : '' ) + '</span>'
        }

        return '<span class="status norm error">abgelaufen</span>'

    }

    numberFormat( value, type )
    {
        switch( type )
        {
            case 'price':
                return parseFloat( value ).toFixed( 2 ).replace( '.', ',' ) + ' &euro;'
            case 'period':
                value = value.replace( 'M', ' Monat(e)' )
                value = value.replace( 'D', ' Tag(e)' )
                return value
        }
    }

    featureSet( value )
    {
        switch( value )
        {
            case 'full':
                return 'Pro-Version (voll)'
            case 'lite':
                return 'Light-Version (minimal)'
            case 'networked':
                return 'Netzwerk (voll)'
        }
    }

    prolong( value )
    {
        switch( value )
        {
            case 1:
                return '<span class="status norm good">automatisch</span>'
            case 0:
                return '<span class="status norm okay">manuell</span>'
        }
    }

    customerVisible( value )
    {
        switch( value )
        {
            case 1:
                return '<span class="status norm good">in der App sichtbar</span>'
            case 0:
                return '<span class="status norm okay">nur via Admin buchbar</span>'
        }
    }

    cleanId( id )
    {
        if( undefined === id )
        {
            return id
        }

        id = id.replace( 'ä', 'ae' )
        id = id.replace( 'ö', 'oe' )
        id = id.replace( 'ü', 'ue' )
        id = id.replace( 'Ä', 'Ae' )
        id = id.replace( 'Ö', 'Oe' )
        id = id.replace( 'Ü', 'Ue' )
        id = id.replace( 'ß', 'ss' )

        id = id.replace( /@/g, '--AT--' )
        id = id.replace( /%/g, '--PRCT--' )
        id = id.replace( /&/g, '--AND--' )
        id = id.replace( /\$/g, '--DLLR--' )
        id = id.replace( /§/g, '--PRGRPH--' )
        id = id.replace( /=/g, '--EQL--' )
        id = id.replace( /\|/g, '--PIPE--' )
        id = id.replace( /\{/g, '--SPKO--' )
        id = id.replace( /\}/g, '--SPKCL--' )
        id = id.replace( /\[/g, '--SQO--' )
        id = id.replace( /\]/g, '--SQCL--' )
        id = id.replace( /\(/g, '--BRO--' )
        id = id.replace( /\)/g, '--BRCL--' )
        id = id.replace( /</g, '--LTHN--' )
        id = id.replace( />/g, '--GTHN--' )
        id = id.replace( /,/g, '--COMMA--' )
        id = id.replace( /;/g, '--SMCLN--' )
        id = id.replace( /:/g, '--CLN--' )
        id = id.replace( /\*/g, '--ASTRSK--' )
        id = id.replace( /#/g, '--HSH--' )
        id = id.replace( /!/g, '--EXCL--' )
        id = id.replace( /\?/g, '--QST--' )
        id = id.replace( /\./g, '---' )
        id = id.replace( /'/g, '__' )
        id = id.replace( /"/g, '____' )
        id = id.replace( /\\/g, '_____' )
        id = id.replace( /\//g, '______' )
        id = id.replace( /\+/g, '___' )
        return id.trim().replace( / /g, '_' )

    }

}