<template>
    <div class="infobox" v-if="ready">
        <Opener id="invoices" :state="open" inline=true
                :caption="'Rechnungen'+( !open ? '<span style=\'font-weight:normal\'>: '+invoices.length+'</span>' : '' )"
                @clicked="open = !open"/>
        <template v-if="open">
            <table class="margin-top infotable" v-if="0 < invoices.length">
                <thead>
                <Thd caption="Rechnungs-Nr."/>
                <Thd caption="Erstellungszeitpunkt"/>
                <Thd align="right" caption="Betrag"/>
                <Thd caption="Status"/>
                <Thd caption="Optionen"/>
                </thead>
                <tbody>
                <tr v-for="invoice in invoices" :key="'invoice_'+invoice.id">
                    <td class="strong">{{ invoice.id_invoice }}</td>
                    <td class="nowrap">{{ $core.getReformatter().formatTimestamp( invoice.datetime_invoice ) }}</td>
                    <td class="strong right">{{ invoice.costs.toFixed( 2 ) }} &euro;</td>
                    <td class="nowrap" v-html="getStatus( invoice )"></td>
                    <td class="nowrap">
                        <div class="button download" @click="getInvoice( invoice.id )"></div>
                        <template v-if="$core.r().isAllowed( 'invoiceFunctions' )">
                            <template
                                v-if="null === invoice.datetime_paid || '1970-01-01 00:00:00' === invoice.datetime_paid">
                                <div
                                    v-if="null === invoice.datetime_cancel || '1970-01-01 00:00:00' === invoice.datetime_cancel"
                                    class="button cancel" @click="cancelInvoice( invoice.id )">S
                                </div>
                                <div
                                    v-if="null !== invoice.datetime_cancel && '1970-01-01 00:00:00' !== invoice.datetime_cancel"
                                    class="button reactivate" @click="reactivateInvoice( invoice.id )">R
                                </div>
                            </template>
                            <template
                                v-if="null === invoice.datetime_paid || '1970-01-01 00:00:00' === invoice.datetime_paid">
                                <div
                                    v-if="null === invoice.datetime_cancel || '1970-01-01 00:00:00' === invoice.datetime_cancel"
                                    class="button setpaid" @click="setPaidInvoice( invoice.id )"
                                    title="als bezahlt markieren">B
                                </div>
                            </template>
                            <template
                                v-if="null !== invoice.datetime_paid && '1970-01-01 00:00:00' !== invoice.datetime_paid">
                                <div
                                    v-if="null === invoice.datetime_cancel || '1970-01-01 00:00:00' === invoice.datetime_cancel"
                                    class="button cancel" @click="setUnpaidInvoice( invoice.id )"
                                    title="als unbezahlt markieren">U
                                </div>
                            </template>
                        </template>
                    </td>
                </tr>
                </tbody>
            </table>
            <table class="margin-top infotable" v-else>
                <tr>
                    <td class="center strong">&nbsp;</td>
                    <td class="center"><h3 class="unavailable">keine Rechnungen verfügbar</h3></td>
                </tr>
            </table>
        </template>
    </div>
</template>

<script>

import Thd    from '@/components/elements/Thd'
import Opener from '@/components/elements/Opener'

export default {

    name      : 'UserInvoices',
    components: { Opener, Thd },
    props     : [ 'element' ],

    data()
    {
        return {
            invoices: false,
            open    : false,
            ready   : false
        }
    },

    mounted()
    {

        this.init()

    },

    methods: {

        init()
        {
            let message = {
                method : 'admin.users_listInvoices',
                id_user: this.$props.element.id
            }

            this.$core.getClient()
                .request( message )
                .then( result =>
                {

                    this.invoices = result.result
                    this.ready = true

                } )
                .catch( () =>
                {
                    this.invoices = []
                    this.ready = true
                } )
        },

        reset()
        {
            this.invoices = []
            this.ready = false
            this.init()
        },

        getInvoice( id )
        {
            let invoiceTest = {
                method: 'admin.invoices_getById',
                id    : id
            }

            this.$core.getClient()
                .request( invoiceTest )
                .then( result =>
                {

                    let res = result
                    let arrayBuffer = new Uint8Array( res.invoice.data )
                    let file = new Blob( [ arrayBuffer ], { type: 'application/pdf' } )
                    let fileURL = window.URL.createObjectURL( file );

                    const link = document.createElement( 'a' )
                    link.href = fileURL
                    link.setAttribute( 'download', res.id_invoice + '.pdf' )
                    document.body.appendChild( link )
                    link.click()

                } )

        },

        cancelInvoice( id )
        {
            let invoiceSet = {
                method: 'admin.invoices_cancel',
                id    : id
            }

            this.$core.getClient()
                .request( invoiceSet )
                .then( () =>
                {
                    setTimeout( () =>
                    {
                        this.reset()
                    }, 500 )
                } )
        },

        reactivateInvoice( id )
        {
            let invoiceSet = {
                method: 'admin.invoices_reactivate',
                id    : id
            }

            this.$core.getClient()
                .request( invoiceSet )
                .then( () =>
                {
                    setTimeout( () =>
                    {
                        this.reset()
                    }, 500 )
                } )
        },

        setPaidInvoice( id )
        {
            let invoiceSet = {
                method: 'admin.invoices_setPaid',
                id    : id
            }

            this.$core.getClient()
                .request( invoiceSet )
                .then( () =>
                {
                    setTimeout( () =>
                    {
                        this.reset()
                    }, 500 )
                } )
        },

        setUnpaidInvoice( id )
        {
            let invoiceSet = {
                method: 'admin.invoices_setUnpaid',
                id    : id
            }

            this.$core.getClient()
                .request( invoiceSet )
                .then( () =>
                {
                    setTimeout( () =>
                    {
                        this.reset()
                    }, 500 )
                } )
        },

        getStatus( invoice )
        {
            if( null !== invoice.datetime_paid )
            {
                return '<span class="status norm good">bezahlt</span>'
            }
            else
            {
                if( null !== invoice.datetime_cancel )
                {
                    return '<span class="status norm okay">storniert</span>'
                }
                else
                {
                    return '<span class="status norm error">offen</span>'
                }
            }
        }

    }


}
</script>