<script>
import Vue3ChartJs from '@j-t-mcc/vue3-chartjs'

export default {
    name   : 'DisplayMonitoringStats',
//    extends: Line,
    props  : [ 'data' ],

    mounted()
    {

        let labels = []
        let stats = []
        let keyStats = {}

        let lastDay = false

        let snap = this.freshSnap()
        let label = ''
        let labelIdx = -1

        for( let d in this.$props.data )
        {

            let st = this.$props.data[ d ]
            let timestamp = new Date( st.daygroup )

            if( false === lastDay
                || timestamp.getTime() > lastDay )
            {
                labelIdx++
                snap = this.freshSnap()
                label = new Intl.DateTimeFormat( 'de' ).format( timestamp )
                labels.push( label )
                lastDay = timestamp.getTime()
            }

            if( st.connections > snap.connections )
            {
                snap.connections = st.connections
            }
            if( st.cpu > snap.cpu )
            {
                snap.cpu = st.cpu
            }
            if( st.memory > snap.memory )
            {
                snap.memory = st.memory
            }
            if( st.sessions > snap.sessions )
            {
                snap.sessions = st.sessions
            }

            stats[ labelIdx ] = snap

        }

        for( let s in stats )
        {
            if( undefined === keyStats.connections )
            {
                keyStats.connections = []
            }
            keyStats.connections.push( stats[ s ].connections )
            if( undefined === keyStats.cpu )
            {
                keyStats.cpu = []
            }
            keyStats.cpu.push( stats[ s ].cpu )
            if( undefined === keyStats.sessions )
            {
                keyStats.sessions = []
            }
            keyStats.sessions.push( stats[ s ].sessions )
            if( undefined === keyStats.memory )
            {
                keyStats.memory = []
            }
            keyStats.memory.push( ( stats[ s ].memory / 1024 / 1024 ).toFixed( 2 ) )

        }

        this.renderChart( {

                labels  : labels,
                datasets: [
                    {
                        yAxisID        : 'right',
                        label          : 'CPU-Auslastung',
                        backgroundColor: '#feacac',
                        data           : keyStats.cpu
                    },
                    {
                        yAxisID        : 'left',
                        label          : 'Sessions',
                        backgroundColor: 'yellow',
                        data           : keyStats.sessions
                    },
                    {
                        yAxisID        : 'right',
                        label          : 'Speichernutzung',
                        backgroundColor: '#acacfe',
                        data           : keyStats.memory
                    },
                    {
                        yAxisID        : 'left',
                        label          : 'Verbindungen',
                        backgroundColor: 'yellowgreen',
                        data           : keyStats.connections
                    }
                ]

            },
            {
                scales: {
                    yAxes: [
                        {
                            id   : 'left',
                            position: 'left',
                            ticks: {
                                beginAtZero: true,
                                min        : 0
                            }
                        },
                        {
                            id   : 'right',
                            position: 'right',
                            ticks: {
                                beginAtZero: true,
                                min        : 0
                            }
                        }
                    ]
                }
            } )
    },

    methods: {

        freshSnap()
        {
            return {
                connections: 0,
                cpu        : 0,
                sessions   : 0,
                memory     : 0
            }
        }

    }

}
</script>