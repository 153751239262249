<template>
    <div>
        <vue3-chart-js :data="dataset"
                       :ref="'chartref-'+id"
                       :id="'chart-'+id"
                       :height="height"
                       type="line"/>
    </div>
</template>
<script>
import Vue3ChartJs from '@j-t-mcc/vue3-chartjs'

export default {
    name : 'DisplayUserStats',
    props: [ 'id', 'data', 'ready', 'height' ],

    components: {
        Vue3ChartJs
    },

    mounted()
    {
        this.prepare()
    },

    watch: {
        ready: {
            handler()
            {
                this.prepare()
            }
        }
    },

    data()
    {
        return {

            dataset: {

                labels  : [ '...', '...', '...', '...' ],
                datasets: [
                    {

                        data           : [ 10, 20, 30, 40 ],
                        fill           : true,
                        label          : '...',
                        backgroundColor: 'lightgreen',
                        borderColor    : 'green',
                        borderWidth    : 1,
                        tension        : 0.5,
                        opacity        : 0.2

                    },
                    {

                        data           : [ 40, 30, 20, 10 ],
                        fill           : true,
                        label          : '...',
                        backgroundColor: 'lightblue',
                        borderColor    : 'blue',
                        borderWidth    : 1,
                        tension        : 0.3,
                        opacity        : 0.5

                    },
                    {

                        data           : [ 10, 20, 20, 10 ],
                        fill           : true,
                        label          : '...',
                        backgroundColor: 'lightyellow',
                        borderColor    : 'yellow',
                        borderWidth    : 1,
                        tension        : 0.9,
                        opacity        : 0.5

                    },
                    {

                        data           : [ 10, 20, 30, 40 ],
                        fill           : true,
                        label          : '...',
                        backgroundColor: 'lightred',
                        borderColor    : 'red',
                        borderWidth    : 1,
                        tension        : 0.5,
                        opacity        : 0.2

                    }
                ]
            }
        }
    },

    methods: {
        prepare()
        {

            let color       = '#dfdfdf',
                borderColor = '#2c2c2c'

            switch( this.$props.data.grow.tendence )
            {
                case 'up':
                    color = 'rgba( 201, 242, 155, 0.5 )'
                    borderColor = 'rgba( 201, 242, 155, 1 )'
                    break
                case 'down':
                    color = 'rgba( 125, 125, 125, 0.5 )'
                    borderColor = 'rgba( 125, 125, 125, 1.0 )'
                    break
            }

            this.dataset.labels = this.$props.data.labels

            this.dataset.datasets = [
                {
                    label          : this.$props.data.grow.headline,
                    backgroundColor: color,
                    borderColor    : borderColor,
                    fill           : true,
                    tension        : 0.5,
                    fillOpacity    : 0.5,
                    data           : this.$props.data.grow.set
                },
                {
                    label          : this.$props.data.active.headline,
                    backgroundColor: 'rgba( 255, 255, 224, 0.5 )',
                    borderColor    : 'rgba( 255, 255, 224, 1.0 )',
                    borderWidth    : 1,
                    fill           : true,
                    tension        : 0.5,
                    fillOpacity    : 0.5,
                    data           : this.$props.data.active.set
                },
                {
                    label          : this.$props.data.total.headline,
                    backgroundColor: 'rgba( 103, 242, 209, 0.5 )',
                    borderColor    : 'rgba( 103, 242, 209, 1.0 )',
                    fill           : true,
                    tension        : 0.5,
                    fillOpacity    : 0.5,
                    data           : this.$props.data.total.set
                },
                {
                    label          : this.$props.data.deleted.headline,
                    backgroundColor: 'rgba( 225, 25, 25, 0.5 )',
                    borderColor    : 'rgba( 225, 25, 25, 1.0 )',
                    fill           : true,
                    tension        : 0.5,
                    fillOpacity    : 0.5,
                    data           : this.$props.data.deleted.set
                }
            ]

            this.$refs[ 'chartref-' + this.$props.id ].update()

        }
    }

}
</script>