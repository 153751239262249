<template>
    <transition appear name="fade">
        <div id="dash-requests">
            <h2>Request-Statistiken</h2>
            <p>erfolgreiche vs. fehlerhafte Server-Requests<br/><strong>{{ total }}</strong> Requests insgesamt,
                <strong>{{ percentage }}</strong>% erfolgreich</p>
            <DisplaySessionStats id="dash-requests" :height=300 v-if="isready" :data="data"/>
            <DisplayDummyDougnut id="dash-requests" :height=300 v-else/>
        </div>
    </transition>
</template>

<script>
import DisplaySessionStats from '@/components/elements/boxes/stats/DisplaySessionStats'
import DisplayDummyDougnut from "@/components/elements/boxes/stats/DisplayDummyDougnut";

export default {
    name      : 'DashSessionStats',
    components: { DisplayDummyDougnut, DisplaySessionStats },
    emits     : [ 'ready' ],

    data()
    {
        return {
            isready   : false,
            data      : false,
            percentage: 0,
            total     : 0,
            trans     : {
                request_count: 'erfolgreich',
                error_count  : 'fehlerhaft'
            }
        }
    },

    mounted()
    {
        let message = {
            method: 'admin.stats_getRequestStatistics'
        }

        this.$core.getClient()
            .request( message )
            .then( result =>
            {

                let data = result.result
                let prep = {
                    labels: [],
                    set   : []
                }

                let count = 0
                let succ = 0

                for( let k in Object.keys( data ) )
                {
                    let key = Object.keys( data )[ k ]
                    count += parseInt( data[ key ] )
                    if( 'request_count' === key )
                    {
                        succ = parseInt( data[ key ] )
                    }
                    prep.labels.push( this.trans[ key ] ? this.trans[ key ] : key )
                    prep.set.push( parseInt( data[ key ] ) )
                }

                this.total = count
                this.percentage = ( ( succ / count ) * 100 ).toFixed( 2 )
                this.data = prep
                this.$emit( 'ready' )
                this.isready = true

            } )
            .catch( () =>
            {
            } )
    }

}
</script>