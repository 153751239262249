<template>
    <div>
        <vue3-chart-js :data="dataset"
                       :height="height"
                       :ref="'chartref-'+id"
                       :id="'chart-'+id"
                       type="bar"/>
    </div>
</template>
<script>
import Vue3ChartJs from '@j-t-mcc/vue3-chartjs'

export default {
    name   : 'DisplayLoginStats',
    props  : [ 'id', 'data', 'height' ],

    components: {
        Vue3ChartJs
    },

    data()
    {
        return {

            dataset: {

                labels  : [],
                datasets: []
            }
        }
    },

    mounted()
    {
        this.prepare()
    },

    methods: {
        prepare()
        {

            let labels = [],
                dataset = []

            for( let l in this.$props.data.labels )
            {
                let label = this.$props.data.labels[ l ]
                labels.push( label )
                dataset.push( this.$props.data.set[ l ] )
            }

            this.dataset.labels = labels

            this.dataset.datasets = [
                {
                    label          : 'Aktive Accounts',
                    backgroundColor: this.$core.getColorSet( 'bars' ),
                    color: '#2c2c2c',
                    data           : dataset
                }
            ]

            this.$refs[ 'chartref-' + this.$props.id ].update()

        }
    }

}
</script>