<template>
    <div>
        <transition-group appear name="next">
            <LicensesList v-if="tab === 'licenses/list'" key="licenses"/>
            <LicensePools v-if="tab === 'licenses/licensePools'" key="licensePools"/>
            <LicensesVouchers v-if="tab === 'licenses/vouchers'" key="licensesVouchers"/>
        </transition-group>
    </div>
</template>

<script>
import LicensesList     from '@/components/pages/tabs/licenses/LicensesList'
import LicensePools     from '@/components/pages/tabs/licenses/LicensePools'
import LicensesVouchers from '@/components/pages/tabs/licenses/LicensesVouchers'

export default {
    
    name      : 'Licenses',
    components: { LicensesVouchers, LicensesList, LicensePools },
    data()
    {
        return {
            tab  : this.$core.getState( 'navigation.activeSub' ),
            timer: null
        }
    },
    
    created()
    {
        this.timer = setInterval( () =>
        {
            this.tab = this.$core.getState( 'navigation.activeSub' )
        }, 200 )
    },
    
    beforeDestroy()
    {
        clearInterval( this.timer )
        this.timer = null
    }
    
}
</script>