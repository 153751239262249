<script>
export default {
    name: "ContainsFilterable",
    methods: {
        setFilter( filter )
        {
        
            if( 'reset' === filter )
            {
                this.$core.getEventManager().dispatch( 'reset-filter' )
            }
            else
            {
                let hquery = document.querySelector( '#hquery' )
                if( null !== hquery )
                {
                    hquery.value = filter
                    hquery.dispatchEvent(new Event('input', {
                        bubbles: true,
                        cancelable: true,
                    }) )
                }
            }
        }
    }
}
</script>