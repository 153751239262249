<template>
    <div>
        <transition appear name="fade">
            <div id="navigation">
                <template v-for="target in targets"
                          :key="target.key">
                    <div v-if="$core.r().isRouteAllowed( target.key )"
                         :class="'nav-link'+( $route.name === target.key ? ' active' : '' )"
                         @click="navigate( $event, target )">
                        {{ target.caption }}
                        <template appear name="zoom"
                                  v-if="undefined !== counters[target.key] && 0 < counters[target.key]">
                            <span class="bubble">{{ counters[ target.key ] }}</span>
                        </template>
                        <span class="clickable_user" @click="newTab( $event, target )">&#x25f3;</span>
                    </div>
                </template>
                <div v-if="scrollTopVisible" class="nav-link" @click="scrollToTop()">
                    &#8613;
                </div>
            </div>
        </transition>
        <transition appear name="fade">
            <div id="subnavigation" v-if="undefined !== subnav[ $route.name ]">
                <div v-for="subtarget in subnav[$route.name]"
                     :class="'subnav-link'+( activeSub === subtarget.key ? ' active' : '' )" :key="subtarget.key"
                     @click="navigate( $event, subtarget )">
                    {{ subtarget.caption }}
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {

    name: 'Navigation',

    data()
    {
        return {
            scrollTopVisible  : false,
            attached          : false,
            activeSub         : false,
            scrollToTopVisible: false,
            counters          : {
                users   : 0,
                helpdesk: 0
            },
            targets           : [
                {
                    key    : 'admin',
                    caption: 'Admin'
                },
                {
                    key    : 'monitoring',
                    caption: 'Monitoring'
                },
                {
                    key    : 'tools',
                    caption: 'Werkzeuge'
                },
                {
                    key    : 'helpdesk',
                    caption: 'Helpdesk'
                },
                {
                    key    : 'licenses',
                    caption: 'Lizenzen & Gutscheine'
                },
                {
                    key    : 'invoices',
                    caption: 'Rechnungen'
                },
                {
                    key    : 'users',
                    caption: 'App-Benutzer'
                },
                {
                    key    : 'dashboard',
                    caption: 'Dashboard'
                }
            ],
            subnav            : {
                users     : [
                    {
                        key    : 'users/active-365',
                        caption: 'Karteileichen'
                    },
                    {
                        key    : 'users/active-99',
                        caption: 'aktiv vor mehr als 3 Wochen'
                    },
                    {
                        key    : 'users/active-21',
                        caption: 'aktiv vor 2-3 Wochen'
                    },
                    {
                        key    : 'users/active-14',
                        caption: 'aktiv vor 1-2 Wochen'
                    },
                    {
                        key    : 'users/active-7',
                        caption: 'aktiv'
                    },
                    {
                        key    : 'users/reset',
                        caption: 'Alle anzeigen'
                    }
                ],
                licenses  : [
                    {
                        key    : 'expiredLicenses',
                        caption: 'abgelaufene Lizenzen'
                    },
                    {
                        key    : 'activeLicenses',
                        caption: 'genutzte Lizenzen'
                    },
                    {
                        key    : 'licenses/vouchers',
                        caption: 'Gutscheine'
                    },
                    {
                        key    : 'licenses/licensePools',
                        caption: 'Lizenz-Pools'
                    },
                    {
                        key    : 'licenses/list',
                        caption: 'Lizenzen'
                    }
                ],
                invoices  : [],
                tools     : [
                    {
                        key    : 'tools/server',
                        caption: 'Server-Variablen'
                    },
                    {
                        key    : 'tools/deletions',
                        caption: 'gelöschte Accounts'
                    },
                    {
                        key    : 'tools/bounces',
                        caption: 'E-Mail-Bounces'
                    },
                    {
                        key    : 'tools/automailer',
                        caption: 'automatische E-Mails'
                    },
                    /*{
                        key    : 'tools/slottemplates',
                        caption: 'Planungs-Vorlagen'
                    },*/
                    {
                        key    : 'tools/competencetemplates',
                        caption: 'Kompetenzen-Vorlagen'
                    },
                    {
                        key    : 'tools/listtemplates',
                        caption: 'Listen-Vorlagen'
                    },
                    {
                        key    : 'tools/messages',
                        caption: 'In-App-Messages'
                    },
                    {
                        key    : 'tools/newsletter',
                        caption: 'Newsletter'
                    },
                    {
                        key    : 'tools/templates',
                        caption: 'Mail-Templates'
                    }
                ],
                helpdesk  : [
                    {
                        key    : 'helpdesk/outbox',
                        caption: 'Postausgang'
                    },
                    {
                        key    : 'helpdesk/inbox',
                        caption: 'Posteingang'
                    }
                ],
                admin     : [
                    {
                        key    : 'admin/users',
                        caption: 'Administratoren'
                    },
                    {
                        key    : 'admin/profile',
                        caption: 'Einstellungen'
                    }
                ],
                monitoring: [
                    {
                        key    : 'monitoring/cluster',
                        caption: 'Cluster'
                    }
                ]
            }
        }
    },

    updated()
    {

        this.$nextTick()
            .then( () =>
            {
                if( undefined !== this.subnav[ this.$route.name ] )
                {
                    this.$core.getEventManager().dispatch( 'on-subnav', true )
                }
                else
                {
                    this.$core.getEventManager().dispatch( 'on-subnav', false )
                }
            } )

    },

    mounted()
    {
        this.$core.getEventManager()
            .add( 'on-mail-count', count =>
            {
                this.counters.helpdesk = count
            } )
        this.$core.getEventManager()
            .add( 'on-user-count', count =>
            {
                this.counters.users = count
            } )
        this.prepareInvoiceSub()
        if( this.activeSub === false )
        {
            this.$nextTick()
                .then( () =>
                {
                    this.fixSelectedTab()
                    this.addScroller()
                } )
        }
    },

    methods: {

        fixSelectedTab( recurse )
        {

            recurse = undefined !== recurse ? recurse : 0

            if( null !== this.$route.name )
            {
                if( undefined !== this.subnav[ this.$route.name ] )
                {
                    let lastId = this.subnav[ this.$route.name ].length - 1
                    this.activeSub = this.subnav[ this.$route.name ][ lastId ].key
                }
            }
            else
            {
                if( 3 > recurse )
                {
                    recurse++
                    setTimeout( () =>
                    {
                        this.fixSelectedTab( recurse )
                    }, 1000 )
                }
            }

        },

        navigate( event, tgt )
        {

            let target = tgt.key
            let test = target.split( '/' )
            if( 1 === test.length )
            {
                if( event.ctrlKey )
                {
                    this.newTab( event, tgt )
                    return
                }
                if( target === 'admin' )
                {
                    this.activeSub = 'admin/users'
                }
                if( target === 'tools' )
                {
                    this.activeSub = 'tools/templates'
                }
                if( target === 'licenses' )
                {
                    this.activeSub = 'licenses/list'
                }
                if( target === 'invoices' )
                {
                    this.activeSub = 'invoices/reset'
                }
                if( target === 'users' )
                {
                    this.activeSub = 'users/reset'
                }
                if( target === 'helpdesk' )
                {
                    this.activeSub = 'helpdesk/inbox'
                }
                if( target === 'monitoring' )
                {
                    this.activeSub = 'monitoring/cluster'
                }
                this.$core.setState( 'navigation.activeSub', this.activeSub )
                this.$router.push( { name: target } )
            }
            else
            {
                this.activeSub = target
                this.$core.setState( 'navigation.activeSub', this.activeSub )
                this.$core.getEventManager()
                    .dispatch( 'on-set-filter', test[ 1 ] )
            }
            this.scrollTopVisible = false

        },

        prepareInvoiceSub()
        {

            this.subnav.invoices = []
            let now = Date.now()
            let dt = new Date( now )
            dt.setMonth( dt.getMonth() - 5 )

            for( let i = 0; i < 6; i++ )
            {

                let month = ( dt.getMonth() ) + 1
                let nav = {
                    key    : 'invoices/' + dt.getFullYear() + '-' + ( 10 > month ? '0' + month : month ) + '-01',
                    caption: this.$core.t().translate( 'month-' + month ) + ' ' + dt.getFullYear()
                }
                this.subnav.invoices.push( nav )

                dt.setMonth( dt.getMonth() + 1 )

            }

            this.subnav.invoices.push( {
                key    : 'invoices/reset',
                caption: 'Alle anzeigen'
            } )

        },

        newTab( event, target )
        {
            event.stopPropagation()
            window.open( '/#/' + target.key, '_blank' )
        },

        addScroller()
        {
            this.$core.getEventManager()
                .add( 'on-view-ready', ( uuid ) =>
                {
                    this.$nextTick()
                        .then( () =>
                        {
                            let base = document.querySelector( '#base' )
                            if( null !== base )
                            {
                                this.$nextTick()
                                    .then( () =>
                                    {

                                        let test = base.querySelectorAll( '#' + uuid )
                                        if( 0 < test.length )
                                        {
                                            this.attached = base
                                            base.onscroll = ( event ) =>
                                            {
                                                this.handleScroll( event )
                                            }
                                        }
                                        else
                                        {
                                            setTimeout( () =>
                                            {
                                                this.$core.getEventManager()
                                                    .dispatch( 'on-view-ready', uuid )
                                            }, 100 )
                                        }
                                    } )
                            }
                        } )
                } )
        },

        handleScroll()
        {
            if( this.attached.scrollTop > 30 )
            {
                this.scrollTopVisible = true
            }
            else
            {
                this.scrollTopVisible = false
            }
        },

        scroller( top )
        {

            if( top > 0 )
            {

                setTimeout( () =>
                {

                    let factor = 500
                    if( top > 5000 )
                    {
                        factor = 1000
                    }
                    else if( top > 3000 )
                    {
                        factor = 700
                    }
                    else if( top > 2000 )
                    {
                        factor = 500
                    }
                    else if( top > 1000 )
                    {
                        factor = 300
                    }
                    else if( top > 300 )
                    {
                        factor = 100
                    }
                    else if( top > 200 )
                    {
                        factor = 50
                    }
                    else if( top > 100 )
                    {
                        factor = 25
                    }
                    else if( top > 50 )
                    {
                        factor = 10
                    }
                    else
                    {
                        this.attached.scrollTop = 0
                        return
                    }

                    top -= factor
                    this.attached.scrollTop = top
                    this.scroller( top )

                }, 30 )

            }

        },

        scrollToTop()
        {

            let top = this.attached.scrollTop
            this.scroller( top )
        }
    }
}
</script>