export default class Functions
{

    constructor( core )
    {

        if( !Functions.instance )
        {

            this.store = core.getStore()
            this.eventManager = core.getEventManager()
            this.attachEscapeHandler()
            Functions.instance = this

        }

        return Functions.instance

    }

    attachEscapeHandler()
    {
        let body = document.querySelector( 'body' )
        if( null !== body )
        {
            document.onkeyup = ( evnt ) => this.handleKeyPress( evnt )
        }
        else
        {
            setTimeout( () =>
            {
                this.attachEscapeHandler()
            }, 100 )
        }
    }

    translateRole( idRole )
    {
        switch( idRole )
        {
            case 10:
                return 'Administrator'
            case 5:
                return 'Editor'
            case 1:
                return 'Helpdesk-User'
        }
    }

    handleKeyPress( event )
    {
        if( event.key === 'Escape' || event.key === 'Esc' || event.keyCode === 27 )
        {
            let elm = document.querySelectorAll( '.closer' )
            for( let e in elm )
            {
                if( elm[ e ] instanceof Element )
                {
                    elm[ e ].click()
                    return
                }
            }

            let cnc = document.querySelectorAll( '.cancelbutton' )
            for( let e in cnc )
            {
                if( cnc[ e ] instanceof Element )
                {
                    cnc[ e ].click()
                    return
                }
            }
        }
    }

    promiseRunner( promises )
    {
        return new Promise( resolve =>
        {

            let promise = promises.reduce( ( todo, task ) =>
            {
                return todo.then( () =>
                {
                    return task()
                } )
            }, Promise.resolve() )

            promise.then( () =>
            {
                return resolve()
            } )

        } )
    }

    /**
     * ucFirst
     * @param string
     * @returns {string}
     */
    ucFirst( string )
    {
        return string.charAt( 0 ).toUpperCase() + string.slice( 1 )
    }

    /**
     * lcFirst
     * @param string
     * @returns {string}
     */
    lcFirst( string )
    {
        return string.charAt( 0 ).toLowerCase() + string.slice( 1 )
    }

    uiMessage( className, message )
    {
        this.eventManager.dispatch( 'on-message-out', {
            className: className,
            message  : message
        } )
    }

    toHtml( body )
    {

        if( undefined === body
            || 'undefined' === body )
        {
            return ''
        }

        let nuBody = ''
        let rows = body.split( /\n/g )

        let ulOpen = false
        for( let r in rows )
        {
            if( '-' === rows[ r ].substr( 0, 1 )
                && '--' !== rows[ r ].substr( 0, 2 ) )
            {
                let line = rows[ r ].substr( 1 ).trim()
                if( !ulOpen )
                {
                    nuBody += '<ul>'
                    ulOpen = true
                }
                nuBody += '<li>' + line + '</li>'
            }
            else
            {
                if( ulOpen )
                {
                    nuBody += '</ul>'
                    ulOpen = false
                }
                nuBody += rows[ r ] + '\n'
            }
        }
        if( ulOpen )
        {
            nuBody += '</ul>'
        }

        body = nuBody

        body = body.replace( /\n/g, '<br/>' )
        body = body.replace( /<br>/g, '<br/>' )
        body = body.replace( /\*([^\\*]*)\*/g, '<span class="bold">$1</span>' )
        body = body.replace( /_([^\\*]*)_/g, '<u>$1</u>' )
        body = body.replace( /~([^\\*]*)~/g, '<em>$1</em>' )
        body = body.replace( /--([^\\*]*)--/g, '<s>$1</s>' )

        //body = body.replace( /&amp;([^\\*]*)&amp;/g, '<span class="comic">$1</span>' )

        let cleanup = body.split( '<br/>' )
        let cleanedBody = []
        let textFound = false

        for( let i = ( cleanup.length - 1 ); i >= 0; i -= 1 )
        {
            if( '' !== cleanup[ i ].trim() || textFound )
            {
                cleanedBody.push( cleanup[ i ] )
                textFound = true
            }
        }

        body = cleanedBody.reverse().join( '<br/>' )

        return body

    }

    skip( event )
    {
        event.stopPropagation()
    }

}