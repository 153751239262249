export default class Translation
{

    constructor()
    {
        this.translations = {}
        this.init()
        return this
    }

    init()
    {
        this.translations = {
            'did-a-yearchange'                  : 'Schuljahreswechsel',
            'cloned-a-list'                     : 'Liste geklont',
            'count_login_offline'               : 'Offline angemeldet',
            'count_login_online'                : 'Online angemeldet',
            'count_login_online_failure'        : 'Logindaten falsch eingegeben',
            'count_login_offline_failure'       : 'Logindaten falsch eingegeben',
            'count_logout'                      : 'Logout genutzt',
            'pagehit_classes'                   : 'Seitenaufruf: Klassen',
            'pagehit_classes-referenced'        : 'Seitenaufruf: Klassen',
            'pagehit_students'                  : 'Seitenaufruf: Schüler:innen',
            'pagehit_colleagues'                : 'Seitenaufruf: Kollegium',
            'pagehit_dashboard'                 : 'Seitenaufruf: Dashboard',
            'pagehit_groups'                    : 'Seitenaufruf: Gruppen',
            'pagehit_groups-referenced'         : 'Seitenaufruf: Gruppen',
            'pagehit_messages'                  : 'Seitenaufruf: Nachrichten',
            'pagehit_notes'                     : 'Seitenaufruf: Notizen',
            'pagehit_notes_with_forceCreate'    : 'Seitenaufruf: Notizen',
            'pagehit_notes_with_localId'        : 'Seitenaufruf: Notizen',
            'pagehit_notes_with_openSlider'     : 'Seitenaufruf: Notizen',
            'pagehit_notes_with_remoteCall'     : 'Seitenaufruf: Notizen',
            'pagehit_office'                    : 'Seitenaufruf: Büro',
            'pagehit_office_with_localId'       : 'Seitenaufruf: Büro',
            'pagehit_planner'                   : 'Seitenaufruf: Kalender',
            'pagehit_settings'                  : 'Seitenaufruf: Einstellungen',
            'pagehit_todos'                     : 'Seitenaufruf: Todos',
            'pagehit_secretary'                 : 'Seitenaufruf: Admin (Schullizenz)',
            'pagehit_passwordreset'             : 'Seitenaufruf: Passwort-Reset',
            'pagehit_izel'                      : 'Seitenaufruf: IZeL',
            'pagehit_yeargroups'                : 'Seitenaufruf: Jahrgänge',
            'displayed-open-invoice-warning'    : ' Hinweis zu unbezahlter Rechnung',
            'tutorial-started-TutorialClasses'  : 'Tutorial gestartet: Klassen',
            'tutorial-started-TutorialMotes'    : 'Tutorial gestartet: Notizen',
            'tutorial-started-TutorialNotes'    : 'Tutorial gestartet: Notizen',
            'tutorial-started-TutorialOffice'   : 'Tutorial gestartet: Büro',
            'tutorial-started-TutorialTodos'    : 'Tutorial gestartet: Todos',
            'tutorial-started-TutorialGroups'   : 'Tutorial gestartet: Gruppen',
            'tutorial-started-TutorialDashboard': 'Tutorial gestartet: Dashboard',
            'tutorial-started-TutorialStudents' : 'Tutorial gestartet: Schüler:innen',
            'tutorial-started-TutorialNotFound' : 'Tutorial gestartet: n/a',
            'print'                             : 'Druckfunktion genutzt',
            'excel'                             : 'Excel-Export genutzt',
            'month-1'                           : 'Januar',
            'month-2'                           : 'Februar',
            'month-3'                           : 'März',
            'month-4'                           : 'April',
            'month-5'                           : 'Mai',
            'month-6'                           : 'Juni',
            'month-7'                           : 'Juli',
            'month-8'                           : 'August',
            'month-9'                           : 'September',
            'month-10'                          : 'Oktober',
            'month-11'                          : 'November',
            'month-12'                          : 'Dezember',
            'sums-paid'                         : 'Summe bezahlt',
            'sums-cancelled'                    : 'Summe storniert',
            'sums-open'                         : 'Summe offen',
            'sums-total'                        : 'Umsatz gesamt',
            'Y'                                 : 'Jahr(e)',
            'M'                                 : 'Monat(e)',
            'W'                                 : 'Woche(n)',
            'D'                                 : 'Tag(e)'
        }
    }

    translate( key )
    {
        return ( undefined !== this.translations[ key ] ? this.translations[ key ] : key )
    }

    translatePeriod( period )
    {
        let keys = [ 'D', 'M', 'W', 'Y' ]
        for( let k in keys )
        {
            period = period.replace( keys[ k ], ' ' + this.translate( keys[ k ] ) )
        }
        return period
    }

}