<template>
    <div v-if="ready">
        <ElementFilter @refresh="handleRefresh()" id="deletedAccs" :showAdder="false"/>
        <table>
            <thead>
            <tr>
                <Thd id="id" caption="#" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortDeletions"
                     @sort="handleSort"/>
                <Thd id="username" caption="Benutzername" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortDeletions"
                     @sort="handleSort"/>
                <Thd id="reason" caption="Grund zur Löschung" :sortBy="sortBy" :sortDir="sortDir"
                     sortRule="sortDeletions"
                     @sort="handleSort"/>
                <Thd id="datetime_deleted" caption="Zeitstempel Löschung" :sortBy="sortBy" :sortDir="sortDir"
                     sortRule="sortDeletions"
                     @sort="handleSort"/>
                <Thd id="datetime_created" caption="Zeitstempel Registrierung" :sortBy="sortBy" :sortDir="sortDir"
                     sortRule="sortDeletions"
                     @sort="handleSort"/>
            </tr>
            </thead>
            <tbody class="filterable">
            <tr v-for="deletion in deletions" :key="'row_'+deletion.id" class="clickable">
                <td><strong>{{ deletion.id }}</strong></td>
                <td><strong>{{ deletion.username }}</strong></td>
                <td><p style="padding:0px; margin:0px;" v-if="'ZZZ_UNDEFINED' !== deletion.reason">{{
                        deletion.reason
                    }}</p>
                    <p v-else style="padding:0px; margin:0px;">- keine Angabe</p></td>
                <td><strong>{{ deletion.datetime_deletion_formatted }}</strong></td>
                <td><strong>{{ deletion.datetime_created_formatted }}</strong></td>
            </tr>
            </tbody>
            <tfoot>
            <tr :id="viewId">
                <th colspan="99" style="text-align:right;" class="filtercount">...</th>
            </tr>
            </tfoot>
        </table>
    </div>
    <Spinner v-else title="Gleich geht's weiter" message="Gelöschte Accounts werden abgerufen..."/>
</template>

<script>

import ElementFilter  from '@/components/elements/ElementFilter'
import Spinner        from '@/components/elements/Spinner'
import Thd            from '@/components/elements/Thd'
import MixinViewReady from '@/mixins/MixinViewReady'

export default {
    name      : 'ToolsDeletions',
    components: { Thd, Spinner, ElementFilter },
    mixins    : [ MixinViewReady ],
    data()
    {
        return {
            ready     : false,
            editor    : false,
            formValues: {},
            sortBy    : 'identifier',
            sortDir   : 'ascending',
            deletions : []
        }
    },
    
    created()
    {
        this.init()
    },
    
    methods: {
        
        init()
        {
            
            this.deletions = []
            this.$core.getClient()
                .request( {
                    method: 'admin.tools_listDeletions'
                } )
                .then( response =>
                {
                    
                    let list = response.result
                    for( let l in list )
                    {
                        if( 'undefined' === list[ l ].reason )
                        {
                            list[ l ].reason = 'ZZZ_UNDEFINED'
                        }
                        this.deletions.push( list[ l ] )
                    }
                    this.sort()
                } )
            
        },
        
        sort()
        {
            this.deletions = this.$core.getSorter().sortObjects( this.deletions, this.sortBy, this.sortDir )
            this.ready = true
        },
        
        handleRefresh()
        {
            this.init()
        },
        
        handleSort( args )
        {
            
            let newDir = args.sortDir === 'ascending' ? 'descending' : 'ascending'
            this.$store.commit( 'setSortDeletions', args.id )
            this.$store.commit( 'setSortDeletionsDir', newDir )
            this.sortBy = args.id
            this.sortDir = newDir
            this.sort()
            
        }
        
        
    }
    
}
</script>