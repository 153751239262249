<template>
    <transition appear name="fade">
        <div id="dash-versions">
            <h2>Versionsverteilung</h2>
            <p>Verteilung der unterschiedlichen Versionen:<br/><strong>{{ total }}</strong> Datensätze insgesamt</p>
            <DisplayVersionStats :height=300 v-if="ready" :data="data" id="dash-versions"/>
            <DisplayDummyDougnut :height=300 id="dash-versions" v-else/>
        </div>
    </transition>
</template>

<script>
import DisplayVersionStats from '@/components/elements/boxes/stats/DisplayVersionStats'
import DisplayDummyDougnut from "@/components/elements/boxes/stats/DisplayDummyDougnut";

export default {
    name      : 'DashVersionStats',
    components: { DisplayDummyDougnut, DisplayVersionStats },
    emits     : [ 'ready' ],

    data()
    {
        return {
            ready: false,
            data : false,
            total: false
        }
    },

    mounted()
    {
        let message = {
            method: 'admin.stats_getVersionStatistics'
        }

        this.$core.getClient()
            .request( message )
            .then( result =>
            {

                let data     = result.result,
                    prepared = { labels: [ 'older' ], set: { older: 0 } },
                    older    = [],
                    labels   = JSON.parse( JSON.stringify( data.labels ) ),
                    set      = JSON.parse( JSON.stringify( data.set ) )

                while( 10 < labels.length )
                {
                    older.push( labels.shift() )
                }

                for( let k in set )
                {
                    if( -1 < older.indexOf( k ) )
                    {
                        prepared.set.older += set[ k ]
                    }
                    else
                    {
                        prepared.labels.push( k )
                        prepared.set[ k ] = set[ k ]
                    }
                }

                this.data = prepared
                this.total = data.total
                this.ready = true
                this.$emit( 'ready' )

            } )
            .catch( () =>
            {
            } )
    }

}
</script>