<template>
    <transition appear name="fade">
        <div id="dash-sessions">
            <h2>Session-Statistiken</h2>
            <p>Nutzungszeit je Session<br/><strong>{{ total }}</strong> Werte insgesamt, Sitzungsdauer
                &Oslash;&nbsp;<strong>{{ average }}</strong> Minuten</p>
            <DisplaySessionStats id="dash-sessions" :height=300 v-if="isready" :data="data"/>
            <DisplayDummyDougnut id="dash-sessions" :height=300 v-else/>
        </div>
    </transition>
</template>

<script>
import DisplaySessionStats from '@/components/elements/boxes/stats/DisplaySessionStats'
import DisplayDummyDougnut from "@/components/elements/boxes/stats/DisplayDummyDougnut";

export default {
    name      : 'DashSessionStats',
    components: { DisplayDummyDougnut, DisplaySessionStats },
    emits     : [ 'ready' ],

    data()
    {
        return {
            isready: false,
            data   : false,
            average: 0,
            total  : 0,
            trans  : {
                count_0_60     : 'weniger als 1 Minute',
                count_60_180   : '1-3 Minuten',
                count_180_300  : '3-5 Minuten',
                count_300_600  : '5-10 Minuten',
                count_600_1200 : '10-20 Minuten',
                count_1200_1800: '20-30 Minuten',
                count_1800_3600: '30-60 Minuten',
                count_gt_3600  : 'mehr als 1 Stunde',
            }
        }
    },

    mounted()
    {
        let message = {
            method: 'admin.stats_getSessionStatistics'
        }

        this.$core.getClient()
            .request( message )
            .then( result =>
            {

                let data = result.result
                let prep = {
                    labels: [],
                    set   : []
                }

                let count = 0

                for( let k in Object.keys( data ) )
                {
                    let key = Object.keys( data )[ k ]
                    if( key !== 'average' )
                    {
                        count += parseInt( data[ key ] )
                        prep.labels.push( this.trans[ key ] ? this.trans[ key ] : key )
                        prep.set.push( parseInt( data[ key ] ) )
                    }
                    else
                    {
                        let average = parseFloat( data[ key ] ) / 60
                        this.average = average.toFixed( 2 )
                    }
                }

                this.total = count
                this.data = prep
                this.isready = true
                this.$emit( 'ready' )

            } )
            .catch( () =>
            {
            } )
    }

}
</script>