<template>
    <div v-if="ready">
        <UserInfo v-if="visibleElement !== false" :element="visibleElement" @close="close()"
                  @refresh="refresh( true )"/>
        <LogView v-if="logsfor !== false" :element="logsfor" @close="close()"/>
        <ElementFilter v-if="!logsfor" id="users" @refresh="handleRefresh()"/>
        <table>
            <thead>
            <tr>
                <Thd id="id" caption="#" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortUsers" @sort="handleSort"/>
                <Thd id="type_plain" caption="Typ" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortUsers"
                     @sort="handleSort"/>
                <Thd id="username" caption="Benutzername" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortUsers"
                     @sort="handleSort"/>
                <Thd id="name" caption="Name" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortUsers"
                     @sort="handleSort"/>
                <Thd id="school" caption="Schule" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortUsers"
                     @sort="handleSort"/>
                <Thd id="email" caption="E-Mail-Adresse" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortUsers"
                     @sort="handleSort"/>
                <Thd id="object_count" caption="Objekte" :sortBy="sortBy" :sortDir="sortDir" sortRule="sortUsers"
                     @sort="handleSort"/>
                <Thd id="datetime_updated" caption="letzte Aktivität" :sortBy="sortBy" :sortDir="sortDir"
                     sortRule="sortUsers" @sort="handleSort"/>
                <Thd id="app_version_sortable" caption="App-Version" :sortBy="sortBy" :sortDir="sortDir"
                     sortRule="sortUsers"
                     @sort="handleSort"/>
                <th>Aktionen</th>
            </tr>
            </thead>
            <tbody class="filterable">
            <tr v-for="user in users" :key="'row_'+user.id" class="clickable" @click="open(user)">
                <td>{{ user.id }}
                    <span style="font-size:0em;" v-html="'-active-'+user.activityLevel"></span></td>
                <td v-html="user.type"></td>
                <td><strong>{{ user.username }}</strong></td>
                <td v-html="user.name"></td>
                <td>{{ user.school }}<br/>
                    {{ user.school_zipcity }}
                </td>
                <td>{{ user.email }}</td>
                <td>{{ user.object_count }}</td>
                <td class="nowrap" v-html="user.datetime_last_meta_formatted"></td>
                <td>{{ user.app_version }}</td>
                <td>
                    <div v-if="user.log_count > 0" class="button" @click="logsfor=user">
                        <strong>L</strong>
                    </div>
                </td>
            </tr>
            </tbody>
            <tfoot>
            <tr :id="viewId">
                <th colspan="99" style="text-align:right;" class="filtercount">...</th>
            </tr>
            </tfoot>
        </table>
    </div>
    <Spinner v-else title="Das kann dauern" message="Kundendaten werden abgerufen..."/>
</template>

<script>
/* eslint-disable */
import Spinner            from '@/components/elements/Spinner'
import Thd                from '@/components/elements/Thd'
import UserInfo           from '@/components/elements/fullscreen/UserInfo'
import ElementFilter      from '@/components/elements/ElementFilter'
import LogView            from '@/components/elements/fullscreen/LogView'
import ContainsFilterable from '@/components/pages/abstract/ContainsFilterable'
import MixinViewReady     from '@/mixins/MixinViewReady'

export default {
    
    name      : 'Users',
    extends   : ContainsFilterable,
    components: { LogView, ElementFilter, UserInfo, Thd, Spinner },
    mixins    : [ MixinViewReady ],
    
    data()
    {
        return {
            ready         : false,
            users         : [],
            sortBy        : 'id',
            sortDir       : 'descending',
            visibleElement: false,
            logsfor       : false
        }
    },
    
    beforeDestroy()
    {
        this.ready = false
        this.$core.getEventManager().remove( 'on-set-filter' )
    },
    
    mounted()
    {
        
        this.$core.getEventManager()
            .add( 'on-set-filter', ( filter ) =>
            {
                this.setFilter( filter )
            } )

        this.$core.getEventManager()
            .dispatch( 'on-userlist-visit' )
        
        this.sortBy = null !== this.$store.getters.sortUsers ? this.$store.getters.sortUsers : 'id'
        this.sortDir = null !== this.$store.getters.sortUsersDir ? this.$store.getters.sortUsersDir : 'descending'
        this.listAll()
        
    },
    
    methods: {
        
        listAll( force )
        {
            
            return new Promise( resolve =>
            {
                this.$core.getCacher()
                    .fillUsersCache( force )
                    .then( userlist =>
                    {
                        
                        this.users = userlist
                        this.sort()
                        this.ready = true
                        return resolve()
                        
                    } )
                
            } )
            
        },
        
        sort()
        {
            this.users = this.$core.getSorter().sortObjects( this.users, this.sortBy, this.sortDir )
            this.ready = true
        },
        
        handleSort( args )
        {
            
            let newDir = args.sortDir === 'ascending' ? 'descending' : 'ascending'
            this.$store.commit( 'setSortUsers', args.id )
            this.$store.commit( 'setSortUsersDir', newDir )
            this.sortBy = args.id
            this.sortDir = newDir
            this.sort()
            
        },
        
        open( element )
        {
            this.visibleElement = element
        },
        
        close()
        {
            this.visibleElement = false
            this.logsfor = false
        },
        
        refresh( noreload )
        {

            let id = this.visibleElement.id
            this.visibleElement = false
            if( undefined === noreload )
            {
                this.ready = false
            }
            
            this.listAll( true )
                .then( () =>
                {
                    
                    if( noreload === true )
                    {
                        
                        for( let u in this.users )
                        {
                            if( this.users[ u ].id === id )
                            {
                                this.visibleElement = this.users[ u ]
                            }
                        }
                        
                        this.$core.getEventManager().dispatch( 'hideBlocker' )
                        
                    }
                    
                } )
            
        },
        
        handleRefresh()
        {
            this.refresh()
        }
        
    }
    
    
}
</script>